import { Button, Cascader } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardVariant, CardsApiFactory } from '../../../service/api'
import styles from './VariantsPopover.module.css'

export const VariantsPopover = ({ onValueChange, onCancel, defaultValue }: { onValueChange: (value: CardVariant | null) => void; onCancel: () => void; defaultValue?: number }): ReactElement => {
    const api = CardsApiFactory()
    const [t] = useTranslation('translations')
    const [variants, setVariants] = useState<CardVariant[]>([])
    const [selectedVariant, setSelectedVariant] = useState<CardVariant | undefined>()
    useEffect(() => {
        api.getVariants().then(response => {
            setVariants(response.data)
            if (defaultValue) {
                setSelectedVariant(variants.find(v => v.pk === Number(defaultValue)))
            }
        })
    }, [])

    return (
        <div className={styles.popover}>
            <div className={styles.label}>{t('type')}</div>
            <Cascader
                value={selectedVariant && [selectedVariant.pk]}
                popupClassName={styles.cascaderMenu}
                className={`${styles.cascader}`}
                fieldNames={{ label: 'name', value: 'pk' }}
                options={variants}
                onChange={value => {
                    setSelectedVariant(variants.find(v => v.pk === Number(value)))
                }}
            />
            <div className={styles.buttonContainer}>
                <Button className={`yellowOutlinedCancelButton ${styles.cancelButton}`} onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button className={`yellowFillPositiveButton ${styles.filterButton}`} onClick={() => onValueChange(selectedVariant ?? null)}>{t('filter')}</Button>
            </div>
        </div>
    )
}
