import { Button } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import alertIcon from '../../../../assets/alert.svg'
import '../../../../common/commonCss/buttons.css'
import styles from './ImportConfirm.module.css'
interface PropsType {
    overwrittenCount: number,
    invalidCount: number,
    onConfirm: () => void,
    onCancel: () => void
}

const ImportConfirmDialog = (props: PropsType) => {
    const [t] = useTranslation('translations')
    useEffect(() => {
        document.body.style.overflow = 'hidden'
    }, [])

    const onConfirm = () => {
        document.body.style.overflow = 'scroll'
        props.onConfirm()
    }

    const onCancel = () => {
        document.body.style.overflow = 'scroll'
        props.onCancel()
    }

    return (
        <div className={styles.container}>
            <div className={styles.dialogContainer}>
                <img src={alertIcon} className={styles.alertIcon} />

                {props.overwrittenCount > 0 &&
                    <div>
                        <span className={styles.count}>
                            {props.overwrittenCount} {t('contacts').toLocaleLowerCase()}
                        </span>
                        &nbsp;
                        {t('willBeOverwritten')}
                    </div>
                }
                {props.invalidCount > 0 &&
                    <div>
                        <span className={styles.count}>
                            {props.invalidCount} {t('contacts').toLocaleLowerCase()}
                        </span>
                        &nbsp;
                        {t('willNotBeImported')}
                    </div>
                }

                <span className={styles.title}>{t('confirmSave')}</span>

                <div className={styles.buttonContainer}>
                    <Button className={'yellowOutlinedCancelButton ' + styles.cancelButton} onClick={onCancel}>
                        {t('cancel')}
                    </Button>

                    <Button className={'blackFillPositiveButton ' + styles.saveButton} onClick={onConfirm}>
                        {t('save')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ImportConfirmDialog