import { Button } from 'antd'
import Table, { ColumnsType, TableProps } from 'antd/lib/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import closeDetail from '../../../assets/close.svg'
import { Colors } from '../../../common/Colors'
import ChartTooltip from '../../../common/components/charts/ChartTooltip'
import ExportCsv from '../../../common/components/charts/ExportCsv'
import { SoldQuantityShowItem } from './SoldQuantityPerItemList'
import './SoldQuantityPerItemTable.css'
import { formatSoldQuantityPerItemData } from './SoldQuantityPerItemUtils'

interface PropsType {
    title: string
    items: any[]
    tooltipText: string
    exportFilename: string
    onClose: () => void
}

const styles = {
    container: {
        backgroundColor: Colors.white,
        borderRadius: '5px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        height: '90%',
        width: '100%',
        display: 'flex',
        padding: '30px',
        flexDirection: 'column' as const,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        color: Colors.yellow,
        marginTop: '0',
        marginBottom: '0'
    },
    tableContainer: {
        marginTop: '30px',
        width: '100%',
    },
    actionButtonContainer: {
        float: 'right',
    },
    graphContainer: {
        paddingLeft: '30px',
        paddingRight: '55px',
        backgroundColor: Colors.lighterGray
    }
}

const SoldQuantityPerItemTable = (props: PropsType) => {

    const [t] = useTranslation('translations')
    const [filteredAndSortedItems, setFilteredAndSortedItems] = useState<SoldQuantityShowItem[]>(props.items)

    const columns: ColumnsType<SoldQuantityShowItem> = [
        {
            title: t('show'),
            dataIndex: 'showName',
            width: 200,
            sorter: {
                compare: (a, b) => a.showName.localeCompare(b.showName)
            }
        },
        {
            title: t('status'),
            dataIndex: 'status',
            width: 100,
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status)
            },
            filters: [
                { text: t('arriving'), value: t('arriving') },
                { text: t('ongoing'), value: t('ongoing') },
                { text: t('finished'), value: t('finished') },
            ],
            filterMultiple: false
        },
        {
            title: t('ticketsSold'),
            dataIndex: 'count',
            width: 100,
            sorter: {
                compare: (a, b) => a.count - b.count
            }
        }
    ]

    const onChange: TableProps<SoldQuantityShowItem>['onChange'] = (pagination, filters, sorter, extra) => {
        setFilteredAndSortedItems(extra.currentDataSource)
    }

    return (
        <div style={styles.graphContainer}>
            <div style={styles.container}>
                <div style={styles.titleContainer}>
                    <div style={{ display: 'flex' }}>
                        <p style={styles.title}>{props.title}</p>
                        <ChartTooltip tooltipText={props.tooltipText} />
                    </div>

                    <div className='actionButtonContainer'>
                        <ExportCsv filename={props.exportFilename} data={formatSoldQuantityPerItemData(props.items)} isImage={true} />
                        <Button style={{ border: 'none', boxShadow: 'none', marginLeft: '5px' }}
                            icon={<img src={closeDetail} />}
                            onClick={props.onClose} />
                    </div>

                </div>
                <div style={styles.tableContainer}>
                    <Table
                        className="soldQuantityTable"
                        columns={columns}
                        dataSource={props.items}
                        showSorterTooltip={false}
                        pagination={{
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                            defaultPageSize: 10,
                            style: {
                                marginTop: '45px'
                            },
                            showTitle: false
                        }}
                        onChange={onChange} />
                </div>
            </div>
        </div>
    )
}

export default SoldQuantityPerItemTable