import { Column, ColumnConfig } from '@ant-design/plots'
import { Button, Table, TableColumnType } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import closeIcon from '../../../assets/close.svg'
import { ChartData } from '../../../common/components/charts/ChartData'
import ChartTooltip from '../../../common/components/charts/ChartTooltip'
import ExportCsv from '../../../common/components/charts/ExportCsv'
import { formatDate } from '../../../common/utils'
import styles from './SalesChartDetail.module.css'
import { convertSalesChartData, formatSalesChartData } from './SalesChartUtils'

interface SalesChartDetailProps {
    data: ChartData[],
    totalAmount: number,
    exportFilename: string,
    onClose: () => void
}

const SalesChartDetail = ({ data, totalAmount, exportFilename, ...props }: SalesChartDetailProps) => {
    const [t, i18n] = useTranslation('translations')
    const histogramConfig: ColumnConfig = {
        data: convertSalesChartData(data),
        xField: 'date',
        yField: 'sales',
        label: {
            position: 'top',
            style: {
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: 400,
                fill: '#989898',
            }
        },
        xAxis: {
            label: {
                style: {
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fontWeight: 'bolder'
                },
            },
            tickLine: null,
        },
        yAxis: {
            grid: null,
            label: null
        },
        meta: {
            sales: {
                alias: 'Sales'
            }
        },
        color: '#F7C217',
        maxColumnWidth: 18,
        minColumnWidth: 18,
        renderer: 'svg',
        tooltip: false,
        autoFit: true,
        className: styles.histogram,
        appendPadding: [15, 0, 5, 0]
    }

    const columns: TableColumnType<ChartData>[] = [
        {
            title: t('date'),
            dataIndex: 'date',
            render: (datetime: dayjs.Dayjs) => (
                <>{formatDate(datetime, 'D MMM')}</>
            ),
            className: styles.tableColumn,
            align: 'center'
        },
        {
            title: t('totalPerDay'),
            dataIndex: 'sales',
            align: 'center'
        }
    ]

    return (
        <>
            <div className={styles.graphContainer}>
                <div className={styles.summary}>
                    <div className={styles.actionButtonContainer}>
                        <ExportCsv filename={exportFilename} data={formatSalesChartData(data)} isImage={true} />
                        <Button className={styles.actionButton} onClick={props.onClose}>
                            <img src={closeIcon} style={{ width: '25px', height: '25px' }} />
                        </Button>
                    </div>
                    <h1 className={styles.h1}>
                        {t('ticketsSold')}
                        <span>
                            <ChartTooltip tooltipText={t('tooltip')} />
                        </span>
                    </h1>
                    <span className={styles.soldTickets}>{totalAmount}</span>
                    <Column style={{ height: '310px', padding: '5px', marginTop: '20px' }} {...histogramConfig} />
                </div>
                <Table dataSource={data} pagination={false} columns={columns} className={styles.table} />
            </div>
        </>
    )
}

export default SalesChartDetail
