import { Button, Select } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import filterIcon from '../../assets/filter.svg'
import { ORDER_DATE_FORMAT } from '../../common/constants'
import { formatDate } from '../../common/utils'
import styles from './FilterPopup.module.css'

const { Option } = Select

interface PropsType {
    shows: Map<string, dayjs.Dayjs[]>
    filterVisible: boolean
    selectedShowName: string
    selectedShowDate: string
    onCancel: () => void
    onConfirm: (showName: string, showDate: string) => void
}

const NO_SELECTION = ''

const FilterPopup = (props: PropsType) => {

    const [t] = useTranslation('translations')
    const [showNames, setShowNames] = useState<string[]>([NO_SELECTION])
    const [showDates, setShowDates] = useState<string[]>([NO_SELECTION])

    const [selectedShowName, setSelectedShowName] = useState<string>(props.selectedShowName)
    const [selectedShowDate, setSelectedShowDate] = useState<string>(props.selectedShowDate)

    useEffect(() => {
        loadShowNames()
        setSelectedShowName(props.selectedShowName)
        setSelectedShowDate(props.selectedShowDate)
    }, [props.shows, props.selectedShowName, props.selectedShowDate])

    const loadShowNames = () => {
        const shows: string[] = []
        shows.push(NO_SELECTION)
        props.shows.forEach((value: dayjs.Dayjs[], key: string) => {
            shows.push(key)
        })
        setShowNames(shows)
    }

    const handleShowNameChanged = (value: string) => {
        setSelectedShowName(value)
        setSelectedShowDate(NO_SELECTION)
        updateShowDates(value)
    }

    const handleShowDateChanged = (value: string) => {
        setSelectedShowDate(value)
    }

    const updateShowDates = (showName: string) => {
        const shows: string[] = []
        shows.push(NO_SELECTION)
        props.shows.get(showName)?.forEach((date: dayjs.Dayjs) => {
            shows.push(formatDate(date, ORDER_DATE_FORMAT))
        })
        setShowDates(shows)
    }

    return (
        <>
            {
                props.filterVisible &&
                <div className={styles.container}>

                    <label className={styles.label}>{t('show')}</label>
                    <Select className={styles.filter} value={selectedShowName} onChange={handleShowNameChanged} style={{ marginBottom: '20px', width: '100%' }}>
                        {showNames.map(showName => {
                            return <Option key={showName} value={showName}>{showName}</Option>
                        })}
                    </Select>

                    <label className={styles.label}>{t('showDate')}</label>
                    <Select className={styles.filter} value={selectedShowDate} onChange={handleShowDateChanged} style={{ marginBottom: '20px' }}>
                        {showDates.map(showDate => {
                            return <Option key={showDate} value={showDate}>{showDate}</Option>
                        })}
                    </Select>

                    <div className={styles.buttonsContainer}>
                        <Button
                            className={`${styles.button} ${styles.ordersCancelButton}`}
                            onClick={props.onCancel}>
                            {t('cancel')}
                        </Button>
                        <Button
                            className={`${styles.button} ${styles.filterButton}`}
                            icon={<img src={filterIcon} />}
                            onClick={() => props.onConfirm(selectedShowName, selectedShowDate)}>
                            {t('filter')}
                        </Button>
                    </div>
                </div>
            }
        </>
    )
}

export default FilterPopup
