import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useCSVDownloader } from 'react-papaparse'
import exportIcon from '../../../assets/export.svg'
import classStyle from './exportCsv.module.css'
interface PropsType {
    filename: string,
    data: object[],
    isImage: boolean
}

const ExportCsv = (props: PropsType) => {
    const [t] = useTranslation('translations')
    const { CSVDownloader, Type } = useCSVDownloader()
    return <CSVDownloader
        style={{
            border: '0px',
            padding: '0px',
            background: 'none'
        }}
        filename={props.filename}
        data={props.data}
        bom={true}
        config={{
            delimiter: ';',
        }}
    >
        {!props.isImage && <Button className={classStyle.exportPrimaryButton} type="primary">
            {t('export')}
        </Button>}
        {props.isImage &&
            <Button className={classStyle.exportImageButton} >
                <img src={exportIcon} />
            </Button>}

    </CSVDownloader>
}

export default ExportCsv