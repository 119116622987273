import { Avatar, Button, Space } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ReactComponent as CloseIcon } from '../../../assets/mobile/close.svg'
import { ReactComponent as MenuIcon } from '../../../assets/mobile/menu.svg'
import { getFullName } from '../../../common/utils'
import i18n from '../../../i18n'
import { User } from '../../../service/api'
import { Logout } from '../../login/Login'
import { getRoleTranslation } from '../../users/Users/UserDetailForm/UserDetailForm'
import { CustomDropdown } from './DropownComponent/CustomDropdown'
import styles from './Topbar.module.css'

interface TobarProps {
    user: User
    drawerVisible: boolean
    changeDrawerState: () => void
}

const getUserCompany = (user: User): string => {
    if (user.company && user.company !== '') {
        return user.company
    } else if (user.organizations) {
        return user.organizations[0].name
    } else {
        return ''
    }
}

export const Topbar = (props: TobarProps): ReactElement => {
    const [t] = useTranslation('translations')
    const { pathname } = useLocation()
    const [pathName, setPathName] = useState<string>('')
    useEffect(() => {
        const segments = pathname.split('/').filter(segment => segment.length > 0)
        const route = segments[1] ?? ''
        setPathName(i18n.t('translations:' + route))
    }, [pathname])

    const userMenuContent = (
        <>
            <div className={styles.menuItem}>
                <div className={styles.itemTitle}>{getFullName(props.user)}</div>
                <div className={styles.itemSecondaryText}>{props.user?.username}</div>
            </div>
            <div className={styles.menuItem}>
                {props.user &&
                    <>
                        <div className={styles.itemTitle}>{getUserCompany(props.user)}</div>
                        <div className={styles.itemSecondaryText}>{getRoleTranslation(props.user.role)}</div>
                    </>
                }
            </div>
            <div>
                <Logout />
            </div>
        </>
    )
    const userAvatar = () => {
        return (
            <Avatar size={30} className={styles.avatar}>
                {props.user?.firstName[0]}{props.user?.lastName[0]}
            </Avatar>)
    }
    return (
        <>
            <div className={styles.topbar}>
                <span className={styles.helloUser}>
                    {props.user && t('helloUser', { user: props.user.firstName })}
                </span>
                <Space size={11} className={styles.space}>
                    <p className={styles.companyName}>
                        {props.user?.company}
                    </p>
                    <CustomDropdown userMenuContent={userMenuContent} avatar={userAvatar()} />
                </Space>
                {/*Mobile items*/}
                <span className={styles.currentPage}>
                    {pathName}
                </span>
                <Button
                    onClick={() => props.changeDrawerState()}
                    type='text'
                    icon={props.drawerVisible ? <CloseIcon className={styles.drawerIcon} /> : <MenuIcon className={styles.drawerIcon} />}
                    className={styles.drawerButton} />
            </div>
        </>
    )
}

