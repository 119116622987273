import { Form, Input } from 'antd'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import styles from './StockOutModalForm.module.css'

interface StockOutModalProps {
    form: any
    onFinish: (values: StockOutFormValues) => void
}
export interface StockOutFormValues {
    intervalStarting: number
    intervalEnding: number
}

const MAX_VALUE = 2147483647
export const StockOutModalForm = ({ onFinish, form }: StockOutModalProps): ReactElement => {
    const [t] = useTranslation('translations')
    const inventoryState = useSelector((state: RootState) => state.inventoryState)
    const initialValues: StockOutFormValues = {
        intervalStarting: inventoryState.batch!.batchStart,
        intervalEnding: inventoryState.batch!.batchEnd
    }
    return (
        <Form
            form={form}
            className={styles.form}
            layout='vertical'
            initialValues={initialValues}
            onFinish={onFinish}
            requiredMark={false}>
            <Form.Item
                label={t('batchIntervalStarting')}
                name={'intervalStarting'}
                rules={[
                    {
                        required: true,
                        message: ''
                    },
                    () => ({
                        validator(_, value) {
                            if (value <= MAX_VALUE) {
                                return Promise.resolve()
                            } else if (value === undefined) {
                                return Promise.reject()
                            }
                            return Promise.reject(new Error(t('maxValueReached')))
                        },
                    }),
                ]}>
                <Input disabled={true} type='number' placeholder='000000000009919900' />
            </Form.Item>
            <Form.Item
                label={t('batchIntervalEnding')}
                name={'intervalEnding'}
                rules={[
                    {
                        required: true,
                        message: ''
                    },
                    () => ({
                        validator(_, value) {
                            if (value <= MAX_VALUE) {
                                return Promise.resolve()
                            } else if (value === undefined) {
                                return Promise.reject()
                            }
                            return Promise.reject(new Error(t('maxValueReached')))
                        },
                    }),
                ]}>
                <Input disabled={true} type='number' placeholder='000000000009929900' />
            </Form.Item>
        </Form>
    )
}