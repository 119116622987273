import { Avatar } from 'antd'
import ContactPicIcon from '../../assets/contact_pic_empty.svg'

interface UserAvatarProps {
    fullName: string,
    avatarUrl?: URL
}

const UserAvatar = (props: UserAvatarProps) => {
    return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
            {props.avatarUrl ? (
                <Avatar src={props.avatarUrl.toString()} style={{ width: 28, height: 28, marginRight: 14, borderRadius: 14 }}></Avatar>
            ) : (
                <Avatar src={ContactPicIcon} style={{ fontSize: '28px', marginRight: 14 }} />
            )}
            {props.fullName}
        </span >
    )
}

export default UserAvatar