import axios, { AxiosError, AxiosResponse } from 'axios'
import { CustomerDetail } from './api'
import { API_URL } from './model'

const CUSTOMER_ADDRESS = '/customers'

export function getCustomers(callback: (customers: CustomerDetail[]) => void): void {
    axios({
        method: 'GET',
        url: API_URL + CUSTOMER_ADDRESS
    }).then((response: AxiosResponse) => {
        callback(response.data)
    }).catch((error: AxiosError) => {
        console.log(error)
    })
}

export async function importCustomers(customers: CustomerDetail[]): Promise<void> {
    try {
        return await axios({
            method: 'POST',
            url: API_URL + CUSTOMER_ADDRESS,
            data: customers
        })
    } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error)) {
            if (error.response) {
                throw new Error(`HTTP ${error.response.status} ${error.response.statusText}`)
            } else {
                throw new Error(`Failed to parse data: ${error.message}`)
            }
        } else {
            throw new Error(`Unknown error: ${error}`)
        }
    }
}