import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Modal, Spin, notification } from 'antd'
import { AxiosError } from 'axios'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { ReactComponent as LocationIcon } from '../../../../../assets/locationIcon.svg'
import { ReactComponent as VectorIcon } from '../../../../../assets/vector.svg'
import { Colors } from '../../../../../common/Colors'
import i18n from '../../../../../i18n'
import { InventoryApiFactory, InventoryVariant, Location } from '../../../../../service/api'
import { ErrorResponse } from '../../../../../service/model'
import styles from './StockMoveModal.module.css'
import { StockMoveFormValues, StockMoveModalForm } from './StockMoveModalForm'

interface StockMoveModalProps {
    open: boolean
    productTitle: string
    variant: InventoryVariant
    onCancel: () => void
    onComplete: () => void
}

export const StockMoveModal = ({ open, variant, productTitle, onCancel, onComplete }: StockMoveModalProps): ReactElement => {
    const api = InventoryApiFactory()
    const [t] = useTranslation('translations')
    const [loading, setLoading] = useState<boolean>(false)
    const [form] = Form.useForm()
    const inventoryState = useSelector((state: RootState) => state.inventoryState)
    const [toWarehouse, setToWarehouse] = useState<Location | undefined>(undefined)
    const handleSubmit = (values: StockMoveFormValues) => {
        setToWarehouse(values.toLocation)
        setLoading(true)
        api.moveBatch(values.toLocation!.pk!, {
            pk: values.batchPk,
            inventoryLocation: {
                pk: values.fromLocation.pk!
            }
        }).then(res => {
            onComplete()
            onCancel()
        }).catch((error: AxiosError) => {
            notification.error({
                message: error.response?.status.toString(),
                description: i18n.t((error.response!.data as ErrorResponse).title) ?? error.message
            })
        }).finally(() => {
            setLoading(false)
            form.setFieldValue('toLocation', undefined)
        })
    }
    return (
        <>
            <Modal
                closable={false}
                className={`modal ${styles.customModal}`}
                maskClosable={false}
                open={open}
                footer={
                    !loading &&
                    <>
                        <Button
                            onClick={() => onCancel()}
                            className={'yellowOutlinedCancelButton'}>
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={() => form.submit()}
                            className={'blackFillPositiveButton'}>
                            {t('moveStock')}
                        </Button>
                    </>
                }>
                {!loading ?
                    <>
                        <div className={styles.modalTitle}>
                            {productTitle}
                        </div>
                        <div className={styles.modalSubtitle}>
                            {inventoryState.warehouse?.name}
                            <div className={styles.yellowDot}>{<>&#8226;</>}</div>
                            {variant.sku}
                        </div>
                        <StockMoveModalForm variant={variant} form={form} onFinish={handleSubmit} />
                    </>
                    :
                    <div className={styles.spinContainer}>
                        <div className={styles.loadingAction}>{t('stockingOut')}</div>
                        <Spin className={styles.spin} indicator={<LoadingOutlined style={{ fontSize: 55, color: Colors.yellow }} spin />} />

                        <div className={styles.loadingFooter}>
                            <div className={styles.footerLoadingText}>{t('fromLocation')} <LocationIcon /> {inventoryState.warehouse?.name}</div>
                            <div className={styles.footerLoadingText}><VectorIcon className={styles.vectorIcon} /> {t('toLocation')} <LocationIcon /> {toWarehouse?.name}</div>
                        </div>
                    </div>
                }
            </Modal>
        </>
    )
}