
export enum CardStatus {
    ASSIGNED = 'assigned',
    ACTIVE = 'active',
    REPLACED = 'replaced',
    EXPIRED = 'expired',
    ALL = 'all'
}

export enum CardType {
    RESTART = 'restart'
}

export const getLabel = (key: string) => {
    switch (key) {
    case CardStatus.ACTIVE:
        return 'activeFemalePlural'
    case CardStatus.ALL:
        return 'allFemale'
    case CardStatus.EXPIRED:
        return 'expiredPlural'
    case CardStatus.ASSIGNED:
        return 'assignedPlural'
    case CardStatus.REPLACED:
        return 'replacedPlural'
    }
}
