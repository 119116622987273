import { BellOutlined } from '@ant-design/icons'
import { Avatar, Badge, List, Popover } from 'antd'
import dayjs from 'dayjs'
import { ReactElement, useEffect, useState } from 'react'
import { Notification, NotificationApiFactory } from '../../service/api'
export const NotificationBadge = (): ReactElement => {
    const api = NotificationApiFactory()
    const [notifications, setNotifications] = useState<Notification[]>([])

    useEffect(() => {
        api.getNotifications({ from: dayjs().valueOf() }).then(response => {
            setNotifications(response.data)
        })
    }, [setNotifications])

    return (
        <div id="badge-container" style={{ paddingTop: '5px' }}>
            <Badge count={notifications.length}>
                <BellOutlined style={{ fontSize: '30px' }} />
            </Badge>
        </div>
    )
}

export const NotificationWidget = (): ReactElement => {
    const api = NotificationApiFactory()
    const [notifications, setNotifications] = useState<Notification[]>([])

    const [isPopoverVisible, setPopoverVisible] = useState(false)

    useEffect(() => {
        api.getNotifications({from: dayjs().valueOf()}).then(response => {
            setNotifications(response.data)
        })
    }, [setNotifications])

    const showPopover = () => {
        setPopoverVisible(true)
    }

    const handleVisibilityChange = (visible: boolean) => {
        setPopoverVisible(visible)
    }

    return (
        <div>
            <div onClick={showPopover} style={{ margin: '30px' }}>
                <Badge count={notifications.length}>
                    <BellOutlined style={{ fontSize: '30px' }} />
                </Badge>
            </div>

            <Popover
                style={{ margin: '30px' }}
                content={
                    <div>

                        <List
                            itemLayout="horizontal"
                            dataSource={notifications}
                            renderItem={notification => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                        title={
                                            <p>{notification.customer
                                                ? `${notification.customer.firstName} ${notification.customer.lastName}`
                                                : 'Anonymous'}
                                            </p>
                                        }
                                        description={notification.type + ' ' + notification.date}
                                    />
                                </List.Item>
                            )}
                        />
                        <List.Item>
                            <List.Item.Meta
                                title={<p>View all</p>}
                            />
                        </List.Item>
                    </div>

                }
                placement="rightTop"
                title="Notifications"
                trigger="click"
                open={isPopoverVisible}
                onOpenChange={handleVisibilityChange}>
            </Popover>
        </div>
    )
}