import { Setting } from '../../service/model'

export enum UserSettings {
    adminDashboard = 'adminDashboard',
    adminTicketing = 'adminTicketing',
    adminMarketing = 'adminMarketing',
    adminEcommerce = 'adminEcommerce',
    adminSocial = 'adminContacts',
    adminAdministration = 'adminAdministration',
}

export function getSettings(settings: Setting[]): UserSettings[] {
    const currentSettings: UserSettings[] = []
    const adminDashboard = settings.find(setting => setting.key === UserSettings.adminDashboard)
    const adminEcommerce = settings.find(setting => setting.key === UserSettings.adminEcommerce)
    const adminTickets = settings.find(setting => setting.key === UserSettings.adminTicketing)
    //const adminMarketing = settings.find(setting => setting.key === UserSettings.adminMarketing)
    const adminSocial = settings.find(setting => setting.key === UserSettings.adminSocial)
    const adminAdministration = settings.find(setting => setting.key === UserSettings.adminAdministration)
    if (adminDashboard !== undefined && adminDashboard.value === 'true') {
        currentSettings.push(UserSettings.adminDashboard)
    }
    if (adminEcommerce !== undefined && adminEcommerce.value === 'true') {
        currentSettings.push(UserSettings.adminEcommerce)
    }
    if (adminTickets !== undefined && adminTickets.value === 'true') {
        currentSettings.push(UserSettings.adminTicketing)
    }
    /*if (adminMarketing !== undefined && adminMarketing.value === 'true') {
        currentSettings.push(UserSettings.adminMarketing)
    }*/
    if (adminSocial !== undefined && adminSocial.value === 'true') {
        currentSettings.push(UserSettings.adminSocial)
    }
    if (adminAdministration !== undefined && adminAdministration.value === 'true') {
        currentSettings.push(UserSettings.adminAdministration)
    }
    return currentSettings
}