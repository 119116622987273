import { List } from 'antd'
import { Colors } from '../../../common/Colors'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import NoData from '../../../common/components/NoData'
import ChartPopupMenu from '../../../common/components/charts/ChartPopupMenu'
import ChartTooltip from '../../../common/components/charts/ChartTooltip'
import NoSelection from '../NoSelection'
import SoldQuantityPerItemListItem from './SoldQuantityPerItemListItem'
import { formatSoldQuantityPerItemData } from './SoldQuantityPerItemUtils'
import './soldQuantityPerItemList.css'

export interface SoldQuantityShowItem {
    count: number
    showName: string
    status: string
}

interface PropsType {
    title: string
    items: SoldQuantityShowItem[]
    tooltipText: string
    exportFilename: string
    onExpandClick?: () => void
    onExportClick?: (filename: string, data: object[]) => void
    modalContainer?: React.RefObject<HTMLElement>
    error?: boolean
    loading: boolean
}

const styles = {
    container: {
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        height: '100%',
        overflow: 'hidden',
        padding: '30px'
    },
    titleContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    title: {
        width: '70%',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: Colors.yellow,
        marginTop: '0',
        marginBottom: '0',
    },
    more: {
        width: '30px',
        marginRight: '4%',
        marginLeft: 'auto'
    },
    contentContainer: {
        width: '100%',
        height: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px',
    },
    listContainer: {
        height: '100%',
        overflow: 'hidden'
    }
}

const ListChart = (props: PropsType) => {

    return (
        <>
            {props.loading ?
                <CustomSkeleton height={280} />
                :
                <div style={styles.container}>
                    <div style={{ ...styles.titleContainer }}>
                        <h1 style={styles.title}>{props.title}
                            <span style={{ display: 'inline-flex' }}>
                                <ChartTooltip tooltipText={props.tooltipText} />
                            </span>
                        </h1>
                        {props.items.length > 0 &&
                            <div style={styles.more}>
                                <ChartPopupMenu
                                    onExportClick={() => props.onExportClick?.(props.exportFilename, formatSoldQuantityPerItemData(props.items))}
                                    onDetailClick={() => props.onExpandClick?.()} />
                            </div>
                        }
                    </div>

                    <div style={styles.contentContainer}>
                        {
                            !props.error ? (
                                props.items.length > 0 ? (
                                    <div style={styles.listContainer}>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={props.items.slice(0, 4)}
                                            renderItem={item => (
                                                <SoldQuantityPerItemListItem {...item} />
                                            )}
                                        />
                                    </div>
                                ) : (
                                    <NoData />
                                )
                            ) : (
                                <NoSelection
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%',
                                        margin: '0 auto',
                                        paddingBottom: '30px'
                                    }}
                                    imageStyle={{ width: '40px', height: '40px', margin: 'auto' }}
                                />
                            )
                        }
                    </div>
                </div>
            }
        </>
    )
}
export default ListChart
