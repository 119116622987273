import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RequireAuth } from '../../App'
import { setConfig } from '../../app/Reducers/DashboardSlice'
import { Setting } from '../../service/model'
import Contacts from '../crm/contacts/Contacts/Contacts'
import Importer from '../crm/contacts/Importer/Importer'
import Dashboard from '../dashboard/Dashboard'
import { DashboardSetting } from '../dashboard/newDashboard/DashboardModel'
import NewDashboard from '../dashboard/newDashboard/NewDashboard'
import CardDetail from '../ecommerce/Cards/CardDetail'
import Cards from '../ecommerce/Cards/Cards'
import Inventory from '../ecommerce/Inventory/Inventory'
import { InventoryDetail } from '../ecommerce/Inventory/InventoryDetail'
import { EcommerceOrders } from '../ecommerce/Orders/EcommerceOrders'
import ProductDetail from '../ecommerce/Products/ProductDetail/ProductDetail'
import Products from '../ecommerce/Products/Products'
import Orders from '../orders/Orders'
import { NotFound } from '../pageNotFound/NotFound'
import TicketCancel from '../tickets/TicketCancel'
import TicketIssue from '../tickets/TicketIssue'
import { UserRole } from '../users/AdminUtils'
import { OrganizationsNew } from '../users/Organizations/AddOrganization/OrganizationsNew'
import Organizations from '../users/Organizations/Organizations'
import { OrganizationsDetail } from '../users/Organizations/OrganizationsDetail'
import AddNewUser from '../users/Users/AddUser/AddNewUser'
import UserDetail from '../users/Users/UserDetail/UserDetail'
import Users from '../users/Users/Users'
import { UserSettings, getSettings } from './RoutingUtils'

export const ROUTE_DASHBOARD = 'dashboard'
const ROUTE_DASHBOARD_ORDERS = 'dashboard/orders'

export const ROUTE_CONTACTS = 'contacts'
const ROUTE_CONTACTS_IMPORT = 'contacts/import'

export const ROUTE_PRODUCTS = 'products'
const ROUTE_PRODUCTS_DETAIL = 'products/detail'
export const ROUTE_INVENTORY = 'inventory'
export const ROUTE_INVENTORY_DETAIL = 'inventory/detail'
export const ROUTE_ORDERS = 'orders'
export const ROUTE_CARDS = 'cards'
const ROUTE_CARDS_DETAIL = 'cards/detail'

export const ROUTE_TICKETS_ISSUE = 'ticket-issue'
export const ROUTE_TICKETS_CANCEL = 'ticket-cancel'

export const ROUTE_USER = 'users'
export const ROUTE_ORGANIZATIONS = 'organizations'
export const ROUTE_ORGANIZATION_DETAIL = 'organizations/detail'
const ROUTE_ORGANIZATION_NEW = 'organizations/new'
export const ROUTE_USER_DETAIL = 'users/user-detail'
export const ROUTE_NEW_USER = 'users/new'

const getHomePage = (userSettings: UserSettings[]): string => {
    const firstSetting = userSettings[0]
    let homePage = ''
    switch (firstSetting) {
    case UserSettings.adminDashboard:
        homePage = ROUTE_DASHBOARD
        break
    case UserSettings.adminEcommerce:
        homePage = ROUTE_PRODUCTS
        break
    case UserSettings.adminMarketing:
        homePage = 'marketing'
        break
    case UserSettings.adminSocial:
        homePage = ROUTE_CONTACTS
        break
    case UserSettings.adminTicketing:
        homePage = ROUTE_TICKETS_ISSUE
        break
    case UserSettings.adminAdministration:
        homePage = ROUTE_USER
    }
    return homePage
}

export const MainRoutes = (props: { settings: Setting[], role: string }) => {
    const adminSettings = getSettings(props.settings)
    const homePage = getHomePage(adminSettings)
    const limitPermission = props.role === UserRole.OPERATOR || props.role === UserRole.FINANCIAL
    const isAdmin = props.role === UserRole.ADMIN
    const adminDashboardSetting = props.settings.find(setting => setting.key === 'adminDashboardConfiguration')
    const dispatch = useDispatch()
    useEffect(() => {
        if (adminDashboardSetting) {
            const adminDashboardConfiguration: DashboardSetting[] = JSON.parse(adminDashboardSetting.value)
            adminDashboardConfiguration.forEach(c => {
                if (c.roles.includes(props.role)) {
                    dispatch(setConfig(c.config))
                }
            })
        } else {
            dispatch(setConfig(undefined))
        }
    }, [])
    return (
        <Routes>
            <Route path='*' element={
                <Navigate to={homePage} replace={true} />
            } />
            {adminDashboardSetting ?
                <Route path={ROUTE_DASHBOARD} element={
                    <RequireAuth>
                        <NewDashboard />
                    </RequireAuth>
                } /> :
                <>
                    <Route path={ROUTE_DASHBOARD} element={
                        <RequireAuth>
                            <Dashboard />
                        </RequireAuth>
                    } />
                    <Route path={ROUTE_DASHBOARD_ORDERS} element={
                        <RequireAuth>
                            <Orders />
                        </RequireAuth>
                    } />
                </>
            }

            {!limitPermission &&
                <>
                    <Route path={ROUTE_TICKETS_ISSUE} element={
                        <RequireAuth>
                            <TicketIssue />
                        </RequireAuth>
                    } />
                    <Route path={ROUTE_TICKETS_CANCEL} element={
                        <RequireAuth>
                            <TicketCancel />
                        </RequireAuth>
                    } />
                    {props.role === UserRole.ADMIN &&
                        <>
                            <Route path={'/NotFound'} element={
                                <RequireAuth>
                                    <NotFound />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_PRODUCTS} element={
                                <RequireAuth>
                                    <Products />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_PRODUCTS_DETAIL} element={
                                <RequireAuth>
                                    <ProductDetail />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_INVENTORY} element={
                                <RequireAuth>
                                    <Inventory />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_INVENTORY_DETAIL} element={
                                <RequireAuth>
                                    <InventoryDetail />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_ORDERS} element={
                                <RequireAuth>
                                    <EcommerceOrders />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_CARDS} element={
                                <RequireAuth>
                                    <Cards />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_CARDS_DETAIL} element={
                                <RequireAuth>
                                    <CardDetail />
                                </RequireAuth>
                            } />
                        </>
                    }
                    <Route path={ROUTE_CONTACTS} element={
                        <RequireAuth>
                            <Contacts />
                        </RequireAuth>
                    } />
                    <Route path={ROUTE_CONTACTS_IMPORT} element={
                        <RequireAuth>
                            <Importer />
                        </RequireAuth>
                    } />
                    {isAdmin &&
                        <>
                            <Route path={ROUTE_USER} element={
                                <RequireAuth>
                                    <Users />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_USER_DETAIL} element={
                                <RequireAuth>
                                    <UserDetail />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_NEW_USER} element={
                                <RequireAuth>
                                    <AddNewUser />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_ORGANIZATIONS} element={
                                <RequireAuth>
                                    <Organizations />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_ORGANIZATION_DETAIL} element={
                                <RequireAuth>
                                    <OrganizationsDetail />
                                </RequireAuth>
                            } />
                            <Route path={ROUTE_ORGANIZATION_NEW} element={
                                <RequireAuth>
                                    <OrganizationsNew />
                                </RequireAuth>
                            } />
                        </>

                    }
                    <Route path="/" element={
                        <Navigate to={homePage} replace={true} />
                    } />
                </>
            }
        </Routes>
    )
}