import { InputRef, Space, Tag } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { ReactElement, useEffect, useRef, useState } from 'react'
import styles from './tagComponent.module.css'
interface tagComponentProps {
    disabled: boolean
    onChange: (tickets: string[]) => void
}
const TagComponent = (props: tagComponentProps): ReactElement => {
    const [tags, setTags] = useState<string[]>([])
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const inputRef = useRef<InputRef>(null)

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])

    const handleClose = (removedTag: number) => {
        setTags(prevState => {
            return prevState.filter((tag, i) => (removedTag !== i))
        })
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value)
    }

    const handleInputConfirm = () => {
        inputValue.split(',').filter(item => item !== '').forEach(item => {
            setTags(tags => [...tags, item])
        })
        setInputValue('')
    }
    useEffect(() => {
        props.onChange(tags)
    }, [tags])

    useEffect(() => {
        if (props.disabled) {
            setTags([])
        }
    }, [props.disabled])

    const handleTextAreaClick = () => {
        inputRef.current?.focus()
    }
    return (
        <Space wrap={true}
            className={`${styles.tagContainer} ${(props.disabled && styles.tagContainerDisabled)}`}
            size={[0, 8]}
            onClick={handleTextAreaClick}>
            {
                tags.map((tag, index) => {
                    const tagElem = (
                        <Tag
                            key={index}
                            closable={true}
                            style={{ userSelect: 'none' }}
                            onClose={e => {
                                e.preventDefault()
                                handleClose(index)
                            }}>
                            <span>
                                {tag}
                            </span>
                        </Tag>
                    )
                    return (tagElem)
                })
            }
            <TextArea
                disabled={props.disabled}
                className={styles.tagInput}
                ref={inputRef}
                value={inputValue}
                style={{ resize: 'none' }}
                rows={6}
                onChange={(e) => handleInputChange(e)}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
            />
        </Space >
    )
}


export default TagComponent