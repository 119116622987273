import { DndContext, DragEndEvent, PointerSensor, useSensor } from '@dnd-kit/core'
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable'
import { Modal, Upload, UploadFile, UploadProps } from 'antd'
import { RcFile } from 'antd/lib/upload/interface'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UploadImage } from '../../../../../assets/uploadImage.svg'
import { Image } from '../../../../../service/api'
import DraggableUploadListItem from './DraggableUploadList'
import styles from './imageUploader.module.css'

interface uploadProps {
    productImages?: Image[]
    onLoad: (files: UploadFile<any>[]) => void
}
const MAX_FILES = 9
const getBase64 = (file: RcFile): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
    })
}
const MultipleImageUploader = (props: uploadProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 }, })

    useEffect(() => {
        if (props.productImages && props.productImages.length > 0) {
            const fileList: UploadFile[] = []
            props.productImages.forEach((image, index) => {
                if (image.imageUrl !== null) {
                    fileList.push({
                        uid: image.id!,
                        name: image.id!,
                        url: image.imageUrl
                    })
                }
            })
            setFileList(fileList)
        }
    }, [props.productImages])

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setFileList((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id)
                const overIndex = prev.findIndex((i) => i.uid === over?.id)
                const newList = arrayMove(prev, activeIndex, overIndex)
                props.onLoad(newList)
                return newList
            })
        }
    }

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile)
        }
        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }
    const handleCancel = () => setPreviewOpen(false)
    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        props.onLoad(newFileList)
    }

    const uploadButton = (
        <div>
            <UploadImage />
            {fileList.length === 0 && <div style={{ marginTop: 8, width: '120px' }}>{t('dragOrClick')}</div>}
        </div>
    )
    return (
        <>
            <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                <SortableContext items={fileList.map((i) => i.uid)} strategy={horizontalListSortingStrategy}>
                    <Upload
                        accept='.png, .jpg, .jpeg'
                        style={{ display: 'flex' }}
                        className={styles.imageUploader}
                        beforeUpload={(file, fileList) => {
                            return false
                        }}
                        listType='picture-card'
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        itemRender={(originNode, file) => (
                            <DraggableUploadListItem originNode={originNode} file={file} />
                        )}
                    >
                        {fileList.length >= MAX_FILES ? null : uploadButton}
                    </Upload>
                    <Modal className={styles.imagePreview} open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img alt='example' style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </SortableContext>
            </DndContext>
        </>
    )
}

export default MultipleImageUploader