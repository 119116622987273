import { Table } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import CustomTooltip from '../../../../common/components/CustomTooltip'
import { getFullName } from '../../../../common/utils'
import checkmark from '../../../../assets/checkmark.svg'
import info from '../../../../assets/info.svg'
import '../../../../common/commonCss/tableView.css'
import styles from './ImportTableview.module.css'
import { ImportedCustomer, ImportedCustomerItem, ImportedCustomerStatus } from './Importer'
interface PropsType {
    data: ImportedCustomerItem[]
}

export default function ImportTableView(props: PropsType) {
    const [t, i18n] = useTranslation('translations')

    const statusLabels = {
        [ImportedCustomerStatus.Imported]: t('imported'),
        [ImportedCustomerStatus.ErrorInvalidEmail]: t('notImported'),
        [ImportedCustomerStatus.ErrorDuplicatedImport]: t('duplicated'),
        [ImportedCustomerStatus.WarningAlreadyExisting]: t('overwritten')
    }
    const statusTooltipLabels = {
        [ImportedCustomerStatus.ErrorInvalidEmail]: t('invalidEmail'),
        [ImportedCustomerStatus.ErrorDuplicatedImport]: t('duplicatedEmail'),
        [ImportedCustomerStatus.WarningAlreadyExisting]: t('existingEmail')
    }

    const columns: ColumnsType<ImportedCustomerItem> = [
        {
            title: t('name'),
            dataIndex: 'customer',
            sorter: {
                compare: (a, b) => {
                    if (a.customer.lastName && b.customer.lastName) {
                        return a.customer.lastName.localeCompare(b.customer.lastName)
                    }
                    if (a.customer.lastName) {
                        return -1
                    }
                    if (b.customer.lastName) {
                        return 1
                    }
                    return 0
                }
            },
            render: (customer: ImportedCustomer) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {getFullName(customer)}
                </span>
            )
        },
        {
            title: t('email'),
            dataIndex: ['customer', 'email'],
            sorter: {
                compare: (a, b) => a.customer.email.localeCompare(b.customer.email)
            }
        },
        {
            title: t('city'),
            dataIndex: ['customer', 'city'],
            sorter: {
                compare: (a, b) => a.customer.city.localeCompare(b.customer.city)
            }
        },
        {
            title: t('actionOnSave'),
            dataIndex: 'status',
            sorter: {
                compare: (a, b) => a.status - b.status
            },
            render: (status: ImportedCustomerStatus) => {
                return (
                    <>
                        {status !== ImportedCustomerStatus.Imported ?
                            <CustomTooltip
                                text={statusTooltipLabels[status]}
                                placement='left'>
                                <img src={info} className={styles.tooltipImage} />
                            </CustomTooltip>
                            :
                            <img src={checkmark} className={styles.tooltipImage} />
                        }
                        <span className={status !== ImportedCustomerStatus.Imported ? styles.notImportedLabel : styles.importedLabel}>{statusLabels[status]}</span>
                    </>
                )
            }
        }
    ]

    return (
        <div className={styles.tableContainer}>
            <Table
                dataSource={props.data}
                columns={columns}
                className={'tableView'}
                showSorterTooltip={false}
                pagination={{
                    position: ['bottomCenter'],
                    hideOnSinglePage: props.data.length <= 10,
                    showSizeChanger: true,
                    style: {
                        marginTop: '45px'
                    },
                    responsive: true,
                    showTitle: false
                }}
            />
        </div>
    )
}
