/* tslint:disable */
/* eslint-disable */
/**
 * Showtime! Console 2.0 API
 * This is a specification of the Web API written using OpenAPI 3.1 specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddOrganizationRequest
 */
export interface AddOrganizationRequest {
    /**
     * Organization name
     * @type {string}
     * @memberof AddOrganizationRequest
     */
    'name': string;
    /**
     * Organization name
     * @type {string}
     * @memberof AddOrganizationRequest
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {AddOrganizationRequestMetadata}
     * @memberof AddOrganizationRequest
     */
    'metadata': AddOrganizationRequestMetadata;
    /**
     * 
     * @type {AddOrganizationRequestPoi}
     * @memberof AddOrganizationRequest
     */
    'poi'?: AddOrganizationRequestPoi;
    /**
     * 
     * @type {AddOrganizationRequestPos}
     * @memberof AddOrganizationRequest
     */
    'pos'?: AddOrganizationRequestPos;
}
/**
 * Organization Metadata
 * @export
 * @interface AddOrganizationRequestMetadata
 */
export interface AddOrganizationRequestMetadata {
    /**
     * Entrance refund value of the organization
     * @type {number}
     * @memberof AddOrganizationRequestMetadata
     */
    'entranceRefundValue': number;
    /**
     * Sales refund value of the organization
     * @type {number}
     * @memberof AddOrganizationRequestMetadata
     */
    'salesRefundValue': number;
    /**
     * Sales refund value of the organization
     * @type {number}
     * @memberof AddOrganizationRequestMetadata
     */
    'salesRefundPercentual': number;
}
/**
 * Point of Interests
 * @export
 * @interface AddOrganizationRequestPoi
 */
export interface AddOrganizationRequestPoi {
    /**
     * 
     * @type {number}
     * @memberof AddOrganizationRequestPoi
     */
    'pk'?: number;
}
/**
 * Point of Sales
 * @export
 * @interface AddOrganizationRequestPos
 */
export interface AddOrganizationRequestPos {
    /**
     * 
     * @type {number}
     * @memberof AddOrganizationRequestPos
     */
    'pk'?: number;
}
/**
 * 
 * @export
 * @interface AddUserRequest
 */
export interface AddUserRequest {
    /**
     * User primary key is null if the user is new
     * @type {any}
     * @memberof AddUserRequest
     */
    'pk': any | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'company': string | null;
    /**
     * 
     * @type {any}
     * @memberof AddUserRequest
     */
    'timestamp': any | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserRequest
     */
    'notification': boolean;
    /**
     * 
     * @type {number}
     * @memberof AddUserRequest
     */
    'tenantId': number;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof AddUserRequest
     */
    'organizations': Array<Organization>;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    'pk': number;
    /**
     * 
     * @type {Array<Setting>}
     * @memberof Application
     */
    'settings': Array<Setting>;
}
/**
 * 
 * @export
 * @interface Batch
 */
export interface Batch {
    /**
     * Batch Primary Key
     * @type {number}
     * @memberof Batch
     */
    'pk': number;
    /**
     * Batch emission date
     * @type {number}
     * @memberof Batch
     */
    'date': number;
    /**
     * Batch starting interval
     * @type {number}
     * @memberof Batch
     */
    'batchStart': number;
    /**
     * Batch endig interval
     * @type {number}
     * @memberof Batch
     */
    'batchEnd': number;
    /**
     * Batch count obtained from the difference between BatchEnd and BatchStart
     * @type {number}
     * @memberof Batch
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CancelTicketsRequest
 */
export interface CancelTicketsRequest {
    /**
     * 
     * @type {CancelTicketsRequestRerun}
     * @memberof CancelTicketsRequest
     */
    'rerun': CancelTicketsRequestRerun;
    /**
     * Tickets to cancel
     * @type {Array<string>}
     * @memberof CancelTicketsRequest
     */
    'subscriptionCodes': Array<string>;
}
/**
 * Replica associated to tickets
 * @export
 * @interface CancelTicketsRequestRerun
 */
export interface CancelTicketsRequestRerun {
    /**
     * 
     * @type {number}
     * @memberof CancelTicketsRequestRerun
     */
    'pk'?: number;
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * Card Primary key
     * @type {number}
     * @memberof Card
     */
    'pk': number;
    /**
     * Card code
     * @type {string}
     * @memberof Card
     */
    'code': string;
    /**
     * Card status
     * @type {string}
     * @memberof Card
     */
    'status': string;
    /**
     * 
     * @type {Customer}
     * @memberof Card
     */
    'customer': Customer | null;
    /**
     * Card type
     * @type {string}
     * @memberof Card
     */
    'type': string;
    /**
     * Selling date of the card
     * @type {number}
     * @memberof Card
     */
    'sellingDate': number;
    /**
     * Activation date of the card
     * @type {number}
     * @memberof Card
     */
    'activationDate': number | null;
    /**
     * 
     * @type {SellingPoint}
     * @memberof Card
     */
    'sellingPoint': SellingPoint | null;
    /**
     * Visits associated with the cards
     * @type {Array<Visit>}
     * @memberof Card
     */
    'visits': Array<Visit>;
    /**
     * Minors included with the card
     * @type {number}
     * @memberof Card
     */
    'minors': number;
}
/**
 * 
 * @export
 * @interface CardVariant
 */
export interface CardVariant {
    /**
     * Variant Primary key
     * @type {number}
     * @memberof CardVariant
     */
    'pk': number;
    /**
     * Variant name
     * @type {string}
     * @memberof CardVariant
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ChartItem
 */
export interface ChartItem {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChartItem
     */
    'types': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChartItem
     */
    'labels': Array<string>;
    /**
     * 
     * @type {Array<Array<ChartItemDataInnerInner>>}
     * @memberof ChartItem
     */
    'data': Array<Array<ChartItemDataInnerInner>>;
}
/**
 * @type ChartItemDataInnerInner
 * @export
 */
export type ChartItemDataInnerInner = number | string;

/**
 * 
 * @export
 * @interface CreateLocationRequest
 */
export interface CreateLocationRequest {
    /**
     * 
     * @type {any}
     * @memberof CreateLocationRequest
     */
    'pk': any | null;
    /**
     * Name of the location
     * @type {string}
     * @memberof CreateLocationRequest
     */
    'name': string;
    /**
     * Address of the location
     * @type {string}
     * @memberof CreateLocationRequest
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * Customer email
     * @type {string}
     * @memberof Customer
     */
    'email': string;
    /**
     * Customer name
     * @type {string}
     * @memberof Customer
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CustomerDetail
 */
export interface CustomerDetail {
    /**
     * 
     * @type {number}
     * @memberof CustomerDetail
     */
    'pk': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'lastName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetail
     */
    'birthDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    'pictureUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetail
     */
    'registrationDate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDetail
     */
    'profilePushAccepted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetail
     */
    'deviceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetail
     */
    'type': number;
}
/**
 * 
 * @export
 * @interface DashboardItem
 */
export interface DashboardItem {
    /**
     * 
     * @type {number}
     * @memberof DashboardItem
     */
    'pk': number;
    /**
     * 
     * @type {string}
     * @memberof DashboardItem
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DashboardItemGroup
 */
export interface DashboardItemGroup {
    /**
     * 
     * @type {string}
     * @memberof DashboardItemGroup
     */
    'title': string;
    /**
     * 
     * @type {Array<DashboardItem>}
     * @memberof DashboardItemGroup
     */
    'items': Array<DashboardItem>;
}
/**
 * 
 * @export
 * @interface GetCardsRequest
 */
export interface GetCardsRequest {
    /**
     * Array of variants primary keys, if empty every order is returned
     * @type {Array<number>}
     * @memberof GetCardsRequest
     */
    'pks': Array<number>;
    /**
     * Start date interval
     * @type {number}
     * @memberof GetCardsRequest
     */
    'startDate': number;
    /**
     * Start date interval
     * @type {number}
     * @memberof GetCardsRequest
     */
    'endDate': number;
}
/**
 * 
 * @export
 * @interface GetChartDataRequest
 */
export interface GetChartDataRequest {
    /**
     * Item primary keys
     * @type {Array<number>}
     * @memberof GetChartDataRequest
     */
    'pks'?: Array<number>;
    /**
     * Starting date of the data to retrieve
     * @type {number}
     * @memberof GetChartDataRequest
     */
    'startDate': number;
    /**
     * Ending date of the data to retrieve
     * @type {number}
     * @memberof GetChartDataRequest
     */
    'endDate': number;
}
/**
 * 
 * @export
 * @interface GetNotificationsRequest
 */
export interface GetNotificationsRequest {
    /**
     * Request date
     * @type {number}
     * @memberof GetNotificationsRequest
     */
    'from': number;
}
/**
 * 
 * @export
 * @interface GetProductOrdersRequest
 */
export interface GetProductOrdersRequest {
    /**
     * Array of variants primary keys, if empty every order is returned
     * @type {Array<number>}
     * @memberof GetProductOrdersRequest
     */
    'pks': Array<number>;
    /**
     * Interval start date in millis
     * @type {number}
     * @memberof GetProductOrdersRequest
     */
    'startDate': number;
    /**
     * Interval end date in millis
     * @type {number}
     * @memberof GetProductOrdersRequest
     */
    'endDate': number;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'imageUrl': string | null;
}
/**
 * 
 * @export
 * @interface InsertVisitRequest
 */
export interface InsertVisitRequest {
    /**
     * 
     * @type {InsertVisitRequestPoi}
     * @memberof InsertVisitRequest
     */
    'poi': InsertVisitRequestPoi;
    /**
     * 
     * @type {number}
     * @memberof InsertVisitRequest
     */
    'visitDate': number;
}
/**
 * 
 * @export
 * @interface InsertVisitRequestPoi
 */
export interface InsertVisitRequestPoi {
    /**
     * 
     * @type {number}
     * @memberof InsertVisitRequestPoi
     */
    'pk': number;
}
/**
 * 
 * @export
 * @interface InventoryVariant
 */
export interface InventoryVariant {
    /**
     * Primary Key of the variant
     * @type {number}
     * @memberof InventoryVariant
     */
    'pk': number;
    /**
     * Name of the variant
     * @type {string}
     * @memberof InventoryVariant
     */
    'name': string;
    /**
     * Stock keeping unit
     * @type {string}
     * @memberof InventoryVariant
     */
    'sku': string;
    /**
     * Variant price
     * @type {number}
     * @memberof InventoryVariant
     */
    'price': number;
    /**
     * 
     * @type {Array<Location>}
     * @memberof InventoryVariant
     */
    'inventoryLocations': Array<Location>;
}
/**
 * 
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * Line item ordinal identifier
     * @type {number}
     * @memberof LineItem
     */
    'ordinal': number;
    /**
     * Voucher
     * @type {string}
     * @memberof LineItem
     */
    'voucher': string | null;
    /**
     * Line item type
     * @type {string}
     * @memberof LineItem
     */
    'type': string;
    /**
     * Line item price
     * @type {number}
     * @memberof LineItem
     */
    'price': number;
    /**
     * Customer
     * @type {Customer}
     * @memberof LineItem
     */
    'customer': Customer;
}
/**
 * 
 * @export
 * @interface LinkedVariant
 */
export interface LinkedVariant {
    /**
     * 
     * @type {number}
     * @memberof LinkedVariant
     */
    'pk'?: number;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * ID of location
     * @type {number}
     * @memberof Location
     */
    'pk': number;
    /**
     * Name of the location
     * @type {string}
     * @memberof Location
     */
    'name': string;
    /**
     * Address of the location
     * @type {string}
     * @memberof Location
     */
    'address': string;
    /**
     * Viartual location or physical
     * @type {boolean}
     * @memberof Location
     */
    'virtual'?: boolean;
    /**
     * Currently unused
     * @type {number}
     * @memberof Location
     */
    'count': number | null;
}
/**
 * 
 * @export
 * @interface MoveBatch200Response
 */
export interface MoveBatch200Response {
    /**
     * 
     * @type {number}
     * @memberof MoveBatch200Response
     */
    'pk': number;
    /**
     * 
     * @type {number}
     * @memberof MoveBatch200Response
     */
    'batchStart': number;
    /**
     * 
     * @type {number}
     * @memberof MoveBatch200Response
     */
    'batchEnd': number;
    /**
     * 
     * @type {Location}
     * @memberof MoveBatch200Response
     */
    'inventoryLocation': Location;
}
/**
 * 
 * @export
 * @interface MoveBatchRequest
 */
export interface MoveBatchRequest {
    /**
     * Primary key of the batch
     * @type {number}
     * @memberof MoveBatchRequest
     */
    'pk': number;
    /**
     * Object inventory location contains only the pk
     * @type {object}
     * @memberof MoveBatchRequest
     */
    'inventoryLocation': object;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {CustomerDetail}
     * @memberof Notification
     */
    'customer': CustomerDetail;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'date': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * Order primary key
     * @type {number}
     * @memberof Order
     */
    'pk': number;
    /**
     * External id
     * @type {number}
     * @memberof Order
     */
    'externalId': number;
    /**
     * Order date
     * @type {number}
     * @memberof Order
     */
    'date': number;
    /**
     * Purchase count
     * @type {number}
     * @memberof Order
     */
    'purchaseCount': number;
    /**
     * Order total price
     * @type {number}
     * @memberof Order
     */
    'totalPrice': number;
    /**
     * Point of sale name
     * @type {string}
     * @memberof Order
     */
    'posName': string;
    /**
     * Order line items
     * @type {Array<LineItem>}
     * @memberof Order
     */
    'lineItems': Array<LineItem>;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * Organization Primary Key
     * @type {number}
     * @memberof Organization
     */
    'pk': number;
    /**
     * Organization name
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * Organization group name
     * @type {string}
     * @memberof Organization
     */
    'groupName': string | null;
}
/**
 * 
 * @export
 * @interface OrganizationDetail
 */
export interface OrganizationDetail {
    /**
     * Organization Primary Key
     * @type {number}
     * @memberof OrganizationDetail
     */
    'pk': number;
    /**
     * Organization name
     * @type {string}
     * @memberof OrganizationDetail
     */
    'name': string;
    /**
     * Organization group name
     * @type {string}
     * @memberof OrganizationDetail
     */
    'groupName': string | null;
    /**
     * 
     * @type {AddOrganizationRequestMetadata}
     * @memberof OrganizationDetail
     */
    'metadata': AddOrganizationRequestMetadata;
    /**
     * 
     * @type {AddOrganizationRequestPoi}
     * @memberof OrganizationDetail
     */
    'poi'?: AddOrganizationRequestPoi;
    /**
     * 
     * @type {AddOrganizationRequestPos}
     * @memberof OrganizationDetail
     */
    'pos'?: AddOrganizationRequestPos;
}
/**
 * 
 * @export
 * @interface Place
 */
export interface Place {
    /**
     * Place Primary key
     * @type {number}
     * @memberof Place
     */
    'pk': number;
    /**
     * Place name
     * @type {string}
     * @memberof Place
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Product primary Key
     * @type {number}
     * @memberof Product
     */
    'pk': number;
    /**
     * Product title
     * @type {string}
     * @memberof Product
     */
    'title': string;
    /**
     * Short description of the product
     * @type {string}
     * @memberof Product
     */
    'shortDescription': string;
    /**
     * Short description of the product
     * @type {string}
     * @memberof Product
     */
    'longDescription': string | null;
    /**
     * 
     * @type {Image}
     * @memberof Product
     */
    'coverImage': Image;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Product
     */
    'images': Array<Image>;
    /**
     * Number of variants associated with product
     * @type {number}
     * @memberof Product
     */
    'variantsCount': number;
}
/**
 * 
 * @export
 * @interface ProductVariant
 */
export interface ProductVariant {
    /**
     * Primary Key of the variant
     * @type {number}
     * @memberof ProductVariant
     */
    'pk': number | null;
    /**
     * Name of the variant
     * @type {string}
     * @memberof ProductVariant
     */
    'name': string;
    /**
     * Stock keeping unit
     * @type {string}
     * @memberof ProductVariant
     */
    'sku': string;
    /**
     * Variant price
     * @type {number}
     * @memberof ProductVariant
     */
    'price': number | null;
    /**
     * Duration of the variant
     * @type {number}
     * @memberof ProductVariant
     */
    'duration': number | null;
    /**
     * 
     * @type {Image}
     * @memberof ProductVariant
     */
    'image': Image;
    /**
     * 
     * @type {LinkedVariant}
     * @memberof ProductVariant
     */
    'linkedVariant': LinkedVariant | null;
    /**
     * 
     * @type {Array<Place>}
     * @memberof ProductVariant
     */
    'locations': Array<Place>;
}
/**
 * 
 * @export
 * @interface Rerun
 */
export interface Rerun {
    /**
     * Variant Primary key
     * @type {number}
     * @memberof Rerun
     */
    'pk': number;
    /**
     * Rerun date
     * @type {number}
     * @memberof Rerun
     */
    'date': number;
}
/**
 * 
 * @export
 * @interface SaveBatchRequest
 */
export interface SaveBatchRequest {
    /**
     * Batch starting interval
     * @type {number}
     * @memberof SaveBatchRequest
     */
    'batchStart': number;
    /**
     * Batch ending interval
     * @type {number}
     * @memberof SaveBatchRequest
     */
    'batchEnd': number;
    /**
     * Location where the batch is saved
     * @type {object}
     * @memberof SaveBatchRequest
     */
    'inventoryLocation': object;
    /**
     * Product variant associated to the new batch
     * @type {object}
     * @memberof SaveBatchRequest
     */
    'productVariant': object;
}
/**
 * 
 * @export
 * @interface SaveUserRequest
 */
export interface SaveUserRequest {
    /**
     * User primary key is null if the user is new
     * @type {any}
     * @memberof SaveUserRequest
     */
    'pk': any | null;
    /**
     * 
     * @type {string}
     * @memberof SaveUserRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SaveUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SaveUserRequest
     */
    'password': string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveUserRequest
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof SaveUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SaveUserRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SaveUserRequest
     */
    'company': string | null;
    /**
     * 
     * @type {any}
     * @memberof SaveUserRequest
     */
    'timestamp': any | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveUserRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveUserRequest
     */
    'notification': boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveUserRequest
     */
    'tenantId': number;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof SaveUserRequest
     */
    'organizations': Array<Organization>;
}
/**
 * 
 * @export
 * @interface SellingPoint
 */
export interface SellingPoint {
    /**
     * Order primary key
     * @type {number}
     * @memberof SellingPoint
     */
    'pk': number;
    /**
     * Selling point name
     * @type {string}
     * @memberof SellingPoint
     */
    'name': string;
    /**
     * application number
     * @type {number}
     * @memberof SellingPoint
     */
    'application': number;
}
/**
 * 
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * Setting Key
     * @type {string}
     * @memberof Setting
     */
    'key': string;
    /**
     * Setting value
     * @type {string}
     * @memberof Setting
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Show
 */
export interface Show {
    /**
     * Item Primary key
     * @type {number}
     * @memberof Show
     */
    'pk': number;
    /**
     * Item title
     * @type {string}
     * @memberof Show
     */
    'title': string;
    /**
     * Item replicas
     * @type {Array<Rerun>}
     * @memberof Show
     */
    'reruns': Array<Rerun>;
}
/**
 * 
 * @export
 * @interface SubscribeTicketsRequest
 */
export interface SubscribeTicketsRequest {
    /**
     * 
     * @type {Rerun}
     * @memberof SubscribeTicketsRequest
     */
    'rerun': Rerun;
    /**
     * Tickets to emit
     * @type {Array<string>}
     * @memberof SubscribeTicketsRequest
     */
    'subscriptionCodes': Array<string>;
}
/**
 * 
 * @export
 * @interface TicketingResponse
 */
export interface TicketingResponse {
    /**
     * Array containing the code for which the operations was successful
     * @type {Array<string>}
     * @memberof TicketingResponse
     */
    'successfulCodes': Array<string>;
    /**
     * Array containing the code for which the operations was unsuccessful
     * @type {Array<TicketingResponseFailedCodesInner>}
     * @memberof TicketingResponse
     */
    'failedCodes': Array<TicketingResponseFailedCodesInner>;
}
/**
 * 
 * @export
 * @interface TicketingResponseFailedCodesInner
 */
export interface TicketingResponseFailedCodesInner {
    /**
     * Failed codes
     * @type {string}
     * @memberof TicketingResponseFailedCodesInner
     */
    'code': string;
    /**
     * Error message
     * @type {string}
     * @memberof TicketingResponseFailedCodesInner
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface UpdateLocationRequest
 */
export interface UpdateLocationRequest {
    /**
     * Must be same as {pk}
     * @type {number}
     * @memberof UpdateLocationRequest
     */
    'pk': number;
    /**
     * Name of the location
     * @type {string}
     * @memberof UpdateLocationRequest
     */
    'name': string;
    /**
     * Address of the location
     * @type {string}
     * @memberof UpdateLocationRequest
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * User Primary Key
     * @type {number}
     * @memberof User
     */
    'pk': number;
    /**
     * Username
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * User password
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * User email
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * User role
     * @type {string}
     * @memberof User
     */
    'role': string;
    /**
     * User first name
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * User last name
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * User associated company
     * @type {string}
     * @memberof User
     */
    'company': string | null;
    /**
     * Timestamp
     * @type {number}
     * @memberof User
     */
    'timestamp': number | null;
    /**
     * User enabled
     * @type {boolean}
     * @memberof User
     */
    'enabled': boolean;
    /**
     * User notifications
     * @type {boolean}
     * @memberof User
     */
    'notification': boolean;
    /**
     * User tenant ID
     * @type {number}
     * @memberof User
     */
    'tenantId': number;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof User
     */
    'organizations': Array<Organization>;
    /**
     * 
     * @type {Application}
     * @memberof User
     */
    'application': Application;
}
/**
 * 
 * @export
 * @interface Visit
 */
export interface Visit {
    /**
     * Visit primary key
     * @type {number}
     * @memberof Visit
     */
    'pk': number;
    /**
     * Name of the place visited
     * @type {string}
     * @memberof Visit
     */
    'name': string;
    /**
     * Visit date
     * @type {number}
     * @memberof Visit
     */
    'date': number;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the current logged user
         * @summary Get the current logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs the user into the system by sending username, password (encrypted) and a remember me flag
         * @summary Logs user into the system
         * @param {string} username The user name for login
         * @param {string} password The password for login hashed with sha512
         * @param {boolean} rememberMe Remembers the session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (username: string, password: string, rememberMe: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('login', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('login', 'password', password)
            // verify required parameter 'rememberMe' is not null or undefined
            assertParamExists('login', 'rememberMe', rememberMe)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (rememberMe !== undefined) {
                localVarQueryParameter['remember-me'] = rememberMe;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout current user from the Console
         * @summary Logout current user from the Console
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the current logged user
         * @summary Get the current logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.getCurrentUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Logs the user into the system by sending username, password (encrypted) and a remember me flag
         * @summary Logs user into the system
         * @param {string} username The user name for login
         * @param {string} password The password for login hashed with sha512
         * @param {boolean} rememberMe Remembers the session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(username: string, password: string, rememberMe: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(username, password, rememberMe, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.login']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Logout current user from the Console
         * @summary Logout current user from the Console
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.logout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Get the current logged user
         * @summary Get the current logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<User> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Logs the user into the system by sending username, password (encrypted) and a remember me flag
         * @summary Logs user into the system
         * @param {string} username The user name for login
         * @param {string} password The password for login hashed with sha512
         * @param {boolean} rememberMe Remembers the session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(username: string, password: string, rememberMe: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.login(username, password, rememberMe, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout current user from the Console
         * @summary Logout current user from the Console
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - interface
 * @export
 * @interface AuthenticationApi
 */
export interface AuthenticationApiInterface {
    /**
     * Get the current logged user
     * @summary Get the current logged user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiInterface
     */
    getCurrentUser(options?: RawAxiosRequestConfig): AxiosPromise<User>;

    /**
     * Logs the user into the system by sending username, password (encrypted) and a remember me flag
     * @summary Logs user into the system
     * @param {string} username The user name for login
     * @param {string} password The password for login hashed with sha512
     * @param {boolean} rememberMe Remembers the session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiInterface
     */
    login(username: string, password: string, rememberMe: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Logout current user from the Console
     * @summary Logout current user from the Console
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiInterface
     */
    logout(options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI implements AuthenticationApiInterface {
    /**
     * Get the current logged user
     * @summary Get the current logged user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getCurrentUser(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs the user into the system by sending username, password (encrypted) and a remember me flag
     * @summary Logs user into the system
     * @param {string} username The user name for login
     * @param {string} password The password for login hashed with sha512
     * @param {boolean} rememberMe Remembers the session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(username: string, password: string, rememberMe: boolean, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).login(username, password, rememberMe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout current user from the Console
     * @summary Logout current user from the Console
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logout(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CardsApi - axios parameter creator
 * @export
 */
export const CardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete the card and visits associated with the path parameter pk
         * @summary Delete the card and visits associated with the path parameter pk
         * @param {number} pk Primary key of the Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('deleteCard', 'pk', pk)
            const localVarPath = `/products/cards/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the data of the card identified by the primary key pk in the URL
         * @summary Returns the data of the card identified by the primary key pk in the URL
         * @param {number} pk Primary key of the Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardDetails: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getCardDetails', 'pk', pk)
            const localVarPath = `/products/cards/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
         * @summary Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
         * @param {GetCardsRequest} getCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards: async (getCardsRequest: GetCardsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCardsRequest' is not null or undefined
            assertParamExists('getCards', 'getCardsRequest', getCardsRequest)
            const localVarPath = `/products/getCards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCardsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the product variants for the application of the user making the request.
         * @summary Returns the product variants for the application of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariants: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/variants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
         * @summary Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
         * @param {number} cardPk Primary key of the Card
         * @param {InsertVisitRequest} insertVisitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertVisit: async (cardPk: number, insertVisitRequest: InsertVisitRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardPk' is not null or undefined
            assertParamExists('insertVisit', 'cardPk', cardPk)
            // verify required parameter 'insertVisitRequest' is not null or undefined
            assertParamExists('insertVisit', 'insertVisitRequest', insertVisitRequest)
            const localVarPath = `/products/cards/{cardPk}`
                .replace(`{${"cardPk"}}`, encodeURIComponent(String(cardPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertVisitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
         * @summary Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
         * @param {number} cardPk Primary key of the Card
         * @param {number} poiPk Primary key of the Point of interest
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitDate: async (cardPk: number, poiPk: number, body: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardPk' is not null or undefined
            assertParamExists('updateVisitDate', 'cardPk', cardPk)
            // verify required parameter 'poiPk' is not null or undefined
            assertParamExists('updateVisitDate', 'poiPk', poiPk)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateVisitDate', 'body', body)
            const localVarPath = `/products/cards/{cardPk}/poi/{poiPk}`
                .replace(`{${"cardPk"}}`, encodeURIComponent(String(cardPk)))
                .replace(`{${"poiPk"}}`, encodeURIComponent(String(poiPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardsApi - functional programming interface
 * @export
 */
export const CardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete the card and visits associated with the path parameter pk
         * @summary Delete the card and visits associated with the path parameter pk
         * @param {number} pk Primary key of the Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCard(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCard(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CardsApi.deleteCard']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns the data of the card identified by the primary key pk in the URL
         * @summary Returns the data of the card identified by the primary key pk in the URL
         * @param {number} pk Primary key of the Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardDetails(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardDetails(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CardsApi.getCardDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
         * @summary Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
         * @param {GetCardsRequest} getCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCards(getCardsRequest: GetCardsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Card>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCards(getCardsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CardsApi.getCards']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns the product variants for the application of the user making the request.
         * @summary Returns the product variants for the application of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariants(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CardVariant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVariants(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CardsApi.getVariants']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
         * @summary Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
         * @param {number} cardPk Primary key of the Card
         * @param {InsertVisitRequest} insertVisitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertVisit(cardPk: number, insertVisitRequest: InsertVisitRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertVisit(cardPk, insertVisitRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CardsApi.insertVisit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
         * @summary Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
         * @param {number} cardPk Primary key of the Card
         * @param {number} poiPk Primary key of the Point of interest
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVisitDate(cardPk: number, poiPk: number, body: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVisitDate(cardPk, poiPk, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CardsApi.updateVisitDate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CardsApi - factory interface
 * @export
 */
export const CardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardsApiFp(configuration)
    return {
        /**
         * Delete the card and visits associated with the path parameter pk
         * @summary Delete the card and visits associated with the path parameter pk
         * @param {number} pk Primary key of the Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard(pk: number, options?: any): AxiosPromise<Card> {
            return localVarFp.deleteCard(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the data of the card identified by the primary key pk in the URL
         * @summary Returns the data of the card identified by the primary key pk in the URL
         * @param {number} pk Primary key of the Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardDetails(pk: number, options?: any): AxiosPromise<Card> {
            return localVarFp.getCardDetails(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
         * @summary Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
         * @param {GetCardsRequest} getCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(getCardsRequest: GetCardsRequest, options?: any): AxiosPromise<Array<Card>> {
            return localVarFp.getCards(getCardsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the product variants for the application of the user making the request.
         * @summary Returns the product variants for the application of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariants(options?: any): AxiosPromise<Array<CardVariant>> {
            return localVarFp.getVariants(options).then((request) => request(axios, basePath));
        },
        /**
         * Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
         * @summary Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
         * @param {number} cardPk Primary key of the Card
         * @param {InsertVisitRequest} insertVisitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertVisit(cardPk: number, insertVisitRequest: InsertVisitRequest, options?: any): AxiosPromise<Card> {
            return localVarFp.insertVisit(cardPk, insertVisitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
         * @summary Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
         * @param {number} cardPk Primary key of the Card
         * @param {number} poiPk Primary key of the Point of interest
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitDate(cardPk: number, poiPk: number, body: number, options?: any): AxiosPromise<Card> {
            return localVarFp.updateVisitDate(cardPk, poiPk, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardsApi - interface
 * @export
 * @interface CardsApi
 */
export interface CardsApiInterface {
    /**
     * Delete the card and visits associated with the path parameter pk
     * @summary Delete the card and visits associated with the path parameter pk
     * @param {number} pk Primary key of the Card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApiInterface
     */
    deleteCard(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<Card>;

    /**
     * Returns the data of the card identified by the primary key pk in the URL
     * @summary Returns the data of the card identified by the primary key pk in the URL
     * @param {number} pk Primary key of the Card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApiInterface
     */
    getCardDetails(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<Card>;

    /**
     * Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
     * @summary Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
     * @param {GetCardsRequest} getCardsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApiInterface
     */
    getCards(getCardsRequest: GetCardsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Card>>;

    /**
     * Returns the product variants for the application of the user making the request.
     * @summary Returns the product variants for the application of the user making the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApiInterface
     */
    getVariants(options?: RawAxiosRequestConfig): AxiosPromise<Array<CardVariant>>;

    /**
     * Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
     * @summary Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
     * @param {number} cardPk Primary key of the Card
     * @param {InsertVisitRequest} insertVisitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApiInterface
     */
    insertVisit(cardPk: number, insertVisitRequest: InsertVisitRequest, options?: RawAxiosRequestConfig): AxiosPromise<Card>;

    /**
     * Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
     * @summary Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
     * @param {number} cardPk Primary key of the Card
     * @param {number} poiPk Primary key of the Point of interest
     * @param {number} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApiInterface
     */
    updateVisitDate(cardPk: number, poiPk: number, body: number, options?: RawAxiosRequestConfig): AxiosPromise<Card>;

}

/**
 * CardsApi - object-oriented interface
 * @export
 * @class CardsApi
 * @extends {BaseAPI}
 */
export class CardsApi extends BaseAPI implements CardsApiInterface {
    /**
     * Delete the card and visits associated with the path parameter pk
     * @summary Delete the card and visits associated with the path parameter pk
     * @param {number} pk Primary key of the Card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public deleteCard(pk: number, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).deleteCard(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the data of the card identified by the primary key pk in the URL
     * @summary Returns the data of the card identified by the primary key pk in the URL
     * @param {number} pk Primary key of the Card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public getCardDetails(pk: number, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).getCardDetails(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
     * @summary Returns a list of all cards registered on the system in a given time interval, relating to an optional set of product variants.
     * @param {GetCardsRequest} getCardsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public getCards(getCardsRequest: GetCardsRequest, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).getCards(getCardsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the product variants for the application of the user making the request.
     * @summary Returns the product variants for the application of the user making the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public getVariants(options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).getVariants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
     * @summary Insert a new visit for a given product unit identified by <cardPk>. The visit to insert needs to be associated with a poi and a visit date passed in the request body.
     * @param {number} cardPk Primary key of the Card
     * @param {InsertVisitRequest} insertVisitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public insertVisit(cardPk: number, insertVisitRequest: InsertVisitRequest, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).insertVisit(cardPk, insertVisitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
     * @summary Modify a visit for a given product unit identified by <cardPk>. The visit to modify needs to be associated with a poi, specified as a path parameter <poiPK>, and a visit date passed in the request body.
     * @param {number} cardPk Primary key of the Card
     * @param {number} poiPk Primary key of the Point of interest
     * @param {number} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public updateVisitDate(cardPk: number, poiPk: number, body: number, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).updateVisitDate(cardPk, poiPk, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a list of all customers
         * @summary Get a list of all customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save customers
         * @summary Save customers
         * @param {Array<CustomerDetail>} customerDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomers: async (customerDetail: Array<CustomerDetail>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerDetail' is not null or undefined
            assertParamExists('saveCustomers', 'customerDetail', customerDetail)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a list of all customers
         * @summary Get a list of all customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.getCustomers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Save customers
         * @summary Save customers
         * @param {Array<CustomerDetail>} customerDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCustomers(customerDetail: Array<CustomerDetail>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCustomers(customerDetail, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.saveCustomers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * Get a list of all customers
         * @summary Get a list of all customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(options?: any): AxiosPromise<Array<CustomerDetail>> {
            return localVarFp.getCustomers(options).then((request) => request(axios, basePath));
        },
        /**
         * Save customers
         * @summary Save customers
         * @param {Array<CustomerDetail>} customerDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomers(customerDetail: Array<CustomerDetail>, options?: any): AxiosPromise<Array<CustomerDetail>> {
            return localVarFp.saveCustomers(customerDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - interface
 * @export
 * @interface CustomerApi
 */
export interface CustomerApiInterface {
    /**
     * Get a list of all customers
     * @summary Get a list of all customers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    getCustomers(options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerDetail>>;

    /**
     * Save customers
     * @summary Save customers
     * @param {Array<CustomerDetail>} customerDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    saveCustomers(customerDetail: Array<CustomerDetail>, options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerDetail>>;

}

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI implements CustomerApiInterface {
    /**
     * Get a list of all customers
     * @summary Get a list of all customers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomers(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save customers
     * @summary Save customers
     * @param {Array<CustomerDetail>} customerDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public saveCustomers(customerDetail: Array<CustomerDetail>, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).saveCustomers(customerDetail, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get line chart data
         * @summary Get line chart data
         * @param {string} datasource DataSource of the api to call
         * @param {GetChartDataRequest} getChartDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartData: async (datasource: string, getChartDataRequest: GetChartDataRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasource' is not null or undefined
            assertParamExists('getChartData', 'datasource', datasource)
            // verify required parameter 'getChartDataRequest' is not null or undefined
            assertParamExists('getChartData', 'getChartDataRequest', getChartDataRequest)
            const localVarPath = `/graphs/{datasource}`
                .replace(`{${"datasource"}}`, encodeURIComponent(String(datasource)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getChartDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of items divided by groups
         * @summary Get a list of items divided by groups
         * @param {string} datasource DataSource of the api to call
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectorItems: async (datasource: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasource' is not null or undefined
            assertParamExists('getSelectorItems', 'datasource', datasource)
            const localVarPath = `/dashboard/{datasource}`
                .replace(`{${"datasource"}}`, encodeURIComponent(String(datasource)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * Get line chart data
         * @summary Get line chart data
         * @param {string} datasource DataSource of the api to call
         * @param {GetChartDataRequest} getChartDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartData(datasource: string, getChartDataRequest: GetChartDataRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartData(datasource, getChartDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.getChartData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get a list of items divided by groups
         * @summary Get a list of items divided by groups
         * @param {string} datasource DataSource of the api to call
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectorItems(datasource: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DashboardItemGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectorItems(datasource, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.getSelectorItems']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * Get line chart data
         * @summary Get line chart data
         * @param {string} datasource DataSource of the api to call
         * @param {GetChartDataRequest} getChartDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartData(datasource: string, getChartDataRequest: GetChartDataRequest, options?: any): AxiosPromise<ChartItem> {
            return localVarFp.getChartData(datasource, getChartDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of items divided by groups
         * @summary Get a list of items divided by groups
         * @param {string} datasource DataSource of the api to call
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectorItems(datasource: string, options?: any): AxiosPromise<Array<DashboardItemGroup>> {
            return localVarFp.getSelectorItems(datasource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - interface
 * @export
 * @interface DashboardApi
 */
export interface DashboardApiInterface {
    /**
     * Get line chart data
     * @summary Get line chart data
     * @param {string} datasource DataSource of the api to call
     * @param {GetChartDataRequest} getChartDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    getChartData(datasource: string, getChartDataRequest: GetChartDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChartItem>;

    /**
     * Get a list of items divided by groups
     * @summary Get a list of items divided by groups
     * @param {string} datasource DataSource of the api to call
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    getSelectorItems(datasource: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DashboardItemGroup>>;

}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI implements DashboardApiInterface {
    /**
     * Get line chart data
     * @summary Get line chart data
     * @param {string} datasource DataSource of the api to call
     * @param {GetChartDataRequest} getChartDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getChartData(datasource: string, getChartDataRequest: GetChartDataRequest, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getChartData(datasource, getChartDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of items divided by groups
     * @summary Get a list of items divided by groups
     * @param {string} datasource DataSource of the api to call
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getSelectorItems(datasource: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getSelectorItems(datasource, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryApi - axios parameter creator
 * @export
 */
export const InventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new location
         * @summary Creates a new location
         * @param {CreateLocationRequest} createLocationRequest Create a new location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (createLocationRequest: CreateLocationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLocationRequest' is not null or undefined
            assertParamExists('createLocation', 'createLocationRequest', createLocationRequest)
            const localVarPath = `/inventory/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
         * @summary Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
         * @param {number} pk Primary key of the batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('deleteBatch', 'pk', pk)
            const localVarPath = `/inventory/batches/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the inventory location identified by {pk}
         * @summary Delete the inventory location identified by {pk}
         * @param {number} pk Primary key of the Inventory Location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('deleteLocation', 'pk', pk)
            const localVarPath = `/inventory/locations/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all variants defined for the product identified by {pk}
         * @summary Gets a list of all variants defined for the product identified by {pk}
         * @param {number} pk Primary key of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryVariants: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getInventoryVariants', 'pk', pk)
            const localVarPath = `/inventory/products/{pk}/variants`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
         * @summary Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
         * @param {number} locationPk Primary key of the Inventory Location
         * @param {number} variantPk Primary key of the variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationBatches: async (locationPk: number, variantPk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationPk' is not null or undefined
            assertParamExists('getLocationBatches', 'locationPk', locationPk)
            // verify required parameter 'variantPk' is not null or undefined
            assertParamExists('getLocationBatches', 'variantPk', variantPk)
            const localVarPath = `/inventory/locations/{locationPk}/batches/variant/{variantPk}`
                .replace(`{${"locationPk"}}`, encodeURIComponent(String(locationPk)))
                .replace(`{${"variantPk"}}`, encodeURIComponent(String(variantPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move the inventory batch (a.k.a. stocks) passed in the body of the request to the inventory location whose primary key on the database matches the path parameter pk. The inventory batch must be sent with its original inventory location.
         * @summary Move the inventory batch (a.k.a. stocks) passed in the body of the request to the specified inventory location
         * @param {number} pk Primary key of the Inventory Location
         * @param {MoveBatchRequest} moveBatchRequest Save a new inventory batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveBatch: async (pk: number, moveBatchRequest: MoveBatchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('moveBatch', 'pk', pk)
            // verify required parameter 'moveBatchRequest' is not null or undefined
            assertParamExists('moveBatch', 'moveBatchRequest', moveBatchRequest)
            const localVarPath = `/inventory/locations/{pk}/batches`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save a new inventory batch (a.k.a. stocks) bound to the application of the user making the request, and return the corresponding object with the data that has been stored
         * @summary Save a new inventory batch
         * @param {SaveBatchRequest} saveBatchRequest Save a new inventory batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBatch: async (saveBatchRequest: SaveBatchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveBatchRequest' is not null or undefined
            assertParamExists('saveBatch', 'saveBatchRequest', saveBatchRequest)
            const localVarPath = `/inventory/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a location
         * @summary Updates a location
         * @param {number} pk ID of the location to update
         * @param {UpdateLocationRequest} updateLocationRequest Updates a location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (pk: number, updateLocationRequest: UpdateLocationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('updateLocation', 'pk', pk)
            // verify required parameter 'updateLocationRequest' is not null or undefined
            assertParamExists('updateLocation', 'updateLocationRequest', updateLocationRequest)
            const localVarPath = `/inventory/locations/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryApi - functional programming interface
 * @export
 */
export const InventoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new location
         * @summary Creates a new location
         * @param {CreateLocationRequest} createLocationRequest Create a new location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(createLocationRequest: CreateLocationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(createLocationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InventoryApi.createLocation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
         * @summary Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
         * @param {number} pk Primary key of the batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatch(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBatch(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InventoryApi.deleteBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Delete the inventory location identified by {pk}
         * @summary Delete the inventory location identified by {pk}
         * @param {number} pk Primary key of the Inventory Location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InventoryApi.deleteLocation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets a list of all variants defined for the product identified by {pk}
         * @summary Gets a list of all variants defined for the product identified by {pk}
         * @param {number} pk Primary key of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInventoryVariants(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InventoryVariant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryVariants(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InventoryApi.getInventoryVariants']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
         * @summary Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
         * @param {number} locationPk Primary key of the Inventory Location
         * @param {number} variantPk Primary key of the variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationBatches(locationPk: number, variantPk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Batch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationBatches(locationPk, variantPk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InventoryApi.getLocationBatches']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Move the inventory batch (a.k.a. stocks) passed in the body of the request to the inventory location whose primary key on the database matches the path parameter pk. The inventory batch must be sent with its original inventory location.
         * @summary Move the inventory batch (a.k.a. stocks) passed in the body of the request to the specified inventory location
         * @param {number} pk Primary key of the Inventory Location
         * @param {MoveBatchRequest} moveBatchRequest Save a new inventory batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveBatch(pk: number, moveBatchRequest: MoveBatchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MoveBatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveBatch(pk, moveBatchRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InventoryApi.moveBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Save a new inventory batch (a.k.a. stocks) bound to the application of the user making the request, and return the corresponding object with the data that has been stored
         * @summary Save a new inventory batch
         * @param {SaveBatchRequest} saveBatchRequest Save a new inventory batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBatch(saveBatchRequest: SaveBatchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBatch(saveBatchRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InventoryApi.saveBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates a location
         * @summary Updates a location
         * @param {number} pk ID of the location to update
         * @param {UpdateLocationRequest} updateLocationRequest Updates a location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(pk: number, updateLocationRequest: UpdateLocationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(pk, updateLocationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InventoryApi.updateLocation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InventoryApi - factory interface
 * @export
 */
export const InventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryApiFp(configuration)
    return {
        /**
         * Creates a new location
         * @summary Creates a new location
         * @param {CreateLocationRequest} createLocationRequest Create a new location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(createLocationRequest: CreateLocationRequest, options?: any): AxiosPromise<Location> {
            return localVarFp.createLocation(createLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
         * @summary Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
         * @param {number} pk Primary key of the batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch(pk: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBatch(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the inventory location identified by {pk}
         * @summary Delete the inventory location identified by {pk}
         * @param {number} pk Primary key of the Inventory Location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(pk: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLocation(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all variants defined for the product identified by {pk}
         * @summary Gets a list of all variants defined for the product identified by {pk}
         * @param {number} pk Primary key of a product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryVariants(pk: number, options?: any): AxiosPromise<Array<InventoryVariant>> {
            return localVarFp.getInventoryVariants(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
         * @summary Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
         * @param {number} locationPk Primary key of the Inventory Location
         * @param {number} variantPk Primary key of the variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationBatches(locationPk: number, variantPk: number, options?: any): AxiosPromise<Array<Batch>> {
            return localVarFp.getLocationBatches(locationPk, variantPk, options).then((request) => request(axios, basePath));
        },
        /**
         * Move the inventory batch (a.k.a. stocks) passed in the body of the request to the inventory location whose primary key on the database matches the path parameter pk. The inventory batch must be sent with its original inventory location.
         * @summary Move the inventory batch (a.k.a. stocks) passed in the body of the request to the specified inventory location
         * @param {number} pk Primary key of the Inventory Location
         * @param {MoveBatchRequest} moveBatchRequest Save a new inventory batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveBatch(pk: number, moveBatchRequest: MoveBatchRequest, options?: any): AxiosPromise<MoveBatch200Response> {
            return localVarFp.moveBatch(pk, moveBatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Save a new inventory batch (a.k.a. stocks) bound to the application of the user making the request, and return the corresponding object with the data that has been stored
         * @summary Save a new inventory batch
         * @param {SaveBatchRequest} saveBatchRequest Save a new inventory batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBatch(saveBatchRequest: SaveBatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveBatch(saveBatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a location
         * @summary Updates a location
         * @param {number} pk ID of the location to update
         * @param {UpdateLocationRequest} updateLocationRequest Updates a location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(pk: number, updateLocationRequest: UpdateLocationRequest, options?: any): AxiosPromise<Location> {
            return localVarFp.updateLocation(pk, updateLocationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryApi - interface
 * @export
 * @interface InventoryApi
 */
export interface InventoryApiInterface {
    /**
     * Creates a new location
     * @summary Creates a new location
     * @param {CreateLocationRequest} createLocationRequest Create a new location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    createLocation(createLocationRequest: CreateLocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Location>;

    /**
     * Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
     * @summary Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
     * @param {number} pk Primary key of the batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    deleteBatch(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete the inventory location identified by {pk}
     * @summary Delete the inventory location identified by {pk}
     * @param {number} pk Primary key of the Inventory Location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    deleteLocation(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Gets a list of all variants defined for the product identified by {pk}
     * @summary Gets a list of all variants defined for the product identified by {pk}
     * @param {number} pk Primary key of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    getInventoryVariants(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<InventoryVariant>>;

    /**
     * Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
     * @summary Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
     * @param {number} locationPk Primary key of the Inventory Location
     * @param {number} variantPk Primary key of the variant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    getLocationBatches(locationPk: number, variantPk: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<Batch>>;

    /**
     * Move the inventory batch (a.k.a. stocks) passed in the body of the request to the inventory location whose primary key on the database matches the path parameter pk. The inventory batch must be sent with its original inventory location.
     * @summary Move the inventory batch (a.k.a. stocks) passed in the body of the request to the specified inventory location
     * @param {number} pk Primary key of the Inventory Location
     * @param {MoveBatchRequest} moveBatchRequest Save a new inventory batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    moveBatch(pk: number, moveBatchRequest: MoveBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<MoveBatch200Response>;

    /**
     * Save a new inventory batch (a.k.a. stocks) bound to the application of the user making the request, and return the corresponding object with the data that has been stored
     * @summary Save a new inventory batch
     * @param {SaveBatchRequest} saveBatchRequest Save a new inventory batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    saveBatch(saveBatchRequest: SaveBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates a location
     * @summary Updates a location
     * @param {number} pk ID of the location to update
     * @param {UpdateLocationRequest} updateLocationRequest Updates a location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    updateLocation(pk: number, updateLocationRequest: UpdateLocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Location>;

}

/**
 * InventoryApi - object-oriented interface
 * @export
 * @class InventoryApi
 * @extends {BaseAPI}
 */
export class InventoryApi extends BaseAPI implements InventoryApiInterface {
    /**
     * Creates a new location
     * @summary Creates a new location
     * @param {CreateLocationRequest} createLocationRequest Create a new location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public createLocation(createLocationRequest: CreateLocationRequest, options?: RawAxiosRequestConfig) {
        return InventoryApiFp(this.configuration).createLocation(createLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
     * @summary Delete the existing inventory batch whose primary key on the database matches the path parameter pk.
     * @param {number} pk Primary key of the batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public deleteBatch(pk: number, options?: RawAxiosRequestConfig) {
        return InventoryApiFp(this.configuration).deleteBatch(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the inventory location identified by {pk}
     * @summary Delete the inventory location identified by {pk}
     * @param {number} pk Primary key of the Inventory Location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public deleteLocation(pk: number, options?: RawAxiosRequestConfig) {
        return InventoryApiFp(this.configuration).deleteLocation(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all variants defined for the product identified by {pk}
     * @summary Gets a list of all variants defined for the product identified by {pk}
     * @param {number} pk Primary key of a product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public getInventoryVariants(pk: number, options?: RawAxiosRequestConfig) {
        return InventoryApiFp(this.configuration).getInventoryVariants(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
     * @summary Return all the inventory batches for the product variant identified by the <variant_pk> path parameter that are stocked in the inventory location identified by the <location_pk> path parameter.
     * @param {number} locationPk Primary key of the Inventory Location
     * @param {number} variantPk Primary key of the variant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public getLocationBatches(locationPk: number, variantPk: number, options?: RawAxiosRequestConfig) {
        return InventoryApiFp(this.configuration).getLocationBatches(locationPk, variantPk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Move the inventory batch (a.k.a. stocks) passed in the body of the request to the inventory location whose primary key on the database matches the path parameter pk. The inventory batch must be sent with its original inventory location.
     * @summary Move the inventory batch (a.k.a. stocks) passed in the body of the request to the specified inventory location
     * @param {number} pk Primary key of the Inventory Location
     * @param {MoveBatchRequest} moveBatchRequest Save a new inventory batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public moveBatch(pk: number, moveBatchRequest: MoveBatchRequest, options?: RawAxiosRequestConfig) {
        return InventoryApiFp(this.configuration).moveBatch(pk, moveBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save a new inventory batch (a.k.a. stocks) bound to the application of the user making the request, and return the corresponding object with the data that has been stored
     * @summary Save a new inventory batch
     * @param {SaveBatchRequest} saveBatchRequest Save a new inventory batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public saveBatch(saveBatchRequest: SaveBatchRequest, options?: RawAxiosRequestConfig) {
        return InventoryApiFp(this.configuration).saveBatch(saveBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a location
     * @summary Updates a location
     * @param {number} pk ID of the location to update
     * @param {UpdateLocationRequest} updateLocationRequest Updates a location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public updateLocation(pk: number, updateLocationRequest: UpdateLocationRequest, options?: RawAxiosRequestConfig) {
        return InventoryApiFp(this.configuration).updateLocation(pk, updateLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a list of user associated notification
         * @summary Get a list of user associated notification
         * @param {GetNotificationsRequest} getNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (getNotificationsRequest: GetNotificationsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getNotificationsRequest' is not null or undefined
            assertParamExists('getNotifications', 'getNotificationsRequest', getNotificationsRequest)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getNotificationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a list of user associated notification
         * @summary Get a list of user associated notification
         * @param {GetNotificationsRequest} getNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(getNotificationsRequest: GetNotificationsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(getNotificationsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationApi.getNotifications']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * Get a list of user associated notification
         * @summary Get a list of user associated notification
         * @param {GetNotificationsRequest} getNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(getNotificationsRequest: GetNotificationsRequest, options?: any): AxiosPromise<Array<Notification>> {
            return localVarFp.getNotifications(getNotificationsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - interface
 * @export
 * @interface NotificationApi
 */
export interface NotificationApiInterface {
    /**
     * Get a list of user associated notification
     * @summary Get a list of user associated notification
     * @param {GetNotificationsRequest} getNotificationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    getNotifications(getNotificationsRequest: GetNotificationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Notification>>;

}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI implements NotificationApiInterface {
    /**
     * Get a list of user associated notification
     * @summary Get a list of user associated notification
     * @param {GetNotificationsRequest} getNotificationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotifications(getNotificationsRequest: GetNotificationsRequest, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getNotifications(getNotificationsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Save a new organization
         * @summary Save a new organization
         * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganization: async (addOrganizationRequest: AddOrganizationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addOrganizationRequest' is not null or undefined
            assertParamExists('addOrganization', 'addOrganizationRequest', addOrganizationRequest)
            const localVarPath = `/users/current/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the organization identified by the <pk> path parameter
         * @summary Delete the organization identified by the <pk> path parameter
         * @param {number} pk ID of the organization to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('deleteOrganization', 'pk', pk)
            const localVarPath = `/users/current/organizations/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all POI belonging to the application associated with the user making the request.
         * @summary Returns a list of all POI belonging to the application associated with the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociatedPoiList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/poi/getAssociatedPoiList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
         * @summary Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
         * @param {number} pk ID of the organization to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDetails: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getOrganizationDetails', 'pk', pk)
            const localVarPath = `/users/current/organizations/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all the organizations associated with the current user
         * @summary Returns a list of all the organizations associated with the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/current/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all POI belonging to the application associated with the user making the request.
         * @summary Get the Point of Interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoi: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/poi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all POS belonging to the application associated with the user making the request.
         * @summary Get the Point of Sale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPos: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the organization identified by the <pk> path parameter
         * @summary Update the organization identified by the <pk> path parameter
         * @param {number} pk ID of the organization to request
         * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (pk: number, addOrganizationRequest: AddOrganizationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('updateOrganization', 'pk', pk)
            // verify required parameter 'addOrganizationRequest' is not null or undefined
            assertParamExists('updateOrganization', 'addOrganizationRequest', addOrganizationRequest)
            const localVarPath = `/users/current/organizations/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Save a new organization
         * @summary Save a new organization
         * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganization(addOrganizationRequest: AddOrganizationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganization(addOrganizationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.addOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Delete the organization identified by the <pk> path parameter
         * @summary Delete the organization identified by the <pk> path parameter
         * @param {number} pk ID of the organization to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganization(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganization(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.deleteOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of all POI belonging to the application associated with the user making the request.
         * @summary Returns a list of all POI belonging to the application associated with the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssociatedPoiList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Place>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociatedPoiList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getAssociatedPoiList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
         * @summary Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
         * @param {number} pk ID of the organization to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationDetails(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationDetails(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getOrganizationDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of all the organizations associated with the current user
         * @summary Returns a list of all the organizations associated with the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getOrganizations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of all POI belonging to the application associated with the user making the request.
         * @summary Get the Point of Interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoi(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Place>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoi(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getPoi']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of all POS belonging to the application associated with the user making the request.
         * @summary Get the Point of Sale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPos(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Place>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPos(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getPos']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update the organization identified by the <pk> path parameter
         * @summary Update the organization identified by the <pk> path parameter
         * @param {number} pk ID of the organization to request
         * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(pk: number, addOrganizationRequest: AddOrganizationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(pk, addOrganizationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.updateOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * Save a new organization
         * @summary Save a new organization
         * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganization(addOrganizationRequest: AddOrganizationRequest, options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.addOrganization(addOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the organization identified by the <pk> path parameter
         * @summary Delete the organization identified by the <pk> path parameter
         * @param {number} pk ID of the organization to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization(pk: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganization(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all POI belonging to the application associated with the user making the request.
         * @summary Returns a list of all POI belonging to the application associated with the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociatedPoiList(options?: any): AxiosPromise<Array<Place>> {
            return localVarFp.getAssociatedPoiList(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
         * @summary Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
         * @param {number} pk ID of the organization to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDetails(pk: number, options?: any): AxiosPromise<OrganizationDetail> {
            return localVarFp.getOrganizationDetails(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all the organizations associated with the current user
         * @summary Returns a list of all the organizations associated with the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(options?: any): AxiosPromise<Array<OrganizationDetail>> {
            return localVarFp.getOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all POI belonging to the application associated with the user making the request.
         * @summary Get the Point of Interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoi(options?: any): AxiosPromise<Array<Place>> {
            return localVarFp.getPoi(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all POS belonging to the application associated with the user making the request.
         * @summary Get the Point of Sale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPos(options?: any): AxiosPromise<Array<Place>> {
            return localVarFp.getPos(options).then((request) => request(axios, basePath));
        },
        /**
         * Update the organization identified by the <pk> path parameter
         * @summary Update the organization identified by the <pk> path parameter
         * @param {number} pk ID of the organization to request
         * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(pk: number, addOrganizationRequest: AddOrganizationRequest, options?: any): AxiosPromise<OrganizationDetail> {
            return localVarFp.updateOrganization(pk, addOrganizationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - interface
 * @export
 * @interface OrganizationsApi
 */
export interface OrganizationsApiInterface {
    /**
     * Save a new organization
     * @summary Save a new organization
     * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    addOrganization(addOrganizationRequest: AddOrganizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Organization>>;

    /**
     * Delete the organization identified by the <pk> path parameter
     * @summary Delete the organization identified by the <pk> path parameter
     * @param {number} pk ID of the organization to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    deleteOrganization(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns a list of all POI belonging to the application associated with the user making the request.
     * @summary Returns a list of all POI belonging to the application associated with the user making the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    getAssociatedPoiList(options?: RawAxiosRequestConfig): AxiosPromise<Array<Place>>;

    /**
     * Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
     * @summary Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
     * @param {number} pk ID of the organization to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    getOrganizationDetails(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationDetail>;

    /**
     * Returns a list of all the organizations associated with the current user
     * @summary Returns a list of all the organizations associated with the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    getOrganizations(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrganizationDetail>>;

    /**
     * Returns a list of all POI belonging to the application associated with the user making the request.
     * @summary Get the Point of Interests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    getPoi(options?: RawAxiosRequestConfig): AxiosPromise<Array<Place>>;

    /**
     * Returns a list of all POS belonging to the application associated with the user making the request.
     * @summary Get the Point of Sale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    getPos(options?: RawAxiosRequestConfig): AxiosPromise<Array<Place>>;

    /**
     * Update the organization identified by the <pk> path parameter
     * @summary Update the organization identified by the <pk> path parameter
     * @param {number} pk ID of the organization to request
     * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    updateOrganization(pk: number, addOrganizationRequest: AddOrganizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationDetail>;

}

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI implements OrganizationsApiInterface {
    /**
     * Save a new organization
     * @summary Save a new organization
     * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public addOrganization(addOrganizationRequest: AddOrganizationRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).addOrganization(addOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the organization identified by the <pk> path parameter
     * @summary Delete the organization identified by the <pk> path parameter
     * @param {number} pk ID of the organization to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteOrganization(pk: number, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteOrganization(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all POI belonging to the application associated with the user making the request.
     * @summary Returns a list of all POI belonging to the application associated with the user making the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getAssociatedPoiList(options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getAssociatedPoiList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
     * @summary Returns data for the organization identified by the <pk> path parameter. The organization must be associated with the current user
     * @param {number} pk ID of the organization to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationDetails(pk: number, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationDetails(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all the organizations associated with the current user
     * @summary Returns a list of all the organizations associated with the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizations(options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all POI belonging to the application associated with the user making the request.
     * @summary Get the Point of Interests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getPoi(options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getPoi(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all POS belonging to the application associated with the user making the request.
     * @summary Get the Point of Sale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getPos(options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getPos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the organization identified by the <pk> path parameter
     * @summary Update the organization identified by the <pk> path parameter
     * @param {number} pk ID of the organization to request
     * @param {AddOrganizationRequest} addOrganizationRequest Save a new organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganization(pk: number, addOrganizationRequest: AddOrganizationRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateOrganization(pk, addOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new product for the application of the user making the request.
         * @summary Create a new product for the application of the user making the request.
         * @param {Product} product 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProduct: async (product: Product, images?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('addProduct', 'product', product)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required

            if (images) {
                images.forEach((element) => {
                    localVarFormParams.append('images', element as any);
                })
            }

    
            if (product !== undefined) { 
                localVarFormParams.append('product', new Blob([JSON.stringify(product)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new product variant associated with the product (identified by path parameter <pk>).
         * @summary Create a new product variant associated with the product (identified by path parameter <pk>).
         * @param {number} productPk Primary key of the product
         * @param {ProductVariant} variant 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVariant: async (productPk: number, variant: ProductVariant, image?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPk' is not null or undefined
            assertParamExists('addVariant', 'productPk', productPk)
            // verify required parameter 'variant' is not null or undefined
            assertParamExists('addVariant', 'variant', variant)
            const localVarPath = `/products/{productPk}/variants/`
                .replace(`{${"productPk"}}`, encodeURIComponent(String(productPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (variant !== undefined) { 
                localVarFormParams.append('variant', new Blob([JSON.stringify(variant)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the order specified by path parameter <pk>
         * @summary Delete the order specified by path parameter <pk>
         * @param {number} pk Primary key of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('deleteOrder', 'pk', pk)
            const localVarPath = `/products/deleteProductOrder/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing product for the application of the user making the request, specified by the path parameter pk.
         * @summary Delete an existing product for the application of the user making the request, specified by the path parameter pk.
         * @param {number} pk Primary key of the batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('deleteProduct', 'pk', pk)
            const localVarPath = `/products/{pk}/`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
         * @summary Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductVariant: async (productPk: number, variantPk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPk' is not null or undefined
            assertParamExists('deleteProductVariant', 'productPk', productPk)
            // verify required parameter 'variantPk' is not null or undefined
            assertParamExists('deleteProductVariant', 'variantPk', variantPk)
            const localVarPath = `/products/{productPk}/variants/{variantPk}`
                .replace(`{${"productPk"}}`, encodeURIComponent(String(productPk)))
                .replace(`{${"variantPk"}}`, encodeURIComponent(String(variantPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
         * @summary Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
         * @param {number} productPk Primary key of the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVariants: async (productPk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPk' is not null or undefined
            assertParamExists('getAllVariants', 'productPk', productPk)
            const localVarPath = `/products/{productPk}/variants/`
                .replace(`{${"productPk"}}`, encodeURIComponent(String(productPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
         * @summary Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
         * @param {GetProductOrdersRequest} getProductOrdersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductOrders: async (getProductOrdersRequest: GetProductOrdersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getProductOrdersRequest' is not null or undefined
            assertParamExists('getProductOrders', 'getProductOrdersRequest', getProductOrdersRequest)
            const localVarPath = `/products/getProductOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getProductOrdersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
         * @summary Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductVariant: async (productPk: number, variantPk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPk' is not null or undefined
            assertParamExists('getProductVariant', 'productPk', productPk)
            // verify required parameter 'variantPk' is not null or undefined
            assertParamExists('getProductVariant', 'variantPk', variantPk)
            const localVarPath = `/products/{productPk}/variants/{variantPk}`
                .replace(`{${"productPk"}}`, encodeURIComponent(String(productPk)))
                .replace(`{${"variantPk"}}`, encodeURIComponent(String(variantPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all products defined for the application of the user making the request
         * @summary Gets all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
         * @summary Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
         * @param {number} pk Primary key of the batch
         * @param {Array<File>} images 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (pk: number, images: Array<File>, product: Product, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('updateProduct', 'pk', pk)
            // verify required parameter 'images' is not null or undefined
            assertParamExists('updateProduct', 'images', images)
            // verify required parameter 'product' is not null or undefined
            assertParamExists('updateProduct', 'product', product)
            const localVarPath = `/products/{pk}/`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required

            if (images) {
                images.forEach((element) => {
                    localVarFormParams.append('images', element as any);
                })
            }

    
            if (product !== undefined) { 
                localVarFormParams.append('product', new Blob([JSON.stringify(product)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
         * @summary Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {ProductVariant} variant 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVariant: async (productPk: number, variantPk: number, variant: ProductVariant, image?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPk' is not null or undefined
            assertParamExists('updateVariant', 'productPk', productPk)
            // verify required parameter 'variantPk' is not null or undefined
            assertParamExists('updateVariant', 'variantPk', variantPk)
            // verify required parameter 'variant' is not null or undefined
            assertParamExists('updateVariant', 'variant', variant)
            const localVarPath = `/products/{productPk}/variants/{variantPk}`
                .replace(`{${"productPk"}}`, encodeURIComponent(String(productPk)))
                .replace(`{${"variantPk"}}`, encodeURIComponent(String(variantPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (variant !== undefined) { 
                localVarFormParams.append('variant', new Blob([JSON.stringify(variant)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new product for the application of the user making the request.
         * @summary Create a new product for the application of the user making the request.
         * @param {Product} product 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProduct(product: Product, images?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProduct(product, images, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.addProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Create a new product variant associated with the product (identified by path parameter <pk>).
         * @summary Create a new product variant associated with the product (identified by path parameter <pk>).
         * @param {number} productPk Primary key of the product
         * @param {ProductVariant} variant 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addVariant(productPk: number, variant: ProductVariant, image?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVariant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addVariant(productPk, variant, image, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.addVariant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Delete the order specified by path parameter <pk>
         * @summary Delete the order specified by path parameter <pk>
         * @param {number} pk Primary key of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrder(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrder(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.deleteOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Delete an existing product for the application of the user making the request, specified by the path parameter pk.
         * @summary Delete an existing product for the application of the user making the request, specified by the path parameter pk.
         * @param {number} pk Primary key of the batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.deleteProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
         * @summary Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductVariant(productPk: number, variantPk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductVariant(productPk, variantPk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.deleteProductVariant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
         * @summary Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
         * @param {number} productPk Primary key of the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVariants(productPk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVariant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVariants(productPk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getAllVariants']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
         * @summary Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
         * @param {GetProductOrdersRequest} getProductOrdersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductOrders(getProductOrdersRequest: GetProductOrdersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductOrders(getProductOrdersRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getProductOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
         * @summary Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductVariant(productPk: number, variantPk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVariant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductVariant(productPk, variantPk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getProductVariant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of all products defined for the application of the user making the request
         * @summary Gets all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
         * @summary Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
         * @param {number} pk Primary key of the batch
         * @param {Array<File>} images 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(pk: number, images: Array<File>, product: Product, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(pk, images, product, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.updateProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
         * @summary Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {ProductVariant} variant 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVariant(productPk: number, variantPk: number, variant: ProductVariant, image?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVariant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVariant(productPk, variantPk, variant, image, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.updateVariant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * Create a new product for the application of the user making the request.
         * @summary Create a new product for the application of the user making the request.
         * @param {Product} product 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProduct(product: Product, images?: Array<File>, options?: any): AxiosPromise<Product> {
            return localVarFp.addProduct(product, images, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new product variant associated with the product (identified by path parameter <pk>).
         * @summary Create a new product variant associated with the product (identified by path parameter <pk>).
         * @param {number} productPk Primary key of the product
         * @param {ProductVariant} variant 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVariant(productPk: number, variant: ProductVariant, image?: File, options?: any): AxiosPromise<ProductVariant> {
            return localVarFp.addVariant(productPk, variant, image, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the order specified by path parameter <pk>
         * @summary Delete the order specified by path parameter <pk>
         * @param {number} pk Primary key of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(pk: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrder(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing product for the application of the user making the request, specified by the path parameter pk.
         * @summary Delete an existing product for the application of the user making the request, specified by the path parameter pk.
         * @param {number} pk Primary key of the batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(pk: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProduct(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
         * @summary Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductVariant(productPk: number, variantPk: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductVariant(productPk, variantPk, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
         * @summary Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
         * @param {number} productPk Primary key of the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVariants(productPk: number, options?: any): AxiosPromise<Array<ProductVariant>> {
            return localVarFp.getAllVariants(productPk, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
         * @summary Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
         * @param {GetProductOrdersRequest} getProductOrdersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductOrders(getProductOrdersRequest: GetProductOrdersRequest, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.getProductOrders(getProductOrdersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
         * @summary Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductVariant(productPk: number, variantPk: number, options?: any): AxiosPromise<ProductVariant> {
            return localVarFp.getProductVariant(productPk, variantPk, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all products defined for the application of the user making the request
         * @summary Gets all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.getProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
         * @summary Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
         * @param {number} pk Primary key of the batch
         * @param {Array<File>} images 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(pk: number, images: Array<File>, product: Product, options?: any): AxiosPromise<Product> {
            return localVarFp.updateProduct(pk, images, product, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
         * @summary Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
         * @param {number} productPk Primary key of the product
         * @param {number} variantPk Primary key of the product variant
         * @param {ProductVariant} variant 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVariant(productPk: number, variantPk: number, variant: ProductVariant, image?: File, options?: any): AxiosPromise<ProductVariant> {
            return localVarFp.updateVariant(productPk, variantPk, variant, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - interface
 * @export
 * @interface ProductsApi
 */
export interface ProductsApiInterface {
    /**
     * Create a new product for the application of the user making the request.
     * @summary Create a new product for the application of the user making the request.
     * @param {Product} product 
     * @param {Array<File>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    addProduct(product: Product, images?: Array<File>, options?: RawAxiosRequestConfig): AxiosPromise<Product>;

    /**
     * Create a new product variant associated with the product (identified by path parameter <pk>).
     * @summary Create a new product variant associated with the product (identified by path parameter <pk>).
     * @param {number} productPk Primary key of the product
     * @param {ProductVariant} variant 
     * @param {File} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    addVariant(productPk: number, variant: ProductVariant, image?: File, options?: RawAxiosRequestConfig): AxiosPromise<ProductVariant>;

    /**
     * Delete the order specified by path parameter <pk>
     * @summary Delete the order specified by path parameter <pk>
     * @param {number} pk Primary key of the order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    deleteOrder(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete an existing product for the application of the user making the request, specified by the path parameter pk.
     * @summary Delete an existing product for the application of the user making the request, specified by the path parameter pk.
     * @param {number} pk Primary key of the batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    deleteProduct(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
     * @summary Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
     * @param {number} productPk Primary key of the product
     * @param {number} variantPk Primary key of the product variant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    deleteProductVariant(productPk: number, variantPk: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
     * @summary Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
     * @param {number} productPk Primary key of the product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    getAllVariants(productPk: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductVariant>>;

    /**
     * Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
     * @summary Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
     * @param {GetProductOrdersRequest} getProductOrdersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    getProductOrders(getProductOrdersRequest: GetProductOrdersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Order>>;

    /**
     * Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
     * @summary Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
     * @param {number} productPk Primary key of the product
     * @param {number} variantPk Primary key of the product variant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    getProductVariant(productPk: number, variantPk: number, options?: RawAxiosRequestConfig): AxiosPromise<ProductVariant>;

    /**
     * Returns a list of all products defined for the application of the user making the request
     * @summary Gets all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    getProducts(options?: RawAxiosRequestConfig): AxiosPromise<Array<Product>>;

    /**
     * Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
     * @summary Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
     * @param {number} pk Primary key of the batch
     * @param {Array<File>} images 
     * @param {Product} product 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    updateProduct(pk: number, images: Array<File>, product: Product, options?: RawAxiosRequestConfig): AxiosPromise<Product>;

    /**
     * Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
     * @summary Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
     * @param {number} productPk Primary key of the product
     * @param {number} variantPk Primary key of the product variant
     * @param {ProductVariant} variant 
     * @param {File} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    updateVariant(productPk: number, variantPk: number, variant: ProductVariant, image?: File, options?: RawAxiosRequestConfig): AxiosPromise<ProductVariant>;

}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI implements ProductsApiInterface {
    /**
     * Create a new product for the application of the user making the request.
     * @summary Create a new product for the application of the user making the request.
     * @param {Product} product 
     * @param {Array<File>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public addProduct(product: Product, images?: Array<File>, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).addProduct(product, images, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new product variant associated with the product (identified by path parameter <pk>).
     * @summary Create a new product variant associated with the product (identified by path parameter <pk>).
     * @param {number} productPk Primary key of the product
     * @param {ProductVariant} variant 
     * @param {File} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public addVariant(productPk: number, variant: ProductVariant, image?: File, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).addVariant(productPk, variant, image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the order specified by path parameter <pk>
     * @summary Delete the order specified by path parameter <pk>
     * @param {number} pk Primary key of the order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteOrder(pk: number, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteOrder(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing product for the application of the user making the request, specified by the path parameter pk.
     * @summary Delete an existing product for the application of the user making the request, specified by the path parameter pk.
     * @param {number} pk Primary key of the batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteProduct(pk: number, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteProduct(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
     * @summary Delete a product variant specified by <variantPk>, associated to the product identified by <productPk>
     * @param {number} productPk Primary key of the product
     * @param {number} variantPk Primary key of the product variant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteProductVariant(productPk: number, variantPk: number, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteProductVariant(productPk, variantPk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
     * @summary Retrieve all the product variants associated to the given product (specified by path parameter <pk>)
     * @param {number} productPk Primary key of the product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAllVariants(productPk: number, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getAllVariants(productPk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
     * @summary Returns a list of all orders for the purchase of products in a given time interval, relating to an optional set of product variants.
     * @param {GetProductOrdersRequest} getProductOrdersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductOrders(getProductOrdersRequest: GetProductOrdersRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductOrders(getProductOrdersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
     * @summary Return a specific product variant associated to the product (path parameter <productPk>) and identified by the given <variantPk>.
     * @param {number} productPk Primary key of the product
     * @param {number} variantPk Primary key of the product variant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductVariant(productPk: number, variantPk: number, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductVariant(productPk, variantPk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all products defined for the application of the user making the request
     * @summary Gets all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProducts(options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
     * @summary Update an existing product(specified by the path parameter <pk>) for the application of the user making the request.
     * @param {number} pk Primary key of the batch
     * @param {Array<File>} images 
     * @param {Product} product 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(pk: number, images: Array<File>, product: Product, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(pk, images, product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
     * @summary Update an existing product variant(identified by <variantPk>) associated with the product (identified by path parameter <productPk>).
     * @param {number} productPk Primary key of the product
     * @param {number} variantPk Primary key of the product variant
     * @param {ProductVariant} variant 
     * @param {File} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateVariant(productPk: number, variantPk: number, variant: ProductVariant, image?: File, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateVariant(productPk, variantPk, variant, image, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TicketsApi - axios parameter creator
 * @export
 */
export const TicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send a ticket cancellation request to the remote ticketing system which includes the replica (equipped with pk property only) and the list of season ticket codes for which you want to cancel the previously issued ticket.
         * @summary Send a ticket cancelling request
         * @param {CancelTicketsRequest} cancelTicketsRequest Cancel one ore more tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTickets: async (cancelTicketsRequest: CancelTicketsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelTicketsRequest' is not null or undefined
            assertParamExists('cancelTickets', 'cancelTicketsRequest', cancelTicketsRequest)
            const localVarPath = `/subscriptions/tickets/canceller`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelTicketsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all type of items that have one or more associated reruns.
         * @summary Returns all type of items that have one or more associated reruns.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShows: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a ticket issuing request to the remote ticketing system which includes the reply (with pk and date properties) and the list of season ticket codes for which you want to issue the ticket.
         * @summary Send a ticket issuing request
         * @param {SubscribeTicketsRequest} subscribeTicketsRequest Emit one ore more tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeTickets: async (subscribeTicketsRequest: SubscribeTicketsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribeTicketsRequest' is not null or undefined
            assertParamExists('subscribeTickets', 'subscribeTicketsRequest', subscribeTicketsRequest)
            const localVarPath = `/subscriptions/tickets/emitter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribeTicketsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsApi - functional programming interface
 * @export
 */
export const TicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * Send a ticket cancellation request to the remote ticketing system which includes the replica (equipped with pk property only) and the list of season ticket codes for which you want to cancel the previously issued ticket.
         * @summary Send a ticket cancelling request
         * @param {CancelTicketsRequest} cancelTicketsRequest Cancel one ore more tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTickets(cancelTicketsRequest: CancelTicketsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTickets(cancelTicketsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.cancelTickets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns all type of items that have one or more associated reruns.
         * @summary Returns all type of items that have one or more associated reruns.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShows(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Show>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShows(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.getShows']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Send a ticket issuing request to the remote ticketing system which includes the reply (with pk and date properties) and the list of season ticket codes for which you want to issue the ticket.
         * @summary Send a ticket issuing request
         * @param {SubscribeTicketsRequest} subscribeTicketsRequest Emit one ore more tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribeTickets(subscribeTicketsRequest: SubscribeTicketsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeTickets(subscribeTicketsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.subscribeTickets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TicketsApi - factory interface
 * @export
 */
export const TicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsApiFp(configuration)
    return {
        /**
         * Send a ticket cancellation request to the remote ticketing system which includes the replica (equipped with pk property only) and the list of season ticket codes for which you want to cancel the previously issued ticket.
         * @summary Send a ticket cancelling request
         * @param {CancelTicketsRequest} cancelTicketsRequest Cancel one ore more tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTickets(cancelTicketsRequest: CancelTicketsRequest, options?: any): AxiosPromise<TicketingResponse> {
            return localVarFp.cancelTickets(cancelTicketsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all type of items that have one or more associated reruns.
         * @summary Returns all type of items that have one or more associated reruns.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShows(options?: any): AxiosPromise<Array<Show>> {
            return localVarFp.getShows(options).then((request) => request(axios, basePath));
        },
        /**
         * Send a ticket issuing request to the remote ticketing system which includes the reply (with pk and date properties) and the list of season ticket codes for which you want to issue the ticket.
         * @summary Send a ticket issuing request
         * @param {SubscribeTicketsRequest} subscribeTicketsRequest Emit one ore more tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeTickets(subscribeTicketsRequest: SubscribeTicketsRequest, options?: any): AxiosPromise<TicketingResponse> {
            return localVarFp.subscribeTickets(subscribeTicketsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketsApi - interface
 * @export
 * @interface TicketsApi
 */
export interface TicketsApiInterface {
    /**
     * Send a ticket cancellation request to the remote ticketing system which includes the replica (equipped with pk property only) and the list of season ticket codes for which you want to cancel the previously issued ticket.
     * @summary Send a ticket cancelling request
     * @param {CancelTicketsRequest} cancelTicketsRequest Cancel one ore more tickets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApiInterface
     */
    cancelTickets(cancelTicketsRequest: CancelTicketsRequest, options?: RawAxiosRequestConfig): AxiosPromise<TicketingResponse>;

    /**
     * Returns all type of items that have one or more associated reruns.
     * @summary Returns all type of items that have one or more associated reruns.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApiInterface
     */
    getShows(options?: RawAxiosRequestConfig): AxiosPromise<Array<Show>>;

    /**
     * Send a ticket issuing request to the remote ticketing system which includes the reply (with pk and date properties) and the list of season ticket codes for which you want to issue the ticket.
     * @summary Send a ticket issuing request
     * @param {SubscribeTicketsRequest} subscribeTicketsRequest Emit one ore more tickets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApiInterface
     */
    subscribeTickets(subscribeTicketsRequest: SubscribeTicketsRequest, options?: RawAxiosRequestConfig): AxiosPromise<TicketingResponse>;

}

/**
 * TicketsApi - object-oriented interface
 * @export
 * @class TicketsApi
 * @extends {BaseAPI}
 */
export class TicketsApi extends BaseAPI implements TicketsApiInterface {
    /**
     * Send a ticket cancellation request to the remote ticketing system which includes the replica (equipped with pk property only) and the list of season ticket codes for which you want to cancel the previously issued ticket.
     * @summary Send a ticket cancelling request
     * @param {CancelTicketsRequest} cancelTicketsRequest Cancel one ore more tickets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public cancelTickets(cancelTicketsRequest: CancelTicketsRequest, options?: RawAxiosRequestConfig) {
        return TicketsApiFp(this.configuration).cancelTickets(cancelTicketsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all type of items that have one or more associated reruns.
     * @summary Returns all type of items that have one or more associated reruns.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getShows(options?: RawAxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getShows(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a ticket issuing request to the remote ticketing system which includes the reply (with pk and date properties) and the list of season ticket codes for which you want to issue the ticket.
     * @summary Send a ticket issuing request
     * @param {SubscribeTicketsRequest} subscribeTicketsRequest Emit one ore more tickets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public subscribeTickets(subscribeTicketsRequest: SubscribeTicketsRequest, options?: RawAxiosRequestConfig) {
        return TicketsApiFp(this.configuration).subscribeTickets(subscribeTicketsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Save a new user
         * @summary Save a new user
         * @param {AddUserRequest} addUserRequest Save a new user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (addUserRequest: AddUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addUserRequest' is not null or undefined
            assertParamExists('addUser', 'addUserRequest', addUserRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the user specified by pk path parameter
         * @summary Delete the selected user
         * @param {number} pk ID of the user to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (pk: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('deleteUser', 'pk', pk)
            const localVarPath = `/users/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all users belonging to the organizations associated with the user making the request.
         * @summary Get all the users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a the user identified by pk path parameter
         * @summary Update a user
         * @param {number} pk ID of the user to update
         * @param {SaveUserRequest} saveUserRequest Update an existing user identified by pk path parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUser: async (pk: number, saveUserRequest: SaveUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('saveUser', 'pk', pk)
            // verify required parameter 'saveUserRequest' is not null or undefined
            assertParamExists('saveUser', 'saveUserRequest', saveUserRequest)
            const localVarPath = `/users/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Save a new user
         * @summary Save a new user
         * @param {AddUserRequest} addUserRequest Save a new user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(addUserRequest: AddUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(addUserRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.addUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Delete the user specified by pk path parameter
         * @summary Delete the selected user
         * @param {number} pk ID of the user to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(pk: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(pk, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.deleteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of all users belonging to the organizations associated with the user making the request.
         * @summary Get all the users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.getUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update a the user identified by pk path parameter
         * @summary Update a user
         * @param {number} pk ID of the user to update
         * @param {SaveUserRequest} saveUserRequest Update an existing user identified by pk path parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUser(pk: number, saveUserRequest: SaveUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveUser(pk, saveUserRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.saveUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Save a new user
         * @summary Save a new user
         * @param {AddUserRequest} addUserRequest Save a new user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(addUserRequest: AddUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.addUser(addUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the user specified by pk path parameter
         * @summary Delete the selected user
         * @param {number} pk ID of the user to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(pk: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all users belonging to the organizations associated with the user making the request.
         * @summary Get all the users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a the user identified by pk path parameter
         * @summary Update a user
         * @param {number} pk ID of the user to update
         * @param {SaveUserRequest} saveUserRequest Update an existing user identified by pk path parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUser(pk: number, saveUserRequest: SaveUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.saveUser(pk, saveUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * Save a new user
     * @summary Save a new user
     * @param {AddUserRequest} addUserRequest Save a new user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    addUser(addUserRequest: AddUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User>;

    /**
     * Delete the user specified by pk path parameter
     * @summary Delete the selected user
     * @param {number} pk ID of the user to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    deleteUser(pk: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns a list of all users belonging to the organizations associated with the user making the request.
     * @summary Get all the users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUsers(options?: RawAxiosRequestConfig): AxiosPromise<Array<User>>;

    /**
     * Update a the user identified by pk path parameter
     * @summary Update a user
     * @param {number} pk ID of the user to update
     * @param {SaveUserRequest} saveUserRequest Update an existing user identified by pk path parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    saveUser(pk: number, saveUserRequest: SaveUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * Save a new user
     * @summary Save a new user
     * @param {AddUserRequest} addUserRequest Save a new user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addUser(addUserRequest: AddUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).addUser(addUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the user specified by pk path parameter
     * @summary Delete the selected user
     * @param {number} pk ID of the user to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(pk: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all users belonging to the organizations associated with the user making the request.
     * @summary Get all the users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a the user identified by pk path parameter
     * @summary Update a user
     * @param {number} pk ID of the user to update
     * @param {SaveUserRequest} saveUserRequest Update an existing user identified by pk path parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public saveUser(pk: number, saveUserRequest: SaveUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).saveUser(pk, saveUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



