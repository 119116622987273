import { Menu, MenuProps, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import audienceIcon from '../../assets/audience.svg'
import dashboardIcon from '../../assets/dashboard.svg'
import eCommerceIcon from '../../assets/eCommerce.svg'
import ticketsIcon from '../../assets/ticketsIcon.svg'
import userIcon from '../../assets/userIcon.svg'
import { Colors } from '../../common/Colors'
import { Setting } from '../../service/model'
import { UserRole } from '../users/AdminUtils'
import { UserSettings, getSettings } from './RoutingUtils'
import styles from './TopicMenu.module.css'
import { ROUTE_CARDS, ROUTE_CONTACTS, ROUTE_DASHBOARD, ROUTE_INVENTORY, ROUTE_ORDERS, ROUTE_ORGANIZATIONS, ROUTE_PRODUCTS, ROUTE_TICKETS_CANCEL, ROUTE_TICKETS_ISSUE, ROUTE_USER } from './routing'
import { ItemType } from 'antd/lib/menu/interface'
type MenuItem = Required<MenuProps>['items'][number]

function createItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem
}

export const TopicMenu = (props: { settings: Setting[], userRole: string, onMenuChange: (path: string) => void }) => {
    const [t] = useTranslation('translations')
    const { pathname } = useLocation()
    const [selectedMenuKey, setSelectedMenuKey] = useState('')

    useEffect(() => {
        const segments = pathname.split('/').filter(segment => segment.length > 0)
        const route = segments[1] ?? ''
        setSelectedMenuKey(route)
    }, [pathname])

    const dashboardMenu = createItem(
        <div className={styles.menuTitle}>
            <Space size={5}>
                <img src={dashboardIcon} className={styles.menuTitleIcon} />
                <span className={styles.menuTitleLabel}>{t('dashboard')}</span>
            </Space>
        </div>,
        t('dashboard').toLowerCase(),
        <img src={dashboardIcon} className={styles.menuTitleIcon} />,
        [
            createItem(<Link to={ROUTE_DASHBOARD}>{t('overview')}</Link>, ROUTE_DASHBOARD)
        ],
        'group'
    )
    const ticketsMenu = createItem(
        <div className={styles.menuTitle}>
            <Space size={5}>
                <img src={ticketsIcon} className={styles.menuTitleIcon} />
                <span className={styles.menuTitleLabel}>{t('tickets')}</span>
            </Space>
        </div>,
        t('tickets').toLowerCase(),
        <img src={ticketsIcon} className={styles.menuTitleIcon} />,
        [
            createItem(<Link to={ROUTE_TICKETS_ISSUE}>{t('issue')}</Link>, ROUTE_TICKETS_ISSUE),
            createItem(<Link to={ROUTE_TICKETS_CANCEL}>{t('cancelling')}</Link>, ROUTE_TICKETS_CANCEL)
        ],
        'group'
    )
    const eCommmerceMenu = createItem(
        <div className={styles.menuTitle}>
            <Space size={5}>
                <img src={eCommerceIcon} className={styles.menuTitleIcon} />
                <span className={styles.menuTitleLabel}>{t('eCommerce')}</span>
            </Space>
        </div>,
        t('eCommerce').toLowerCase(),
        <img src={eCommerceIcon} className={styles.menuTitleIcon} />,
        [
            createItem(<Link to={ROUTE_PRODUCTS}>{t('products')}</Link>, ROUTE_PRODUCTS),
            createItem(<Link to={ROUTE_INVENTORY}>{t('inventory')}</Link>, ROUTE_INVENTORY),
            createItem(<Link to={ROUTE_ORDERS}>{t('orders')}</Link>, ROUTE_ORDERS),
            createItem(<Link to={ROUTE_CARDS}>{t('cards')}</Link>, ROUTE_CARDS)
        ],
        'group'
    )
    const audienceMenu = createItem(
        <div className={styles.menuTitle}>
            <Space size={5}>
                <img src={audienceIcon} className={styles.menuTitleIcon} />
                <span className={styles.menuTitleLabel}>{t('audience')}</span>
            </Space>
        </div>,
        t('audience').toLowerCase(),
        <img src={audienceIcon} className={styles.menuTitleIcon} />,
        [
            createItem(<Link to={ROUTE_CONTACTS}>{t('contacts')}</Link>, ROUTE_CONTACTS)
        ],
        'group'
    )
    const marketingMenu = createItem(
        <div className={styles.menuTitle}>
            <Space size={5}>
                <span className={styles.menuTitleLabel}>{t('marketing')}</span>
            </Space>
        </div>,
        t('marketing').toLowerCase(),
        <img src={dashboardIcon} className={styles.menuTitleIcon} />,
        [
            createItem(<Link to={'NotFound'}>{t('contacts')}</Link>, 'NotFound')
        ],
        'group'
    )
    const adminAministrationMenu = createItem(
        <>
            <p className={styles.section}>{t('consoleAdmin').toUpperCase()}</p>
            <div className={styles.menuTitle}>
                <Space size={5}>
                    <img src={userIcon} className={styles.menuTitleIcon} />
                    <span className={styles.menuTitleLabel}>{t('users')}</span>
                </Space>
            </div>
        </>,
        t('users').toLowerCase(),
        <img src={userIcon} className={styles.menuTitleIcon} />,
        [
            createItem(<Link to={ROUTE_USER}>{t('users')}</Link>, ROUTE_USER),
            createItem(<Link to={ROUTE_ORGANIZATIONS}>{t('organizations')}</Link>, ROUTE_ORGANIZATIONS)
        ],
        'group'
    )

    const tools = createItem(
        <p className={styles.section}>{t('tools').toUpperCase()}</p>,
        t('tools').toLowerCase(),
        null,
        [],
        'group'
    )

    const optionalItems: ItemType[] = []
    const adminSettings = getSettings(props.settings)
    const hideToolsDivider = adminSettings.length <= 1 && adminSettings.includes(UserSettings.adminAdministration)
    if (!hideToolsDivider) {
        optionalItems.push(tools)
    }
    adminSettings.forEach(setting => {
        switch (setting) {
        case UserSettings.adminDashboard:
            optionalItems.push(dashboardMenu)
            break
        case UserSettings.adminEcommerce:
            if (props.userRole === UserRole.ADMIN) {
                optionalItems.push(eCommmerceMenu)
            }
            break
        case UserSettings.adminTicketing:
            optionalItems.push(ticketsMenu)
            break
        /*case UserSettings.adminMarketing:
            optionalItems.push(marketingMenu)
            break*/
        case UserSettings.adminSocial:
            optionalItems.push(audienceMenu)
            break
        case UserSettings.adminAdministration:
            if (props.userRole === UserRole.ADMIN) {
                optionalItems.push({ type: 'divider' })
                optionalItems.push(adminAministrationMenu)
            }
        }
    })

    return (
        <Menu
            className={styles.mainMenu}
            style={{ background: Colors.black, borderInlineEnd: 0 }}
            mode='inline'
            selectedKeys={[selectedMenuKey]}
            onClick={(e) => props.onMenuChange(e.key)}
            items={optionalItems}
        />
    )
}
