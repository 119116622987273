import { Select } from 'antd'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setSelectedItemsIds } from '../../app/Reducers/DashboardSlice'
import '../../common/commonCss/tableView.css'
import CustomSkeleton from '../../common/components/CustomSkeleton'
import { DashboardApiFactory, DashboardItem, DashboardItemGroup } from '../../service/api'
import styles from './ItemSelector.module.css'
import './itemSelector.css'
import { FilterConfig } from './newDashboard/DashboardModel'
const { Option, OptGroup } = Select

interface PropsType {
    loading: boolean
    selectorConfig: FilterConfig
    onLoading: (loading: boolean) => void
}

const NewItemSelector = (props: PropsType) => {
    const api = DashboardApiFactory()
    const [t] = useTranslation('translations')
    const allTitle: string = t(props.selectorConfig.all as unknown as TemplateStringsArray)
    const dispatch = useDispatch()
    const [selectionOpen, setSelectionOpen] = useState<boolean>(false)
    const [selectedItems, setSelectedItems] = useState<string[]>([allTitle])
    const [loaded, setLoaded] = useState<boolean>(false)
    const [showGroups, setShowGroups] = useState<DashboardItemGroup[]>([{ title: allTitle, items: [] }])
    useEffect(() => {
        props.onLoading(true)
        api.getSelectorItems(props.selectorConfig.dataSource).then(response => {
            const dashboardItemGroups = response.data
            const groups = [...showGroups, ...dashboardItemGroups]
            setShowGroups(groups)
            onSelectAllItems(groups)
            setLoaded(true)
            props.onLoading(false)
        })
    }, [])

    const handleDropdownVisibilityChanged = (open: boolean) => {
        setSelectionOpen(open)
    }

    const onSelectAllItems = (groups: DashboardItemGroup[]) => {
        const selectedDashboardItems: DashboardItem[] = _.flatten(
            groups
                .map(group => group.items)
                .filter(items => items.length > 0)
        )
        setSelectedItems([t(props.selectorConfig.all as unknown as TemplateStringsArray)])
        dispatch(setSelectedItemsIds(selectedDashboardItems.map(item => item.pk)))
    }

    const handleSelectionChanged = (values: string[]) => {
        let selectedDashboardItems: DashboardItem[] = []
        const lastSelection = values[values.length - 1]
        if (lastSelection === allTitle) {
            onSelectAllItems(showGroups)
        } else {
            const selectedItems = values.filter(item => item !== allTitle)
            setSelectedItems(selectedItems)
            selectedDashboardItems = _.flatten(
                showGroups
                    .map(group => group.items)
                    .filter(items => items.length > 0)
            ).filter(item => selectedItems.includes(item.name))
            dispatch(setSelectedItemsIds(selectedDashboardItems.map(item => item.pk)))
        }
    }

    return (
        <div className={styles.selectorContainer}>

            {!loaded ?
                <CustomSkeleton height={50} />
                :
                <>
                    <Select
                        className={styles.itemSelector}
                        mode='multiple'
                        value={selectedItems}
                        open={selectionOpen}
                        maxTagCount={'responsive'}
                        allowClear={true}
                        placeholder={t('selectPlaceholder')}
                        onChange={handleSelectionChanged}
                        onDropdownVisibleChange={handleDropdownVisibilityChanged}>

                        {showGroups.map((group: DashboardItemGroup) => (
                            group.items.length > 0 ?
                                <OptGroup
                                    className='itemSelctorOptions'
                                    key={group.title}
                                    label={group.title}>
                                    {group.items.map((item: DashboardItem) => (
                                        <Option key={item.pk} value={item.name}>{item.name}</Option>
                                    ))}
                                </OptGroup>
                                :
                                <Option key={group.title} value={group.title}>{group.title}</Option>
                        ))
                        }
                    </Select>
                </>
            }
        </div>
    )
}

export default NewItemSelector
