import Icon from '@ant-design/icons'
import { Button, Checkbox, Empty, Popover, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import Search from 'antd/lib/input/Search'
import Table, { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import 'dayjs/locale/it'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from '../../app/store'
import rightIcon from '../../assets/arrowRight.svg'
import backIcon from '../../assets/back_active.svg'
import { ReactComponent as NoDataIcon } from '../../assets/calendar-strikethrough.svg'
import dashboardIcon from '../../assets/dashboard.svg'
import filterIcon from '../../assets/filters.svg'
import { Colors } from '../../common/Colors'
import CustomSkeleton from '../../common/components/CustomSkeleton'
import UserAvatar from '../../common/components/UserAvatar'
import TitleRefresh from '../../common/components/commonUI/TitleRefresh'
import { ORDER_DATE_FORMAT } from '../../common/constants'
import { formatDate, getFullName } from '../../common/utils'
import { getOrders } from '../../service/DashboardService'
import { Order } from '../../service/model'
import DateSelector from '../dashboard/dateselector/DateSelector'
import { OrderListItem } from '../dashboard/orders/OrderList'
import FilterPopup from './FilterPopup'
import styles from './Orders.module.css'
import './orders.css'
const { Title } = Typography

export interface OrdersParams {
    selectedItemIdsParam: number[]
}

const NO_SELECTION = ''

const Orders = () => {

    const [t, i18n] = useTranslation('translations')
    const navigate = useNavigate()
    const location = useLocation()
    const dateState = useSelector((state: RootState) => state.dashboardState)

    const selectedItemIds = location.state ? (location.state as OrdersParams).selectedItemIdsParam : []
    const [loaded, setLoaded] = useState<boolean>(false)
    const [data, setData] = useState<OrderListItem[]>([])
    const [filteredData, setFilteredData] = useState<OrderListItem[]>([])
    const [finishedShowsChecked, setFinishedShowsChecked] = useState<boolean>(false)
    const [searchedText, setSearchedText] = useState<string>('')
    const [filterShows, setFilterShows] = useState<Map<string, dayjs.Dayjs[]>>()
    const [filterPopupVisible, setFilterPopupVisible] = useState<boolean>(false)
    const [selectedShowName, setSelectedShowName] = useState<string>(NO_SELECTION)
    const [selectedShowDate, setSelectedShowDate] = useState<string>(NO_SELECTION)
    const refresh = () => {
        if (location.state) {
            if (selectedItemIds && selectedItemIds.length > 0) {
                setLoaded(false)
                getOrders(
                    selectedItemIds,
                    dateState.startDate.clone().hour(0).minute(0).second(0),
                    dateState.endDate.clone().hour(23).minute(59).second(59)
                ).then(orders => {
                    convertOrdersData(orders)
                    setLoaded(true)
                })
            }
        }
    }
    useEffect(refresh, [dateState])

    const updateData = () => {
        const searchedContent = searchedText.toLowerCase()
        let updatedData = searchedText.trim().length > 0 ?
            data.filter(order => order.fullName.toLowerCase().includes(searchedContent)
                || order.show.toLowerCase().includes(searchedContent))
            : data
        updatedData = updatedData.filter(order =>
            (order.show.toLowerCase() === selectedShowName.toLowerCase() || selectedShowName === NO_SELECTION)
            &&
            (formatDate(order.datetime, ORDER_DATE_FORMAT) === selectedShowDate || selectedShowDate === NO_SELECTION))
        if (finishedShowsChecked) {
            updatedData = updatedData.filter(order => isFutureShow(order.datetime))
        }
        setFilteredData(updatedData)
    }

    const updateFiltersShow = () => {
        const filtersShowMap = new Map<string, dayjs.Dayjs[]>()
        const filtersData = !finishedShowsChecked ? data : data.filter(order => isFutureShow(order.datetime))

        for (const order of filtersData) {
            const key = order.show
            const value = order.datetime
            if (filtersShowMap.has(key)) {
                const values: dayjs.Dayjs[] = filtersShowMap.get(key)!
                if (!containsDate(value, values)) {
                    values.push(value)
                }
            } else {
                filtersShowMap.set(key, [value])
            }
        }
        setFilterShows(filtersShowMap)
    }

    useEffect(updateData, [data, finishedShowsChecked, searchedText, selectedShowName, selectedShowDate])

    useEffect(updateFiltersShow, [filteredData])

    const convertOrdersData = (data: Order[]) => {
        const ordersData = data.map(order => ({
            fullName: getFullName(order.customerName),
            ...(order.userUrl && { avatarUrl: new URL('http://' + order.userUrl) }),
            show: order.showName,
            datetime: dayjs(order.showDate),
            quantity: order.ticketsCount,
            price: order.totalPrice,
            purchaseDatetime: dayjs(order.purchaseDate)
        }))
        setData(ordersData)
        const filteredData = !finishedShowsChecked ? ordersData : ordersData.filter(order => isFutureShow(order.datetime))
        setFilteredData(filteredData)
    }

    const containsDate = (date: dayjs.Dayjs, dates: dayjs.Dayjs[]): boolean => {
        const time = date.millisecond()
        return dates.find(d => d.millisecond() === time) !== undefined
    }

    const handleIntervalChanged = () => {
        resetPopupFilters()
    }

    const handleShowFinishedChanged = (e: CheckboxChangeEvent) => {
        const checked = e.target.checked
        setFinishedShowsChecked(checked)
    }

    const handleSearchChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchedText(event.target.value)
    }

    const isFutureShow = (date: dayjs.Dayjs) => {
        return date.millisecond() - dayjs().millisecond() > 0
    }

    const handleFilterCancelled = () => {
        setFilterPopupVisible(false)
    }

    const handleFilterConfirmed = (showName: string, showDate: string) => {
        setFilterPopupVisible(false)
        setSelectedShowName(showName)
        setSelectedShowDate(showDate)
    }

    const handleFilterPopupVisibility = (visible: boolean) => {
        setFilterPopupVisible(visible)
    }

    const handleFilterClicked = () => {
        setFilterPopupVisible(!filterPopupVisible)
    }

    const resetPopupFilters = () => {
        setSelectedShowName(NO_SELECTION)
        setSelectedShowDate(NO_SELECTION)
    }

    const resetPopupDateFilter = () => {
        setSelectedShowDate(NO_SELECTION)
    }

    const columns: ColumnsType<OrderListItem> = [
        {
            title: t('contact'),
            dataIndex: 'fullName',
            sorter: {
                compare: (a, b) => a.fullName.localeCompare(b.fullName)
            },
            render: (fullName: string, record: OrderListItem) => (
                <UserAvatar fullName={fullName} avatarUrl={record.avatarUrl}></UserAvatar>
            )
        },
        {
            title: t('show'),
            dataIndex: 'show',
            sorter: {
                compare: (a, b) => a.show.localeCompare(b.show)
            }
        },
        {
            title: t('dateTime'),
            dataIndex: 'datetime',
            align: 'center',
            sorter: {
                compare: (a, b) => a.datetime.millisecond() - b.datetime.millisecond()
            },
            render: (datetime: dayjs.Dayjs) => (
                <>{formatDate(datetime, ORDER_DATE_FORMAT)}</>
            )
        },
        {
            title: t('purchases'),
            dataIndex: 'quantity',
            align: 'center',
            sorter: {
                compare: (a, b) => a.quantity - b.quantity
            }
        },
        {
            title: t('price'),
            dataIndex: 'price',
            align: 'center',
            sorter: {
                compare: (a, b) => a.price - b.price
            },
            render: (price: number) => (
                <span className={styles.grayText}>
                    {new Intl.NumberFormat(i18n.language, { style: 'currency', currency: 'EUR' }).format(price)}
                </span>
            )
        },
        {
            title: t('purchaseDateTime'),
            dataIndex: 'purchaseDatetime',
            align: 'center',
            sorter: {
                compare: (a, b) => a.purchaseDatetime.unix() - b.purchaseDatetime.unix()
            },
            render: (datetime: dayjs.Dayjs) => (
                <span className={styles.grayText}>{formatDate(datetime, ORDER_DATE_FORMAT)}</span>
            )
        }
    ]


    return (
        <>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <TitleRefresh
                        title={t('orders')}
                        onClick={refresh}
                        loaded={loaded} />
                    {!selectedShowName && !selectedShowDate
                        ?
                        <div className={styles.backButtonContainer} onClick={() => navigate('..', { relative: 'path' })}>
                            <img src={backIcon} className={styles.backIcon} />
                            <span className={styles.backLabel}>{t('back')}</span>
                        </div>
                        :
                        <div className={styles.breadcrumbsContainer}>
                            <img src={dashboardIcon} onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }} />
                            <img src={rightIcon} className={styles.rightIcon} />
                            <a className={styles.breadcrumbsLabel} onClick={resetPopupFilters}>{t('orders')}</a>
                            <img src={rightIcon} className={styles.rightIcon} />
                            <a className={styles.breadcrumbsLabel} onClick={resetPopupDateFilter}>{selectedShowName}</a>
                            {selectedShowDate !== NO_SELECTION && (
                                <>
                                    <img src={rightIcon} className={styles.rightIcon} />
                                    <label className={styles.grayBreadcrumbsLabel}>{selectedShowDate}</label>
                                </>
                            )}
                        </div>
                    }
                </div>
                <div className={'flexContainer ' + styles.flexContainer} >
                    <DateSelector
                        onIntervalChanged={handleIntervalChanged}
                        disabled={!loaded} />
                    <div className={styles.searchContainer + ' searchContainer'}>
                        <Search
                            className={'search ' + styles.search}
                            allowClear
                            onChange={handleSearchChanged}
                            disabled={!loaded || !(filterShows !== undefined && filterShows?.size > 0)}
                        />
                        <Popover
                            content={filterShows ?
                                <FilterPopup
                                    filterVisible={filterPopupVisible}
                                    shows={filterShows}
                                    selectedShowName={selectedShowName}
                                    selectedShowDate={selectedShowDate}
                                    onCancel={handleFilterCancelled}
                                    onConfirm={handleFilterConfirmed} />
                                :
                                <></>}
                            trigger="click"
                            open={filterPopupVisible}
                            onOpenChange={handleFilterPopupVisibility}
                            placement='bottomLeft'
                            className={styles.filter}>
                            <Button type='text' style={{ display: 'flex', alignSelf: 'center', marginLeft: '10px' }}>
                                <img style={{ marginBottom: '3px' }} src={filterIcon} onClick={handleFilterClicked} />
                            </Button>
                        </Popover>
                    </div>
                </div>

                <div style={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: '30px' }}>
                    <Checkbox
                        className={'ordersCheckbox ' + styles.finishedShows}
                        disabled={!loaded}
                        onChange={handleShowFinishedChanged}>
                        {t('hideFinishedShows')}
                    </Checkbox>
                </div>

                <div className={styles.tableContainer}>
                    {!loaded ?
                        <div style={{ paddingBottom: '160px' }}>
                            <CustomSkeleton height={300} />
                        </div>
                        :
                        <>
                            <Table
                                dataSource={filteredData}
                                columns={columns}
                                className={'tableView ordersTable'}
                                showSorterTooltip={false}
                                pagination={{
                                    position: ['bottomCenter'],
                                    hideOnSinglePage: filteredData.length <= 10,
                                    showSizeChanger: true,
                                    style: {
                                        marginTop: '45px'
                                    },
                                    responsive: true,
                                    showTitle: false
                                }}
                            />
                            {filteredData.length === 0 && (
                                <Empty
                                    image={<Icon component={NoDataIcon} />}
                                    imageStyle={{ fontSize: '40px', marginTop: '90px', marginBottom: '-8px' }}
                                    style={{ marginTop: '100px', paddingBottom: '180px' }}
                                    description={(
                                        <span style={{ color: Colors.black }}>{t('noData')}</span>
                                    )} />
                            )
                            }
                        </>
                    }
                </div>
            </div >

        </>
    )
}

export default Orders