import axios, { AxiosError, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { API_URL, ChartItem, DashboardItemGroup, Order, SoldQuantity, SoldQuantityItem } from './model'
const DASHBOARD_ADDRESS = '/dashboard'
const DASHBOARD_SOLD_AMOUNT_PER_DAY_ADDRESS = '/dashboard/soldAmountPerDay'
const DASHBOARD_SOLD_QUANTITY_PER_ITEM_ADDRESS = '/dashboard/soldQuantityPerItem'
const DASHBOARD_ORDERS_ADDRESS = '/dashboard/orders'
const DASHBOARD_SOLD_AMOUNT_PER_RERUN_ADDRESS = '/dashboard/soldAmountPerRerun'

export function getShows(callback: (dashboardItemGroups: DashboardItemGroup[]) => void): void {
    axios({
        method: 'GET',
        url: API_URL + DASHBOARD_ADDRESS,
    }).then((response: AxiosResponse) => {
        callback(response.data)
    }).catch((error: AxiosError) => {
        console.log(error)
    })
}

export function getSoldAmountPerDay(
    itemPks: number[],
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    callback: (soldAmountItems: SoldQuantity[]) => void) {

    axios({
        method: 'POST',
        url: API_URL + DASHBOARD_SOLD_AMOUNT_PER_DAY_ADDRESS,
        data: {
            itemPks: itemPks,
            startDate: startDate,
            endDate: endDate
        }
    }).then((response: AxiosResponse) => {
        callback(response.data)
    }).catch((error: AxiosError) => {
        console.log(error)
    })
}

export async function getOrders(
    itemIds: number[],
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    max?: number): Promise<Order[]> {
    try {
        const response = await axios.post(API_URL + DASHBOARD_ORDERS_ADDRESS, {
            max: max,
            interval: {
                itemPks: itemIds,
                startDate: startDate,
                endDate: endDate
            }
        })
        const orders = await response.data as Order[]
        return orders
    } catch (exception) {
        if (axios.isAxiosError(exception)) {
            const error = exception as AxiosError
            if (error.response) {
                throw new Error(`HTTP error: ${error.response.status}`)
            } else {
                throw new Error(`Failed to parse data: ${error.message}`)
            }
        }
        throw new Error(`Unknown exception: ${exception}`)
    }

}

export async function getSoldAmountPerRerun(itemIds: number[], startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): Promise<SoldQuantity[]> {
    try {
        const response = await axios.post(API_URL + DASHBOARD_SOLD_AMOUNT_PER_RERUN_ADDRESS, {
            itemPks: itemIds,
            startDate: startDate,
            endDate: endDate
        })
        const data = await response.data as SoldQuantity[]
        return data
    } catch (exception) {
        if (axios.isAxiosError(exception)) {
            const error = exception as AxiosError
            if (error.response) {
                throw new Error(`HTTP error: ${error.response.status}`)
            } else {
                throw new Error(`Failed to parse data: ${error.message}`)
            }
        }
        throw new Error(`Unknown exception: ${exception}`)
    }
}

export function getSoldQuantityPerItem(
    itemPks: number[],
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    callback: (soldQuantityItems: SoldQuantityItem[]) => void) {

    axios({
        method: 'POST',
        url: API_URL + DASHBOARD_SOLD_QUANTITY_PER_ITEM_ADDRESS,
        data: {
            itemPks: itemPks,
            startDate: startDate,
            endDate: endDate
        }
    }).then((response: AxiosResponse) => {
        callback(response.data)
    }).catch((error: AxiosError) => {
        console.log(error)
    })
}

