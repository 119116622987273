import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Modal, Spin, notification } from 'antd'
import { AxiosError } from 'axios'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { ReactComponent as LocationIcon } from '../../../../../assets/locationIcon.svg'
import { Colors } from '../../../../../common/Colors'
import { InventoryApiFactory, InventoryVariant } from '../../../../../service/api'
import styles from './StockOutModal.module.css'
import { StockOutFormValues, StockOutModalForm } from './StockOutModalForm'

interface StockOutModalProps {
    open: boolean
    productTitle: string
    variant: InventoryVariant
    onCancel: () => void
    onComplete: () => void
}

export const StockOutModal = ({ open, variant, productTitle, onCancel, onComplete }: StockOutModalProps): ReactElement => {
    const api = InventoryApiFactory()
    const [t] = useTranslation('translations')
    const [loading, setLoading] = useState<boolean>(false)
    const [form] = Form.useForm()
    const inventoryState = useSelector((state: RootState) => state.inventoryState)

    const handleSubmit = (values: StockOutFormValues) => {
        setLoading(true)
        api.deleteBatch(inventoryState.batch!.pk).then(res => {
            onComplete()
            onCancel()
        }).catch((error: AxiosError) => {
            notification.error({
                message: error.response?.status.toString(),
                description: error.message
            })
        }).finally(() => setLoading(false))
    }
    return (
        <>
            <Modal
                closable={false}
                className={`modal ${styles.customModal}`}
                maskClosable={false}
                open={open}
                footer={
                    !loading &&
                    <>
                        <Button
                            onClick={() => onCancel()}
                            className={'yellowOutlinedCancelButton'}>
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={() => form.submit()}
                            className={'blackFillPositiveButton'}>
                            {t('removeStock')}
                        </Button>
                    </>
                }>
                {!loading ?
                    <>
                        <div className={styles.modalTitle}>
                            {productTitle}
                        </div>
                        <div className={styles.modalSubtitle}>
                            {inventoryState.warehouse?.name}
                            <div className={styles.yellowDot}>{<>&#8226;</>}</div>
                            {variant.sku}
                        </div>
                        <StockOutModalForm form={form} onFinish={handleSubmit} />
                    </>
                    :
                    <div className={styles.spinContainer}>
                        <div className={styles.loadingAction}>{t('stockingOut')}</div>
                        <Spin className={styles.spin} indicator={<LoadingOutlined style={{ fontSize: 55, color: Colors.yellow }} spin />} />
                    </div>
                }
                <div className={styles.modalAdditionalInfo}>
                    {<LocationIcon className={styles.locationIcon} />}
                    <div className={styles.warehouseName}>
                        {inventoryState.warehouse?.name}
                    </div>
                </div>
            </Modal>
        </>
    )
}