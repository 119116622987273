import dayjs from 'dayjs'
import { CustomerType } from '../pages/crm/contacts/Contacts/Contacts'


export interface PersonName {
    firstName?: string
    lastName?: string
}

export enum CustomerItemType {
    App = 'APP',
    Other = 'OTHER'
}

export function formatDate(date: dayjs.Dayjs, format: string): string {
    return date.format(format)
}

export function getFullName(person: PersonName): string {
    const firstName = person.firstName ?? ''
    const lastName = person.lastName ?? ''
    return `${firstName} ${lastName}`.trim()
}

export const isAppCustomer = (type: number): boolean => {
    const appCustomerTypes = [
        CustomerType.Server,
        CustomerType.Facebook,
        CustomerType.Twitter,
        CustomerType.Apple
    ]
    return appCustomerTypes.some(appType => type === appType)
}
