import Icon from '@ant-design/icons'
import { Empty, Table } from 'antd'
import { FilterValue, SorterResult, TableRowSelection } from 'antd/es/table/interface'
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { Key, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NoDataIcon } from '../../../assets/cloud-cross.svg'
import { Colors } from '../../../common/Colors'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import { Product } from '../../../service/api'
import './../../../common/commonCss/tableView.css'


interface PropsType {
    loaded: boolean
    data: Product[]
    selectedKeys: React.Key[]
    onSortChange: (sortedItems: Product[]) => void
    onRowClick: (selectedItem: Product) => void
    onRowSelect: (selectedItems: Key[]) => void
}

export default function TableView(props: PropsType) {

    const [t] = useTranslation('translations')
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        props.onRowSelect(newSelectedRowKeys)
    }

    const rowSelection: TableRowSelection<Product> = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    useEffect(() => {
        setSelectedRowKeys(props.selectedKeys)
    }, [props.selectedKeys])

    const columns: ColumnsType<Product> = [
        {
            title: t('product'),
            dataIndex: 'title',
            sorter: {
                compare: (a, b) => a.title.localeCompare(b.title)
            },
            render: (productName: string, record: Product) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={record.coverImage.imageUrl!}
                        style={{ maxWidth: '80px', maxHeight: '80px', marginRight: 14 }} />
                    {productName}
                </span>
            )
        },
        {
            title: t('description'),
            dataIndex: 'shortDescription',
            sorter: {
                compare: (a, b) => a.shortDescription.localeCompare(b.shortDescription)
            },
            render: (description: string, record: Product) => (
                <span>{description}</span>
            )
        }
    ]
    const handleSortChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<Product>[] | SorterResult<Product>, extra: { currentDataSource: Product[] }) => {
        props.onSortChange(extra.currentDataSource)
    }
    return (
        <div >
            {!props.loaded ?
                <div style={{ paddingBottom: '160px' }}>
                    <CustomSkeleton height={300} />
                </div>
                :
                <>
                    <Table
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    props.onRowClick(record)
                                }
                            }
                        }}
                        rowKey={(record) => record.pk!}
                        rowSelection={rowSelection}
                        onChange={handleSortChange}
                        dataSource={props.data}
                        columns={columns}
                        className={'tableView'}
                        showSorterTooltip={false}
                        pagination={{
                            position: ['bottomCenter'],
                            hideOnSinglePage: props.data.length <= 10,
                            showSizeChanger: true,
                            style: {
                                marginTop: '45px'
                            },
                            responsive: true,
                            showTitle: false
                        }}
                    />
                    {props.data.length === 0 &&
                        <Empty
                            image={<Icon component={NoDataIcon} />}
                            imageStyle={{ fontSize: '60px', marginTop: '50px', marginBottom: '-8px' }}
                            style={{ marginTop: '50px', paddingBottom: '180px' }}
                            description={(
                                <span style={{ color: Colors.black }}>{t('noData')}</span>
                            )} />
                    }
                </>
            }
        </div>
    )
}
