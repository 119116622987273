import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../app/store'
import { ChartData } from '../../common/components/charts/ChartData'
import TitleRefresh from '../../common/components/commonUI/TitleRefresh'
import { getOrders, getSoldAmountPerDay, getSoldAmountPerRerun, getSoldQuantityPerItem } from '../../service/DashboardService'
import { Order, ShowStatus, SoldQuantity, SoldQuantityItem } from '../../service/model'
import { default as classStyles, default as styles } from './Dashboard.module.css'
import ItemSelector from './ItemSelector'
import DateSelector from './dateselector/DateSelector'
import ExportPopup from './exportpopup/ExportPopup'
import OrderList, { OrderListItem } from './orders/OrderList'
import Histogram from './saleschart/Histrogram'
import SalesChartDetail from './saleschart/SalesChartDetail'
import LineChartGraph from './soldamountperday/SoldAmountPerDayChart'
import SoldAmountPerDayChartDetail from './soldamountperday/SoldAmountPerDayChartDetail'
import ListChart, { SoldQuantityShowItem } from './soldquantityperitem/SoldQuantityPerItemList'
import SoldQuantityPerItemTable from './soldquantityperitem/SoldQuantityPerItemTable'
import { getFullName } from '../../common/utils'

interface LoadingData {
    soldAmountPerDayData: boolean
    soldQuantityPerItemData: boolean
    soldAmountPerRerunData: boolean
    latestOrdersData: boolean
}

const ORDERS_MAX = 10

const Dashboard = () => {
    const [t] = useTranslation('translations')
    const dashboardRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const dateState = useSelector((state: RootState) => state.dashboardState)

    const startingLodingData: LoadingData = ({
        soldAmountPerDayData: false,
        soldQuantityPerItemData: false,
        soldAmountPerRerunData: false,
        latestOrdersData: false
    })
    const [loadingData, setLoadingData] = useState<LoadingData>(startingLodingData)
    const [soldAmountPerDayData, setSoldAmountPerDayData] = useState<ChartData[]>([])
    const [soldAmountPerRerunData, setSoldAmountPerRerunData] = useState<ChartData[]>([])
    const [soldQuantityPerItemData, setSoldQuantityPerItemData] = useState<SoldQuantityShowItem[]>([])
    const [latestOrdersData, setLatestOrdersData] = useState<OrderListItem[]>([])

    const [soldAmountExpand, setSoldAmountExpand] = useState<boolean>(false)
    const [salesChartExpand, setSalesChartExpand] = useState<boolean>(false)
    const [soldQuantityPerItemExpand, setSoldQuantityPerItemExpand] = useState<boolean>(false)
    const [showExportPopup, setShowExportPopup] = useState<boolean>(false)

    const error = (dateState.selectedItemsIds?.length ?? -1) === 0
    const loaded = Object.values(loadingData).every(v => v) || error
    const [exportFilename, setExportFilename] = useState<string>('')
    const [exportData, setExportData] = useState<object[]>([{}])
    const [totalAmount, setTotalAmount] = useState<number>(0)
    const resetFlags = () => {
        setLoadingData(startingLodingData)
    }

    const resetData = () => {
        setSoldAmountPerDayData([])
        setSoldQuantityPerItemData([])
        setLatestOrdersData([])
        setSoldAmountPerRerunData([])
    }

    const refresh = () => {
        const selectedItemIds = dateState.selectedItemsIds
        if (selectedItemIds && selectedItemIds.length > 0) {
            resetFlags()

            const start = dateState.startDate.clone().hour(0).minute(0).second(0)
            const end = dateState.endDate.clone().hour(23).minute(59).second(59)
            getSoldAmountPerDay(selectedItemIds, start, end, (soldAmountItems: SoldQuantity[]) => {
                convertSoldAmountPerDayData(soldAmountItems)
                setLoadingData((loadingData) => { return { ...loadingData, soldAmountPerDayData: true } })
            })
            getSoldQuantityPerItem(selectedItemIds, start, end, (soldQuantityItems: SoldQuantityItem[]) => {
                convertSoldQuantityPerItemData(soldQuantityItems)
                setLoadingData((loadingData) => { return { ...loadingData, soldQuantityPerItemData: true } })
            })
            getSoldAmountPerRerun(selectedItemIds, start, end)
                .then(data => {
                    convertSoldQuantityPerRerunData(data)
                    setLoadingData((loadingData) => { return { ...loadingData, soldAmountPerRerunData: true } })
                })
            getOrders(selectedItemIds, start, end, ORDERS_MAX)
                .then(orders => {
                    convertLatestOrdersData(orders)
                    setLoadingData((loadingData) => { return { ...loadingData, latestOrdersData: true } })
                })
        }
    }

    useEffect(refresh, [dateState.selectedItemsIds, dateState.startDate, dateState.endDate])

    const convertSoldAmountPerDayData = (data: SoldQuantity[]) => {
        setTotalAmount(_.sumBy(data, datum => datum.amount))
        setSoldAmountPerDayData(data.map(item => ({
            date: dayjs(item.date),
            sales: item.amount
        }))
        )
    }

    const statusLabelMap: { [K in ShowStatus]: string } = {
        [ShowStatus.Finished]: t('finished'),
        [ShowStatus.Ongoing]: t('ongoing'),
        [ShowStatus.Future]: t('arriving')
    }

    const convertSoldQuantityPerItemData = (data: SoldQuantityItem[]) => {
        const soldQuantityPerItemData = data.map(item => ({
            count: item.count,
            showName: item.showName,
            status: statusLabelMap[item.status]
        })) as SoldQuantityShowItem[]
        setSoldQuantityPerItemData(soldQuantityPerItemData)
    }

    const convertSoldQuantityPerRerunData = (data: SoldQuantity[]) => {
        const chartData = data.map(item => ({ date: dayjs(item.date), sales: item.amount }))
        setSoldAmountPerRerunData(chartData)
    }

    const convertLatestOrdersData = (data: Order[]) => {
        const latestOrdersData = data.map(order => ({
            fullName: getFullName(order.customerName),
            ...(order.userUrl && { avatarUrl: new URL('http://' + order.userUrl) }),
            show: order.showName,
            datetime: dayjs(order.showDate),
            quantity: order.ticketsCount,
            price: order.totalPrice,
            purchaseDatetime: dayjs(order.purchaseDate)
        }))
        setLatestOrdersData(latestOrdersData)
    }

    useEffect(() => {
        if (dateState.selectedItemsIds.length === 0) {
            resetData()
        }
        resetFlags()
    }, [dateState.selectedItemsIds])

    const handleLineChartExpandClicked = () => {
        setSoldAmountExpand(!soldAmountExpand)
    }

    const handleSalesChartExpandClicked = () => {
        setSalesChartExpand(!salesChartExpand)
    }
    const handleSoldQuantityExpandClicked = () => {
        setSoldQuantityPerItemExpand(!soldQuantityPerItemExpand)
    }

    const handleExportClicked = (filename: string, data: object[]) => {
        setExportFilename(filename)
        setExportData(data)
        setShowExportPopup(true)
    }

    const handleExportPopupCloseClicked = () => {
        setShowExportPopup(false)
    }

    const handleOnViewAllOrders = () => {
        const params = {
            selectedItemIdsParam: dateState.selectedItemsIds
        }
        navigate('orders', { state: params })
    }
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    useEffect(() => {
        scrollToTop()
    }, [salesChartExpand, soldAmountExpand, soldQuantityPerItemExpand])

    return (
        <div className={classStyles.container} ref={dashboardRef}>
            <div className={styles.titleContainer}>
                <TitleRefresh
                    title={t('dashboard')}
                    onClick={refresh}
                    loaded={loaded} />
            </div>
            <div className={classStyles.itemSelectorContainer}>
                <DateSelector
                    disabled={!loaded} />
                <ItemSelector
                    loading={loaded} />
            </div>

            <div className={classStyles.widgetsContainer}>
                <Row className={classStyles.widgetContainers} gutter={[29, 30]}>
                    <Col span={8} className={classStyles.widget}>
                        <Histogram
                            data={soldAmountPerRerunData}
                            onExportClick={handleExportClicked}
                            onExpandClick={handleSalesChartExpandClicked}
                            exportFilename={'SoldAmountPerRerun'}
                            modalContainer={dashboardRef}
                            error={error}
                            loading={!loaded} />
                    </Col>

                    <Col span={16}>
                        <LineChartGraph
                            height={170}
                            title={t('totalSold')}
                            valueLabel={t('totalSoldValue', { value: totalAmount.toString() })}
                            items={soldAmountPerDayData}
                            tooltipText={t('totalSoldTooltip')}
                            expandable={false}
                            onExpandClick={handleLineChartExpandClicked}
                            onExportClick={handleExportClicked}
                            exportFilename={'SoldAmountPerDay'}
                            error={error}
                            loading={!loaded} />
                    </Col>

                    <Col span={8} className={classStyles.widget}>
                        <ListChart
                            title={t('ticketsPerShow')}
                            tooltipText={t('ticketsPerShowTooltip')}
                            items={soldQuantityPerItemData}
                            onExpandClick={handleSoldQuantityExpandClicked}
                            exportFilename={'SoldQuantityPerItem'}
                            onExportClick={handleExportClicked}
                            error={error}
                            loading={!loaded} />
                    </Col>
                </Row>

                <Row className={styles.recentOrdersContainer}>
                    <Col style={{ textAlign: 'center' }} span={24}>
                        <OrderList
                            data={latestOrdersData}
                            error={error}
                            loading={!loaded}
                            onViewAll={handleOnViewAllOrders} />
                    </Col>
                </Row>

            </div>
            {(soldAmountExpand || salesChartExpand || soldQuantityPerItemExpand) &&
                <div className={styles.detailContainer}>
                    {soldAmountExpand &&
                        <SoldAmountPerDayChartDetail
                            valueLabel={t('totalSoldValue', { value: totalAmount.toString() })}
                            chartData={soldAmountPerDayData}
                            onCloseClick={handleLineChartExpandClicked} />}
                    {salesChartExpand &&
                        <SalesChartDetail
                            data={soldAmountPerRerunData}
                            totalAmount={_.sumBy(soldAmountPerRerunData, item => item.sales)}
                            exportFilename={'SoldAmountPerRerun'}
                            onClose={handleSalesChartExpandClicked} />}
                    {soldQuantityPerItemExpand &&
                        <SoldQuantityPerItemTable
                            title={t('ticketsPerShow')}
                            tooltipText={t('ticketsPerShowTooltip')}
                            items={soldQuantityPerItemData}
                            exportFilename={'SoldQuantityPerItem'}
                            onClose={handleSoldQuantityExpandClicked} />}
                </div>
            }
            {showExportPopup && <ExportPopup filename={exportFilename} data={exportData} onCloseClick={handleExportPopupCloseClicked} />}
        </div>
    )
}

export default Dashboard