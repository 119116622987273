import { Avatar, Checkbox, Form, FormInstance, Input, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconPlaceHolder } from '../../../../assets/userPlaceHolder.svg'
import i18n from '../../../../i18n'
import { Organization, User } from '../../../../service/api/api'
import { UserRole } from '../../AdminUtils'
import styles from './UserDetailForm.module.css'

const { Option } = Select

export const PASSWORD_PLACEHOLDER = '00000000'
const USERNAME_SUFFIX = '.firenzecard'

export interface UserFormValue {
    username: string,
    email: string,
    firstName: string,
    lastName: string,
    role: string,
    notification: boolean,
    organizations: string[],
    company: string | null,
    password: string
}
interface UserDetailFormProps {
    selectedUser?: User
    disabled?: boolean
    form: FormInstance<any>
    organizations?: Organization[]
    onFinish: (values: UserFormValue) => void
}

export const getRoleTranslation = (role: string): string => {
    switch (role) {
    case UserRole.ADMIN: return i18n.t('admin')
    case UserRole.FINANCIAL: return i18n.t('financial')
    case UserRole.OPERATOR: return i18n.t('operator')
    default: return i18n.t('admin')
    }
}
const UserDetailForm = (props: UserDetailFormProps) => {
    const [t] = useTranslation('translations')
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [useEmailAsUsername, setUseEmailAsUsername] = useState<boolean>(false)
    const [notificationChecked, setNotificationChecked] = useState<boolean>()
    const [notificationEnabled, setNotificationEnabled] = useState<boolean>(false)
    useEffect(() => {
        if (isDisabled) {
            props.form.setFieldsValue({
                username: props.selectedUser?.username
            })
        } else if (!isDisabled && props.selectedUser?.username !== props.selectedUser?.email) {
            props.form.setFieldsValue({
                username: props.selectedUser?.username.split(USERNAME_SUFFIX)[0]
            })
        }
    }, [isDisabled])

    const initialValues: UserFormValue | undefined = props.selectedUser ?
        {
            username: props.selectedUser.username,
            email: props.selectedUser.email.trimEnd(),
            firstName: props.selectedUser.firstName,
            lastName: props.selectedUser.lastName,
            role: props.selectedUser.role,
            organizations: props.selectedUser.organizations!.map(organization => {
                return organization.pk.toString()
            }),
            notification: props.selectedUser.notification,
            company: props.selectedUser.company,
            password: PASSWORD_PLACEHOLDER
        } : undefined

    useEffect(() => {
        setIsDisabled(props.disabled!)
    }, [props.disabled])

    useEffect(() => {
        if (props.selectedUser) {
            if (props.selectedUser.username === props.selectedUser.email) {
                setUseEmailAsUsername(true)
            }
            setNotificationEnabled(props.selectedUser.role === UserRole.ADMIN)
            setNotificationChecked(props.selectedUser.notification)
        }
    }, [])
    useEffect(() => {
        if (useEmailAsUsername) {
            props.form.setFieldsValue({
                username: props.form.getFieldValue('email')
            })
        }
    }, [useEmailAsUsername])
    const handleRoleChange = (value: string) => {
        if (value === UserRole.ADMIN) {
            setNotificationEnabled(true)
        } else {
            setNotificationChecked(false)
            setNotificationEnabled(false)
        }
    }
    return (
        <>
            <div className={styles.accountImage}>
                <Avatar
                    icon={<IconPlaceHolder className={styles.userImage}></IconPlaceHolder>}
                    shape='circle'
                    size={140} />
            </div>
            <div className={styles.userInfo}>
                <Form
                    initialValues={initialValues}
                    requiredMark={false}
                    form={props.form}
                    onFinish={(values: UserFormValue) => {
                        values.notification = notificationChecked ?? false
                        values.username = useEmailAsUsername ? values.email : (values.username + USERNAME_SUFFIX)
                        props.onFinish(values)
                    }}
                    className={`${isDisabled ? styles.formDisabled : styles.formEnabled} ${styles.form} ${useEmailAsUsername && styles.formDisabledWithCheckbox}`}
                    layout='vertical' >
                    <Form.Item
                        className={`${useEmailAsUsername && styles.disabledInput}`}
                        name={'username'}
                        label={t('username')}
                        rules={[
                            {
                                required: useEmailAsUsername ? false : true,
                                message: ''
                            }
                        ]}
                    >
                        <Input
                            className={`${styles.addonInput} ${useEmailAsUsername && styles.addonDisabled}`}
                            addonAfter={(!isDisabled && !useEmailAsUsername) && '.firenzecard'}
                            disabled={isDisabled || useEmailAsUsername} />
                    </Form.Item>
                    {(!isDisabled || useEmailAsUsername) &&
                        <Form.Item>
                            <Checkbox
                                checked={useEmailAsUsername}
                                onChange={(e) => setUseEmailAsUsername(e.target.checked)}
                                disabled={isDisabled}
                                className={styles.formCheckbox}>
                                {t('emailAsUsername')}
                            </Checkbox>
                        </Form.Item>
                    }
                    <Form.Item
                        name={'email'}
                        label={t('email')}
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                whitespace:true,
                                message: t('emailWrong')
                            },
                        ]}
                    >
                        <Input disabled={isDisabled}  />
                    </Form.Item>
                    <Form.Item
                    >
                        <Checkbox
                            checked={notificationChecked}
                            onChange={(e) => setNotificationChecked(e.target.checked)}
                            disabled={isDisabled || !notificationEnabled}
                            className={styles.formCheckbox}>
                            {t('sendNotification')}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name={'firstName'}
                        label={t('name')}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}>
                        <Input disabled={isDisabled} />
                    </Form.Item>
                    <Form.Item
                        name={'lastName'}
                        label={t('lastName')}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}>
                        <Input disabled={isDisabled} />
                    </Form.Item>
                    <Form.Item
                        className={styles.roleEnabled}
                        name={'role'}
                        label={t('role')}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                    >
                        <Select
                            onSelect={handleRoleChange}
                            disabled={isDisabled}
                            className={`${styles.roleSelector} ${isDisabled && styles.roleSelectorDisabled}`}
                        >
                            {Object.values(UserRole).map(role => (
                                <Option key={role}>{getRoleTranslation(role)}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        className={`${!isDisabled && styles.largeFormItem}`}
                        name={'company'}
                        label={t('company')}>
                        <Input disabled={isDisabled} />
                    </Form.Item>
                    {!isDisabled && <div>
                        <Form.Item
                            className={styles.passwordEnabled}
                            name={'password'}
                            label={t('password')}
                            rules={[
                                {
                                    required: true,
                                    message: ''
                                }
                            ]}>
                            <Input type={'password'} disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item
                            name={'confirmPassword'}
                            label={t('confirmPassword')}
                            initialValue={props.selectedUser && PASSWORD_PLACEHOLDER}
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: ''
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (getFieldValue('password') === value) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error(t('passwordNotMatched')))
                                    },
                                }),
                            ]}>
                            <Input type={'password'} disabled={isDisabled} />
                        </Form.Item>
                    </div>}
                    {props.organizations && props.organizations!.length > 0 &&
                        <div className={`${styles.organizations} ${styles.form}`}>
                            <Form.Item
                                className={styles.organizationsItem}
                                label={t('organizations')}
                                name={'organizations'}
                                rules={[
                                    {
                                        required: true,
                                        message: ''
                                    }
                                ]}>
                                <Select
                                    disabled={isDisabled}
                                    className={`${styles.itemSelector} ${isDisabled ? styles.itemSelectorDisabled : styles.itemSelectorEnabled}`}
                                    mode='multiple'
                                    maxTagCount={!isDisabled ? 'responsive' : undefined}
                                >
                                    {props.organizations.map(organization => (
                                        <Option key={organization.pk}>{organization.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>}
                </Form >
            </div >
        </>
    )

}
export default UserDetailForm