import i18n from '../../../i18n'
import { SoldQuantityShowItem } from './SoldQuantityPerItemList'

export function formatSoldQuantityPerItemData(data: SoldQuantityShowItem[]) {
    return data.map(item => {
        return {
            [i18n.t('ticketsPerShow')]: item.count,
            [i18n.t('show')]: item.showName,
            [i18n.t('status')]: item.status,
        }
    })
}