import { ReactElement } from 'react'

export const NotFound = (): ReactElement => {
    return (
        <>
            <div>
                NOT FOUND
            </div>
        </>
    )
}