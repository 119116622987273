import { Column, ColumnConfig } from '@ant-design/plots'
import _ from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import NoData from '../../../common/components/NoData'
import { ChartData } from '../../../common/components/charts/ChartData'
import ChartPopupMenu from '../../../common/components/charts/ChartPopupMenu'
import ChartTooltip from '../../../common/components/charts/ChartTooltip'
import NoSelection from '../NoSelection'
import styles from './SalesChart.module.css'
import { convertSalesChartData, formatSalesChartData } from './SalesChartUtils'

interface SalesChartProps {
    data: ChartData[],
    exportFilename: string,
    onExportClick?: (filename: string, data: object[]) => void
    onExpandClick?: () => void
    modalContainer?: React.RefObject<HTMLElement>
    error?: boolean
    loading: boolean
}

const Histogram = ({ data, exportFilename, onExpandClick, onExportClick, error, loading }: SalesChartProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [soldTickets, setSoldTickets] = useState<number>(0)

    const config: ColumnConfig = {
        data: convertSalesChartData(data),
        xField: 'date',
        yField: 'sales',
        label: {
            position: 'top',
            style: {
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: 400,
                fill: '#989898',
            },
            offsetY: 10
        },
        xAxis: {
            label: {
                style: {
                    fill: '#191919',
                    fontWeight: 'bolder'
                },
            },
            tickLine: null
        },
        yAxis: {
            grid: null,
            label: null
        },
        color: '#F7C217',
        maxColumnWidth: 18,
        minColumnWidth: 18,
        renderer: 'svg',
        tooltip: false,
        appendPadding: [15, 0, 5, 0]
    }
    useEffect(() => {
        setSoldTickets(_.sumBy(data, item => item.sales))
    }, [data])

    return (
        <>
            {loading ?
                <CustomSkeleton height={280} />
                :
                <div className={styles.container}>
                    {!error && data.length > 0 && (
                        <ChartPopupMenu style={{ float: 'right' }} onDetailClick={() => onExpandClick?.()} onExportClick={() => { onExportClick?.(exportFilename, formatSalesChartData(data)) }} />
                    )}
                    <h1 className={styles.h1}>
                        {t('ticketsSoldByApp')}
                        <span>
                            <ChartTooltip tooltipText={t('tooltip')} />
                        </span>
                    </h1>
                    {!error && data.length > 0 && (
                        <>
                            <span className={styles.soldTickets}>{soldTickets}</span>
                            <Column style={{ paddingTop: '30px', height: '73%' }} {...config} />
                        </>
                    )}
                    {!error && data.length === 0 && (
                        <NoData />
                    )}
                    {!!error && (
                        <NoSelection
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                margin: '0 auto',
                                paddingBottom: '30px'
                            }}
                            imageStyle={{ width: '40px', height: '40px', margin: 'auto' }}
                        />
                    )}
                </div>

            }
        </>
    )
}

export default Histogram
