import { Button, Form, Modal } from 'antd'
import { AxiosError } from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sha512 } from 'sha512-crypt-ts'
import { RootState } from '../../../../app/store'
import { ReactComponent as OkIcon } from '../../../../assets/checkMarkIcon.svg'
import { ReactComponent as AlertIcon } from '../../../../assets/danger.svg'
import '../../../../common/commonCss/buttons.css'
import '../../../../common/commonCss/modal.css'
import BackButton from '../../../../common/components/commonUI/BackButton'
import TitleWithSubtitle from '../../../../common/components/commonUI/TitleWithSubtitle'
import { Organization, User, UsersApiFactory } from '../../../../service/api'
import { ErrorResponse } from '../../../../service/model'
import { UserError, UserRole } from '../../AdminUtils'
import UserDetailForm from '../UserDetailForm/UserDetailForm'
import styles from './AddNewUser.module.css'

const AddNewUser = (): ReactElement => {
    const api = UsersApiFactory()
    const [t] = useTranslation('translations')
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [isSavedModalOpen, setIsSavedModalOpen] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [organizations, setOrganizations] = useState<Organization[]>()

    const currentUserState = useSelector((state: RootState) => state.curentUserState)

    useEffect(() => {
        setOrganizations(currentUserState.user.organizations)
    }, [])
    const handleNavigateBack = () => {
        navigate(-1)
    }
    const handleFinish = (values: any) => {
        setIsSaving(true)
        const newUser: User = values as User
        newUser.tenantId = currentUserState.user.tenantId
        newUser.organizations = organizations !== undefined ? organizations.filter(o => {
            return values.organizations.includes(o.pk.toString())
        }) : []
        api.addUser({
            pk: null,
            username: newUser.username,
            password: sha512.hex(newUser.password),
            email: newUser.email,
            role: newUser.role,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            company: newUser.company,
            enabled: true,
            notification: newUser.notification,
            timestamp: null,
            tenantId: newUser.tenantId,
            organizations: newUser.organizations
        }).then(() => {
            setIsSaving(false)
            setIsSavedModalOpen(true)
        }).catch((error: AxiosError) => {
            switch (error.response?.status) {
            case UserError.ERROR_409:
                setErrorMessage(t('userConflict'))
                break
            case UserError.ERROR_404:
                setErrorMessage(t('userNotFound'))
                break
            case UserError.ERROR_403: {
                if ((error.response!.data as ErrorResponse).title) {
                    setErrorMessage((error.response!.data as ErrorResponse).title ?? error.message)
                } else if (currentUserState.user.role !== UserRole.ADMIN) {
                    setErrorMessage(t('userNotAdmin'))
                } else {
                    setErrorMessage(t('userNotInOrganization'))
                }
                break
            }
            default: setErrorMessage(t('somethingWentWrong'))
            }
            setIsError(true)
        })
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubtitle
                    title={t('newUser')}
                    itemName={t('account')} />
                <div className={styles.buttonsContainer} >
                    <Button
                        disabled={isSaving}
                        className={`blackFillPositiveButton ${styles.deleteButton}`}
                        onClick={() => setIsCancelModalOpen(true)}>
                        {t('cancel')}
                    </Button>
                    <Button
                        loading={isSaving}
                        className='yellowFillPositiveButton'
                        onClick={() => form.submit()}>
                        {t('saveEditing')}
                    </Button>
                </div >
            </div >
            <div className={styles.backButtonContainer}>
                <BackButton />
            </div>
            <div className={styles.userForm}>
                <UserDetailForm form={form} onFinish={handleFinish} organizations={organizations}></UserDetailForm>
            </div >
            <Modal
                closable={false}
                className={'modal'}
                open={isCancelModalOpen}
                footer={
                    <>
                        <Button className='yellowOutlinedCancelButton' onClick={handleNavigateBack}>
                            {t('cancelModalYes')}
                        </Button>
                        <Button className={'blackFillPositiveButton'} onClick={() => setIsCancelModalOpen(false)}>
                            {t('keepEditing')}
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p>{t('cancelEditingQuestion')}</p>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={isSavedModalOpen}
                footer={
                    <>
                        <Button className='yellowOutlinedCancelButton' onClick={() => {
                            setIsSavedModalOpen(false)
                            form.resetFields()
                        }}>
                            {t('createNew')}
                        </Button>
                        <Button className={'blackFillPositiveButton'} onClick={handleNavigateBack}>
                            OK
                        </Button>
                    </>
                }>
                <OkIcon></OkIcon>
                <p>{t('userSaved')}</p>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={isError}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => setIsError(false)}>
                            OK
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p>{errorMessage}</p>
            </Modal>
        </div >
    )
}

export default AddNewUser