import { Button, ConfigProvider, Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../../common/Colors'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import NoData from '../../../common/components/NoData'
import UserAvatar from '../../../common/components/UserAvatar'
import { ORDER_DATE_FORMAT } from '../../../common/constants'
import { formatDate } from '../../../common/utils'
import i18n from '../../../i18n'
import NoSelection from '../NoSelection'
import styles from './OrderList.module.css'

interface OrderListProps {
    data: OrderListItem[]
    error: boolean
    loading: boolean
    onViewAll: () => void
}

interface OrderListHeaderProps {
    onViewAll: () => void
}

export interface OrderListItem {
    fullName: string,
    avatarUrl?: URL,
    show: string,
    datetime: dayjs.Dayjs,
    quantity: number,
    price: number,
    purchaseDatetime: dayjs.Dayjs
}

const OrderList = (props: OrderListProps): ReactElement => {

    const [t, i18n] = useTranslation('translations')

    const columns: ColumnsType<OrderListItem> = [
        {
            title: t('contact'),
            dataIndex: 'fullName',
            render: (fullName: string, record: OrderListItem) => (
                <UserAvatar fullName={fullName} avatarUrl={record.avatarUrl}></UserAvatar>
            )
        },
        {
            title: t('show'),
            dataIndex: 'show'
        },
        {
            title: t('dateTime'),
            dataIndex: 'datetime',
            align: 'center',
            render: (datetime: dayjs.Dayjs) => (
                <>{formatDate(datetime, ORDER_DATE_FORMAT)}</>
            )
        },
        {
            title: t('purchases'),
            dataIndex: 'quantity',
            align: 'center',
        },
        {
            title: t('price'),
            dataIndex: 'price',
            align: 'center',
            render: (price: number) => (
                <span className={styles.grayText}>
                    {new Intl.NumberFormat(i18n.language, { style: 'currency', currency: 'EUR' }).format(price)}
                </span>
            )
        },
        {
            title: t('purchaseDateTime'),
            dataIndex: 'purchaseDatetime',
            align: 'center',
            render: (datetime: dayjs.Dayjs) => (
                <span className={styles.grayText}>{formatDate(datetime, ORDER_DATE_FORMAT)}</span>
            )
        }
    ]

    const tableProps: TableProps<OrderListItem> = {
        pagination: false,
        rowKey: 'pk',
        title: () => (!props.loading && !props.error && props.data.length > 0) && <Header onViewAll={props.onViewAll} />,
    }

    return (
        <>
            {props.loading ?
                <CustomSkeleton height={280} />
                :
                <>
                    {!props.error && (
                        <ConfigProvider renderEmpty={() => (
                            <NoData style={{ height: '280px', marginTop: '-50px' }} />
                        )}>
                            <Table {...tableProps} dataSource={props.data} columns={columns} className={styles.table} />
                        </ConfigProvider>
                    )}
                    {props.error && (
                        <ConfigProvider renderEmpty={() => (
                            <NoSelection
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%',
                                    minHeight: '200px'
                                }}
                                imageStyle={{ fontSize: '40px', width: '40px', height: '40px', marginRight: '20px' }}
                                description={{ style: { color: Colors.gray }, text: t('noSelection') }} />
                        )}>
                            <Table {...tableProps} columns={columns} className={styles.table} />
                        </ConfigProvider>
                    )}
                </>

            }
        </>
    )
}

const Header = (props: OrderListHeaderProps): React.ReactElement => (
    <div className={styles.header}>
        {i18n.t('translations:latestOrders')}
        <Button type="text" onClick={props.onViewAll} className={styles.viewAll}>
            {i18n.t('translations:viewAll')}
        </Button>
    </div>
)

export default OrderList