import { useState } from 'react'
import expand from '../../../assets/expand.svg'
import exportData from '../../../assets/export.svg'
import more from '../../../assets/more.svg'
import PopupMenu from '../PopupMenu'
import { useTranslation } from 'react-i18next'

interface PropsType {
    onExportClick: () => void
    onDetailClick: () => void
    className?: string
    style?: React.CSSProperties
}

const ChartPopupMenu = (props: PropsType) => {
    const [t] = useTranslation('translations')
    const [popupVisible, setPopupVisible] = useState<boolean>(false)

    const handlePopupItemClicked = () => {
        setPopupVisible(!popupVisible)
    }

    return (
        <PopupMenu content={[
            {
                label: t('export'), key: '1', icon: <img src={exportData} />, onClick: () => {
                    props.onExportClick()
                    handlePopupItemClicked()
                }
            },
            {
                label: t('details'), key: '2', icon: <img src={expand} />, onClick: () => {
                    props.onDetailClick()
                    handlePopupItemClicked()
                }
            },
        ]}
        popupVisible={popupVisible} >
            <div style={{
                height: '20px', marginRight: '0', width: '20px', ...props.style
            }} className={props.className} onClick={handlePopupItemClicked}>
                <img src={more} style={{ margin: '0' }}  />
            </div>
        </PopupMenu>

    )
}

export default ChartPopupMenu
