import { Area } from '@ant-design/plots'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import closeDetail from '../../../assets/close.svg'
import NoSelection from '../../../pages/dashboard/NoSelection'
import { formatSoldAmountPerDayData } from '../../../pages/dashboard/soldamountperday/SoldAmountPerDayUtils'
import { Colors } from '../../Colors'
import { formatDate } from '../../utils'
import NoData from '../NoData'
import { ChartData } from './ChartData'
import ChartPopupMenu from './ChartPopupMenu'
import ChartTooltip from './ChartTooltip'
import ExportCsv from './ExportCsv'
import './lineChart.css'

export interface LineChartPropsType {
    height: number
    title: string
    items: ChartData[]
    valueLabel: string
    tooltipText: string
    expandable: boolean
    exportFilename: string
    error?: boolean
    onExpandClick?: () => void
    onCloseClick?: () => void
    onExportClick?: (filename: string, data: object[]) => void
    loading: boolean
}

interface ItemTooltip {
    title: string,
    name: string,
    value: number,
}

const styles = {
    container: {
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        minHeight: '280px',
        overflow: 'hidden',
        padding: '30px',
        height: '100%',
        width: '100%'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    titleContainerExpanded: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '30px'
    },
    title: {
        diplay: 'block',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        color: Colors.yellow,
        marginTop: '0',
        marginBottom: '0',
    },
    totalValue: {
        color: Colors.black,
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        fontSize: '26px',
        marginTop: '0',
        marginBottom: '0',
        marginLeft: '10px'
    },
    totalValueExpanded: {
        position: 'absolute' as const,
        color: Colors.black,
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        fontSize: '26px',
        marginTop: '30px',
        marginBottom: '0',
    },
    more: {
        display: 'flex',
        marginRight: '25px',
        marginLeft: 'auto'
    },
    moreDetail: {
        alignItems: 'middle',
        marginRight: 0,
        marginLeft: 'auto',
        paddingRight: '2%',
        marginTop: '10px'
    },
    lineGraphContainer: {
        width: '100%',
        height: '100%'
    },
    contentContainer: {
        minHeight: '100%',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px'
    }
}

const thousandSuffix = 'k'


const OldLineChart = (props: LineChartPropsType) => {

    const [t] = useTranslation('translations')

    const formatChartData = () => {
        const lineChartData = props.items
        return lineChartData.map(item => ({
            date: formatDate(item.date, 'DD MMM'),
            amount: item.sales,
        }))
    }

    const config = {
        data: formatChartData(),
        xField: 'date',
        yField: 'amount',
        // unlike width, height is not so flexible for the graph
        height: props.height,
        xAxis: {
            tickCount: 7,
            tickLine: null,
            label: {
                formatter: (value: string) => {
                    return value
                },
                style: {
                    fill: '#191919',
                    fontWeight: 700
                },
            },
        },
        yAxis: {
            line: {
                style: { stroke: Colors.white },
            },
            label: {
                formatter: (value: string) => {
                    const num = parseInt(value)
                    return num >= 1000 ? `${num / 1000} ${thousandSuffix}` : value
                }
            },
            position: 'right' as const,
            // FIXME hard-coded offset equals to 10
            maxLimit: props.items.map(item => item.sales).sort((a: number, b: number) => b - a)[0] + 10
        },
        line: { color: Colors.yellow },
        areaStyle: { fill: Colors.yellow, fillOpacity: 0.2 },
        point: {
            color: Colors.yellow,
            size: 2,
            style: {
                lineWidth: 2,
            }
        },
        tooltip: {
            marker: { fill: Colors.yellow },
            customContent: (title: string, items: ItemTooltip[]) => {
                return items.length > 0 ? items[0].value.toString() : ''
            },
            showTitle: false,
        }
    }

    return (
        <div style={styles.container}>
            <div style={!props.expandable ? { display: 'flex' } : { display: 'flex', marginBottom: '30px' }}>
                <h1 style={styles.title}>{props.title}
                    <ChartTooltip tooltipText={props.tooltipText} />
                </h1>
                {!props.error && props.items.length > 0 && !props.expandable &&
                    <p style={styles.totalValue}>{props.valueLabel}</p>}

                {props.expandable &&
                    <p style={styles.totalValueExpanded}>{props.valueLabel}</p>}

                {!props.error && props.items.length > 0 && !props.expandable &&
                    <div style={styles.more}>
                        <ChartPopupMenu
                            onExportClick={() => props.onExportClick?.(props.exportFilename, formatSoldAmountPerDayData(props.items))}
                            onDetailClick={() => props.onExpandClick?.()} />
                    </div>
                }
                {props.items.length > 0 && props.expandable &&
                    <div style={styles.moreDetail}>
                        <ExportCsv filename={props.exportFilename} data={formatSoldAmountPerDayData(props.items)} isImage={true} />
                        <Button style={{ border: 'none', boxShadow: 'none', marginLeft: '5px' }}
                            icon={<img src={closeDetail} />}
                            onClick={() => {
                                props.onCloseClick?.()
                            }} />
                    </div>
                }
            </div>
            <div style={styles.contentContainer}>
                {
                    !props.error ? (
                        props.items.length > 0 ?
                            <div>
                                <Area {...config} style={{
                                    ...styles.lineGraphContainer
                                }}
                                />
                            </div>
                            :
                            <NoData style={{ height: '100%', position: 'relative', margin: '0 auto' }} />
                    ) : (
                        <NoSelection
                            imageStyle={{ width: '40px', height: '50px', marginBottom: '0px', marginRight: '8px' }}
                            style={{
                                display: 'flex',
                                height: '100%',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingBottom: '30px'
                            }}
                            description={{ style: { color: '#989898' }, text: t('noSelection') }}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default OldLineChart
