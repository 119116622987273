import { Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../../common/Colors'
import { ChartData } from '../../../common/components/charts/ChartData'
import OldLineChart from '../../../common/components/charts/LineChart'
import './soldAmountPerDayChartDetail.css'

interface PropsType {
    valueLabel: string
    chartData: ChartData[]
    onCloseClick: () => void
}
const styles = {
    graphContainer: {
        minHeight: '440px',
        paddingLeft: '30px',
        paddingRight: '55px',
        backgroundColor: Colors.lighterGray,
    }
}


const SoldAmountPerDayChartDetail = (props: PropsType) => {
    const [t] = useTranslation('translations')

    const columns: ColumnsType<ChartData> = [
        {
            title: t('date'),
            dataIndex: 'date',
            key: t('date'),
            render: (datetime: number) => (
                <>{dayjs(datetime).format('DD MMM ')}</>
            ),
        },
        {
            title: t('totalPerDay'),
            dataIndex: 'sales',
            key: t('totalPerDay'),
        }
    ]

    const tableProps: TableProps<ChartData> = {
        pagination: false,
        rowKey: 'date'
    }

    const handleCloseClicked = () => {
        props.onCloseClick()
    }

    return (
        <div style={styles.graphContainer}>
            <OldLineChart
                valueLabel={props.valueLabel}
                height={280}
                title={t('totalSold')}
                items={props.chartData}
                tooltipText={t('totalSoldTooltip')}
                expandable={true}
                onCloseClick={handleCloseClicked}
                exportFilename={'SoldAmountPerDay'}
                loading={false} />
            <Table className="lineGraphTable" {...tableProps} columns={columns} dataSource={props.chartData} scroll={{ y: 500 }} />
        </div>
    )
}

export default SoldAmountPerDayChartDetail
