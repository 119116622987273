import { ChartData } from '../../../common/components/charts/ChartData'
import { formatDate } from '../../../common/utils'
import i18n from '../../../i18n'

export function formatSalesChartData(data: ChartData[]) {
    return data.map(data => {
        return {
            [i18n.t('translations:date')]: formatDate(data.date, 'DD/MM/YYYY'),
            [i18n.t('translations:ticketsSold')]: data.sales
        }
    })
}
export function convertSalesChartData(data: ChartData[]) {
    return data.map(item => {
        return {
            date: formatDate(item.date, 'D MMM'),
            sales: item.sales
        }
    })
}