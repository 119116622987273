import { Button, Modal } from 'antd'
import Search from 'antd/es/input/Search'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AlertIcon } from '../../../assets/danger.svg'
import searchStyles from '../../../common/commonCss/Search.module.css'
import '../../../common/commonCss/buttons.css'
import TitleWithSubtitle from '../../../common/components/commonUI/TitleWithSubtitle'
import { User, UsersApiFactory } from '../../../service/api'
import { OperationStatus } from '../AdminUtils'
import styles from './User.module.css'
import UserTable from './UserTable'


const NEW_USER_ADDRESS = 'new'
const USER_DETAIL_ADDRESS = 'user-detail'

const Users = (): ReactElement => {
    const api = UsersApiFactory()
    const [t] = useTranslation('translations')
    const [loaded, setLoaded] = useState<boolean>(false)
    const [users, setUsers] = useState<User[]>([])
    const [filteredUsers, setFilteredUsers] = useState<User[]>([])
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [searchedText, setSearchedText] = useState<string>('')
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [deletionResult, setDeletionResult] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })
    const navigate = useNavigate()

    const handleNewUserClick = () => {
        navigate(NEW_USER_ADDRESS)
    }
    const handleRowClick = (selectedUser: User) => {
        const params = {
            selectedUser: selectedUser
        }
        navigate(USER_DETAIL_ADDRESS, { state: params })
    }
    const handleRowSelection = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }
    const handleSearchChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchedText(event.target.value)
    }
    const filterData = () => {
        const searchedContent = searchedText.toLowerCase()
        const updatedData = searchedText.trim().length > 0 ?
            users.filter(user => user.firstName?.toLowerCase()
                .concat(' ')
                .concat(user.lastName?.toLowerCase())
                .includes(searchedContent)
                || user.username.toLowerCase().includes(searchedContent))
            : users
        setFilteredUsers(updatedData)
    }
    const handleDeleteUser = () => {
        setIsDeleting(true)
        selectedRowKeys.forEach(selectedUser => {
            const userPk = Number(selectedUser.valueOf())
            api.deleteUser(userPk).then(() => {
                setDeletionResult(prevState => (
                    {
                        operationErrors: prevState.operationErrors, operationSuccessful: [...prevState.operationSuccessful, userPk]
                    })
                )
            }).catch(error => {
                deletionResult.operationErrors.push({ pk: userPk, error: error.name })
            })
        })
    }
    const updateData = () => {
        setLoaded(false)
        api.getUsers().then(response => {
            const users = response.data
            setUsers(users)
            setFilteredUsers(users)
            setLoaded(true)
        })
    }
    useEffect(() => {
        filterData()
    }, [searchedText])

    useEffect(() => {
        updateData()
    }, [])
    useEffect(() => {
        if (selectedRowKeys.length > 0 && deletionResult.operationSuccessful.length === selectedRowKeys.length) {
            setIsDeleting(false)
            setIsDeleteModalOpen(false)
            updateData()
        } else if (deletionResult.operationErrors.length > 0) {
            setIsError(true)
            setErrorMessage(t('somethingWentWrong'))
        }
    }, [deletionResult])
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubtitle title={t('users')} itemName={t('users').toLowerCase()} items={users.length} />
                <div className={styles.buttonsContainer}>
                    <Button
                        onClick={() => setIsDeleteModalOpen(true)}
                        disabled={selectedRowKeys.length === 0}
                        className={`blackFillPositiveButton ${styles.cancelButton} ${styles.commonButton}`}>
                        {t('delete')}
                    </Button>
                    <Button
                        onClick={handleNewUserClick}
                        className={`yellowFillPositiveButton ${styles.commonButton}`}>
                        {t('newUser')}
                    </Button>
                </div>
            </div>

            <div className={styles.searchContainer}>
                <Search
                    className={searchStyles.search}
                    allowClear
                    onChange={handleSearchChanged}
                    disabled={!loaded}
                    placeholder={t('searchUser')}
                />
            </div>
            <div className={styles.tableContainer}>
                <UserTable
                    data={filteredUsers}
                    loaded={loaded}
                    onSelect={handleRowSelection}
                    onRowClick={handleRowClick} />
            </div>
            <Modal
                closable={false}
                className={'modal'}
                open={isDeleteModalOpen}
                footer={
                    <>
                        <Button disabled={isDeleting} className='yellowOutlinedCancelButton' onClick={() => setIsDeleteModalOpen(false)}>
                            {t('cancel')}
                        </Button>
                        <Button loading={isDeleting} className={'blackFillPositiveButton'} onClick={handleDeleteUser}>
                            {t('delete')}
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <Trans>{t('deleteUserQuestion', { count: selectedRowKeys.length })}</Trans>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={isError}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => setIsError(false)}>
                            OK
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p>{errorMessage}</p>
            </Modal>
        </div>
    )
}

export default Users