import { LoadingOutlined } from '@ant-design/icons'
import { Button, Modal, Spin } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckMarkIcon } from '../../../assets/checkMarkIcon.svg'
import { ReactComponent as AlertIcon } from '../../../assets/danger.svg'
import { Colors } from '../../../common/Colors'
import { TicketResultItem } from '../../../service/model'
import './ticketingModal.css'
import classNames from './TicketIssueModal.module.css'
export enum IssueTicketErrorType {
    SHOW_NOT_FOUND,
    CODE_NOT_FOUND,
    SERVER_NOT_RESPONDING,
    NONE
}
interface TicketIssueModalProps {
    open: boolean
    errorType: IssueTicketErrorType
    loading: boolean
    showTitle: string
    showDate: string
    tickets: TicketResultItem
    handleConfirmClick: () => void
}
const TicketIssueModal = (props: TicketIssueModalProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [selectedModal, setSelectedModal] = useState<JSX.Element>()
    const modalIcon = props.errorType !== IssueTicketErrorType.NONE ? <AlertIcon /> : <CheckMarkIcon />
    const createOkModal = () => {
        return (
            <>
                <p className={classNames.modalTitle}>{props.showTitle}</p>
                <p className={classNames.modalDate}>{props.showDate}</p>
                <p className={classNames.modalText}>{t('issueModal', { count: props.tickets.successfulCodes.length })}</p>
                <ul className={classNames.listContainer}>
                    {props.tickets.successfulCodes.map((code, index) => (
                        <li key={index}>{code}</li>
                    ))}
                </ul>
            </>
        )
    }

    const createFailedCodesModal = () => {
        return (
            <>
                <p className={classNames.modalTitle}>{t('codeNotFound')}</p>
                <ul className={classNames.listContainer}>
                    {props.tickets.failedCodes.map((failedCode, index) => (
                        <li key={index}>{failedCode.code}
                            <p className={classNames.errorMessage}> <p>{failedCode.error}</p></p>
                        </li>
                    ))}
                </ul >
            </>
        )
    }
    const createShowNotFoundModal = () => {
        return (
            <>
                <p className={classNames.modalTitle}>{t('showNotFound')}</p>
                <p className={classNames.modalDate}>{t('showNotFoundCancelText')}</p>
            </>
        )
    }
    const createServerNotRespondingModal = () => {
        return (
            <>
                <p className={classNames.modalTitle}>{t('serverNotRespondingTitle')}</p>
                <p className={classNames.modalDate}>{t('ticketsNotIssued')}</p>
            </>
        )
    }
    useEffect(() => {
        switch (props.errorType) {
        case IssueTicketErrorType.SHOW_NOT_FOUND:
            setSelectedModal(createShowNotFoundModal)
            break
        case IssueTicketErrorType.CODE_NOT_FOUND:
            setSelectedModal(createFailedCodesModal)
            break
        case IssueTicketErrorType.SERVER_NOT_RESPONDING:
            setSelectedModal(createServerNotRespondingModal)
            break
        default: setSelectedModal(createOkModal)
        }
    }, [props.loading])

    return (
        <div>
            <Modal
                className='ticketingModal ticketingModalPrimaryButton'
                open={props.open}
                closable={false}
                footer={[
                    !props.loading && <Button onClick={props.handleConfirmClick}>
                        OK
                    </Button>]}
            >
                {props.loading ?
                    <>
                        <p className={classNames.titleLoading}>{t('issueTickets')}</p>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 55, color: Colors.yellow }} spin />}></Spin>
                    </>
                    :
                    <>{modalIcon}{selectedModal}</>
                }

            </Modal>
        </div>
    )
}

export default TicketIssueModal