import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import TranslationsEN from './locales/en/translations.json'
import TranslationsIT from './locales/it/translations.json'
export const resources = {
    en: {
        translations: TranslationsEN
    },
    it: {
        translations: TranslationsIT
    }
}

i18n.use(languageDetector)
    .use(initReactI18next).init({
        fallbackLng: 'en',
        resources,
        defaultNS: 'translations',
        ns: ['translations']
    })

export default i18n