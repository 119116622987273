import { Button, type MenuProps } from 'antd'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVDownloader } from 'react-papaparse'
import { ReactComponent as ActionPopupIcon } from '../../../assets/actionPopupButton.svg'
import PopupMenu from '../../../common/components/PopupMenu'
import styles from './ExportMenuPopoverButton.module.css'

interface ExportMenuPopoverProps {
    dataSource: unknown[]
    selected?: number[]
    filename: string
    open: boolean
    onOpenChange: (value: boolean) => void
    deleteSelected: () => void
}

const ExportMenuItem = ({ dataSource, children, disabled, filename }: { dataSource: unknown[], children: ReactElement, disabled?: boolean, filename: string }): ReactElement => {
    const { CSVDownloader } = useCSVDownloader()
    if (disabled) {
        return children
    }
    return (
        <CSVDownloader
            filename={filename}
            bom={true}
            data={dataSource}
        >
            {children}
        </CSVDownloader>
    )
}

const ExportMenuPopoverButton = ({ dataSource, selected, filename, open, onOpenChange, deleteSelected }: ExportMenuPopoverProps) => {
    const [t] = useTranslation('translations')
    const [isMenuOpen, setMenuOpen] = useState(open)

    const closePopover = () => {
        setMenuOpen(false)
        onOpenChange(false)
    }

    const openPopover = () => {
        setMenuOpen(true)
        onOpenChange(true)
    }

    const filteredData = selected === undefined ? dataSource : selected.map(index => dataSource[index])

    const menuItems: MenuProps['items'] = [
        {
            key: 1,
            label: (
                <ExportMenuItem
                    dataSource={filteredData}
                    disabled={(selected?.length ?? 0) === 0}
                    filename={filename}
                >
                    <span className='ant-menu-title-content'>{t('exportSelected')}</span>
                </ExportMenuItem>
            ),
            disabled: (selected?.length ?? 0) === 0,
            onClick: closePopover
        },
        {
            key: 2,
            label: (
                <ExportMenuItem
                    dataSource={dataSource}
                    disabled={dataSource.length === 0}
                    filename={filename}
                >
                    <span className='ant-menu-title-content'>{t('exportAll')}</span>
                </ExportMenuItem>
            ),
            disabled: dataSource.length === 0,
            onClick: closePopover
        }
        /*{
            key: 3,
            label: t('deleteSelected'),
            disabled: (selected?.length ?? 0) === 0,
            onClick: () => {
                deleteSelected()
                closePopover()
            }
        }*/
    ]

    return (
        <PopupMenu
            content={menuItems}
            popupVisible={isMenuOpen}
            placement='bottomRight'
        >
            <Button
                type='text'
                shape='circle'
                icon={
                    <ActionPopupIcon />
                }
                className={styles.button}
                onClick={openPopover}
            />
        </PopupMenu>
    )
}

export default ExportMenuPopoverButton
