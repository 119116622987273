import Icon from '@ant-design/icons'
import { Button, Empty, Table } from 'antd'
import { FilterValue, SorterResult, TableRowSelection } from 'antd/es/table/interface'
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowDown } from '../../../assets/arrowDownYellow.svg'
import { ReactComponent as ArrowUp } from '../../../assets/arrowUpYellow.svg'
import { ReactComponent as NoDataIcon } from '../../../assets/cloud-cross.svg'
import { ReactComponent as TrashIcon } from '../../../assets/delete.svg'
import { Colors } from '../../../common/Colors'
import '../../../common/commonCss/tableView.css'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import { LineItem, Order } from '../../../service/api'
import './NestedTable.css'
import tableStyles from './OrdersTable.module.css'
interface PropsType {
    loaded: boolean
    data: Order[]
    onRowSelection: (selectedRowKeys: React.Key[]) => void
    onSortChange: (curentOrderedData: Order[]) => void
    onDelete: (order: Order) => void
}
const NESTEDTABLE_COL_WIDTH = 220
export default function OrdersTable(props: PropsType) {
    const [t] = useTranslation('translations')
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [tableData, setTableData] = useState<Order[]>([])
    useEffect(() => {
        setTableData(props.data)
        onSelectChange([])
    }, [props.data])
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        props.onRowSelection(newSelectedRowKeys)
    }
    const rowSelection: TableRowSelection<Order> = {
        selectedRowKeys,
        onChange: onSelectChange,
    }
    const expandedRowTable = (record: Order) => {
        const expandedColumns: ColumnsType<LineItem> = [
            {
                title: t('purchase'),
                dataIndex: 'ordinal',
                key: 'ordinal',
                width: NESTEDTABLE_COL_WIDTH
            },
            {
                title: t('name'),
                dataIndex: ['customer', 'name'],
                key: 'customer.name',
                width: NESTEDTABLE_COL_WIDTH
            },
            {
                title: t('email'),
                dataIndex: ['customer', 'email'],
                key: 'customer.email',
                width: NESTEDTABLE_COL_WIDTH
            },
            {
                title: t('voucher'),
                dataIndex: 'voucher',
                key: 'voucher',
                width: NESTEDTABLE_COL_WIDTH
            },
            {
                title: t('type'),
                dataIndex: 'type',
                key: 'type',
                width: NESTEDTABLE_COL_WIDTH
            },
            {
                title: t('price'),
                dataIndex: 'price',
                key: 'price',
                width: NESTEDTABLE_COL_WIDTH
            }

        ]
        return <Table rowKey={record => record.ordinal} className={'nestedTable'} columns={expandedColumns} dataSource={record.lineItems} pagination={false} />
    }

    const columns: ColumnsType<Order> = [
        {
            title: t('pointOfSale'),
            dataIndex: 'posName',
            key: 'posName',
            sorter: {
                compare: (a, b) => a.posName.localeCompare(b.posName)
            }
        },
        {
            title: t('orderId'),
            dataIndex: 'externalId',
            key: 'externalId',
            sorter: {
                compare: (a, b) => a.externalId - b.externalId
            }
        },
        {
            title: t('dateTime'),
            dataIndex: 'date',
            key: 'date',
            sorter: {
                compare: (a, b) => a.date.valueOf() - b.date.valueOf()
            },
            render: (date: dayjs.Dayjs) => {
                return <span>{dayjs(date).format('DD MMM YYYY HH:mm')}</span>

            }
        },
        {
            title: t('purchases'),
            dataIndex: 'purchaseCount',
            key: 'purchaseCount',
            sorter: {
                compare: (a, b) => a.purchaseCount - b.purchaseCount
            }
        },
        {
            title: t('total'),
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            sorter: {
                compare: (a, b) => a.totalPrice - b.totalPrice
            }
        },
        {
            title: ' ',
            dataIndex: '',
            key: 'x',
            render: (_, record) => <Button
                className={tableStyles.deleteButton}
                shape='circle'
                type='text'
                onClick={() => props.onDelete(record)}
                icon={<TrashIcon className={tableStyles.trashIcon} />} />,
        },
        Table.EXPAND_COLUMN
    ]
    const handleSortChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<Order>[] | SorterResult<Order>, extra: { currentDataSource: Order[] }) => {
        setTableData(extra.currentDataSource)
        props.onSortChange(extra.currentDataSource)
    }
    return (
        <div >
            {!props.loaded ?
                <div style={{ paddingBottom: '160px' }}>
                    <CustomSkeleton height={300} />
                </div>
                :
                <>
                    <Table
                        rowKey={record => record.pk}
                        rowSelection={rowSelection}
                        expandable={{
                            expandedRowRender: (record) => expandedRowTable(record),
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <><div onClick={(e) => onExpand(record, e)}><ArrowUp /></div></>
                                ) : (
                                    <><div onClick={(e) => onExpand(record, e)}><ArrowDown /></div></>
                                )
                        }}
                        onChange={handleSortChange}
                        dataSource={tableData}
                        columns={columns}
                        className={`tableView ${tableStyles.ordersTable}`}
                        showSorterTooltip={false}
                        pagination={{
                            position: ['bottomCenter'],
                            hideOnSinglePage: props.data.length <= 10,
                            showSizeChanger: true,
                            style: {
                                marginTop: '45px'
                            },
                            responsive: true,
                            showTitle: false
                        }}
                    />
                    {props.data.length === 0 &&
                        <Empty
                            image={<Icon component={NoDataIcon} />}
                            imageStyle={{ fontSize: '60px', marginTop: '50px', marginBottom: '-8px' }}
                            style={{ marginTop: '50px', paddingBottom: '180px' }}
                            description={(
                                <span style={{ color: Colors.black }}>{t('noData')}</span>
                            )} />
                    }
                </>
            }
        </div >
    )
}
