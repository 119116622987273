import { Colors } from '../../../common/Colors'
import { SoldQuantityShowItem } from './SoldQuantityPerItemList'

const styles = {
    container: {
        display: 'flex',
        width: '100%',
        height: '45px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    showContainer: {
        width: '80%',
        display: 'flex',
    },
    showLabel: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '12px',
        color: Colors.black,
        margin: '0px'
    },
    statusLabel: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '9px',
        color: Colors.yellow,
        marginLeft: '10px',
        marginTop: '2px'
    },
    countLabel: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '12px',
        color: Colors.gray,
        width: '20%',
        textAlign: 'center' as const
    }
}

const SoldQuantityPerItemListItem = (props: SoldQuantityShowItem) => {

    return (
        <div style={styles.container}>
            <div style={styles.showContainer}>
                <p style={styles.showLabel}>{props.showName}</p>
                <p style={styles.statusLabel}>{props.status}</p>
            </div>
            <p style={styles.countLabel}>{props.count}</p>
        </div>
    )
}
export default SoldQuantityPerItemListItem
