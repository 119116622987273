import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEndDate, setSelectedPresetValue, setStartDate } from '../../../app/Reducers/DashboardSlice'
import { RootState } from '../../../app/store'
import DateRangePicker from '../../../common/components/DateRangePicker'
import { NO_SELECTION_VALUE } from './DatePresetBar'

interface PropsType {
    onIntervalChanged?: () => void
    disabled: boolean
}


const DateSelector = (props: PropsType) => {
    const dispatch = useDispatch()
    const dateState = useSelector((state: RootState) => state.dashboardState)
    const [startLocalDate, setStartLocalDate] = useState<dayjs.Dayjs>(dateState.startDate)
    const [endLocalDate, setEndLocalDate] = useState<dayjs.Dayjs>(dateState.endDate)

    useEffect(() => {
        setStartLocalDate(dateState.startDate)
        setEndLocalDate(dateState.endDate)
    }, [dateState.endDate, dateState.startDate])

    const handleDateRangeChanged = (rangeDates?: [dayjs.Dayjs, dayjs.Dayjs]) => {
        if (rangeDates) {
            if (rangeDates[0] !== startLocalDate) {
                dispatch(setStartDate(rangeDates[0].hour(0).minute(0).second(0).millisecond(0)))
            }
            if (rangeDates[1] !== endLocalDate) {
                dispatch(setEndDate(rangeDates[1].hour(23).minute(59).second(59).millisecond(59)))
            }
            setStartLocalDate(rangeDates[0].hour(0).minute(0).second(0).millisecond(0))
            setEndLocalDate(rangeDates[1].hour(23).minute(59).second(59).millisecond(59))
        }
        dispatch(setSelectedPresetValue(NO_SELECTION_VALUE))
    }

    return (
        <div >
            <DateRangePicker
                startDate={startLocalDate}
                endDate={endLocalDate}
                dateRangeChanged={handleDateRangeChanged}
                disabled={props.disabled} />
        </div>
    )
}

export default DateSelector
