import { Button, Dropdown, Space, Typography } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import arrowDown from '../../../assets/arrowDown.svg'
import ExportCsv from '../../../common/components/charts/ExportCsv'
import './exportPopup.module.css'
import styles from './exportPopup.module.css'

const CSV_FORMAT = '.csv'

const dropdownItems = [
    {
        key: '1',
        label: CSV_FORMAT.toUpperCase(),
    }
]

interface PropsType {
    filename: string
    data: object[]
    onCloseClick: () => void
}

const ExportPopup = (props: PropsType) => {

    const [t] = useTranslation('translations')

    useEffect(() => {
        document.body.style.overflow = 'hidden'
    }, [])

    const handleCloseClicked = () => {
        document.body.style.overflow = 'scroll'
        props.onCloseClick()
    }

    return (
        <div className={styles.container}>
            <div className={styles.popupContainer}>
                <p className={styles.title}>{t('chooseExport')}</p>

                <Dropdown
                    menu={{ items: dropdownItems }}
                    trigger={['click']}
                    className={styles.exportDdropdown}>
                    <Typography.Link>
                        <Space>
                            {CSV_FORMAT.toUpperCase()}
                            <img src={arrowDown} className={styles.exportImage} />
                        </Space>
                    </Typography.Link>
                </Dropdown>

                <div className={styles.buttonsContainer}>
                    <Button className={styles.buttonCancel} type="default" onClick={handleCloseClicked}>
                        {t('cancel')}
                    </Button>
                    <ExportCsv filename={props.filename} data={props.data} isImage={false} />
                </div>
            </div>
        </div>
    )
}

export default ExportPopup