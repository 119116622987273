import { Col, Form, Radio, RadioChangeEvent, Row, Select } from 'antd'
import { BaseOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import { formatDate } from '../../../common/utils'
import { Rerun, Show, TicketsApiFactory } from '../../../service/api'
import TagComponent from '../tagComponent/TagComponent'
import styles from './TicketingForm.module.css'
import './ticketingForm.css'
interface TicketingFormProps {
    handleRadioSelection?: (e: RadioChangeEvent) => void
    handleShowSelection: (show: Show) => void
    handleDateSelection: (rerun: Rerun) => void
    handleTicketSelection: (tickets: string[]) => void
}

export enum RadioSelections {
    All,
    Single,
    None
}

const TicketingForm = (props: TicketingFormProps): ReactElement => {
    const api = TicketsApiFactory()
    const [t] = useTranslation('translations')
    const [shows, setShows] = useState<Show[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [selectedShow, setSelectedShow] = useState<Show>()
    const [selectedRerun, setSelectedRerun] = useState<Rerun>()
    const [isDatePickerEnabled, setIsDatePickerEnabled] = useState<boolean>(false)
    const [isTextAreaEnabled, setIsTextAreaEnabled] = useState<boolean>(false)
    const [isRadioEnabled, setIsRadioEnabled] = useState<boolean>(false)
    const [displayRadioButtons, setDisplayRadioButtons] = useState<boolean>(false)
    const [radioButtonValue, setRadioButtonValue] = useState<RadioSelections>(RadioSelections.None)
    useEffect(() => {
        api.getShows().then(response => {
            setShows(response.data)
            setIsLoading(false)
        })
        if (props.handleRadioSelection !== undefined) {
            setDisplayRadioButtons(true)
        }
    }, [])
    useEffect(() => {
        setSelectedRerun(undefined)
        setIsTextAreaEnabled(false)
        setIsRadioEnabled(false)
        setRadioButtonValue(RadioSelections.None)
    }, [selectedShow])

    const handleShowSelectionChange = (value: string, option: BaseOptionType) => {
        const show = shows.find(show => {
            return show.pk === Number(option.key)
        })
        if (show !== undefined) {
            setSelectedShow(show)
            props.handleShowSelection(show)
            setIsDatePickerEnabled(true)
        }
    }
    const handleDateSelectionChange = (value: string, option: BaseOptionType) => {
        const rerun = selectedShow!.reruns.find(rerun => {
            return rerun.pk === Number(option.key)
        })
        if (rerun !== undefined) {
            setSelectedRerun(rerun)
            props.handleDateSelection(rerun)
            displayRadioButtons ? setIsRadioEnabled(true) : setIsTextAreaEnabled(true)
        }
    }
    const handleRadioSelectionChange = (e: RadioChangeEvent) => {
        props.handleRadioSelection!(e)
        setRadioButtonValue(e.target.value)
        e.target.value === RadioSelections.Single ? setIsTextAreaEnabled(true) : setIsTextAreaEnabled(false)
    }
    const handleTextAreaChange = (tickets: string[]) => {
        props.handleTicketSelection(tickets)
    }
    return (
        <>
            {isLoading ?
                <div className={styles.formContainer}>
                    <Form
                        layout='vertical'
                        className={styles.form}>
                        <Row justify={'space-between'}>
                            <Col span={14}>
                                <Form.Item label={t('show')}>
                                    <CustomSkeleton height={50} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('dateTime')}>
                                    <CustomSkeleton height={50} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label={t('insertTicketCode')}>
                                    <CustomSkeleton height={170} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                :
                <div className={styles.formContainer}>
                    <Form
                        layout='vertical'
                        className={styles.form}>
                        <Row justify={'space-between'}>
                            <Col span={14}>
                                <Form.Item label={t('show')}>
                                    <Select
                                        value={selectedShow ? selectedShow.title : null}
                                        className={styles.showSelector}
                                        onChange={handleShowSelectionChange}>
                                        {shows.map(show => (
                                            <Select.Option key={show.pk} value={show.title}> {show.title} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('dateTime')}>
                                    <Select
                                        notFoundContent={t('noRerun')}
                                        disabled={!isDatePickerEnabled}
                                        className={styles.showSelector}
                                        value={selectedRerun ? formatDate(dayjs(selectedRerun!.date), 'D MMM YYYY HH:mm') : null}
                                        onChange={handleDateSelectionChange}
                                        style={{ height: '40px', width: '100%' }}>
                                        {selectedShow?.reruns.filter(rerun => {
                                            return dayjs(rerun.date).isAfter(dayjs())
                                        }).map(rerun => (
                                            < Select.Option key={rerun.pk} value={rerun.date} > {formatDate(dayjs(rerun.date), 'D MMM YYYY HH:mm')} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {(displayRadioButtons) &&
                            <Row>
                                <Col span={24}>
                                    <Radio.Group
                                        disabled={!isRadioEnabled}
                                        className={styles.radioContainer}
                                        name="radiogroup"
                                        value={(radioButtonValue !== RadioSelections.None) ? radioButtonValue : null}
                                        onChange={handleRadioSelectionChange}>
                                        <Radio className={styles.radioText} value={RadioSelections.Single}>{t('cancelCodes')}</Radio>
                                        <Radio className={styles.radioText} value={RadioSelections.All}>{t('cancelAll')}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>}
                        <Row>
                            <Col span={24}>
                                <Form.Item label={t('insertTicketCode')}>
                                    <TagComponent onChange={handleTextAreaChange} disabled={!isTextAreaEnabled} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </div >
            }
        </>
    )
}

export default TicketingForm