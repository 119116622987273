import { Button, Input, InputRef, Modal, Select, notification } from 'antd'
import Search from 'antd/lib/input/Search'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVDownloader } from 'react-papaparse'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedAction, setSelectedBatch, setSelectedWarehouse } from '../../../../../app/Reducers/InvetorySlice'
import { RootState } from '../../../../../app/store'
import { ReactComponent as PopupIcon } from '../../../../../assets/actionPopupButton.svg'
import { ReactComponent as AddIcon } from '../../../../../assets/addVariant.svg'
import { ReactComponent as CloseIcon } from '../../../../../assets/close.svg'
import { ReactComponent as AlertIcon } from '../../../../../assets/danger.svg'
import { ReactComponent as RemoveIcon } from '../../../../../assets/delete.svg'
import { ReactComponent as EditInfo } from '../../../../../assets/edit.svg'
import { ReactComponent as ExportIcon } from '../../../../../assets/export.svg'
import { ReactComponent as LocationIcon } from '../../../../../assets/locationIcon.svg'
import { ReactComponent as StockInIcon } from '../../../../../assets/stockIn.svg'
import { ReactComponent as StockMoveIcon } from '../../../../../assets/stockMove.svg'
import { ReactComponent as StockOutIcon } from '../../../../../assets/stockOut.svg'
import { Colors } from '../../../../../common/Colors'
import searchStyles from '../../../../../common/commonCss/Search.module.css'
import PopupMenu from '../../../../../common/components/PopupMenu'
import { formatDate } from '../../../../../common/utils'
import { Batch, InventoryApiFactory, Location } from '../../../../../service/api'
import styles from './WarehouseCardComponent.module.css'
import { WarehousePopover } from './WarehousePopover'
import { WarehouseTableComponent } from './WarehouseTableComponent'

interface WarehouseComponentProps {
    open: boolean
    warehouses: Location[]
    onSelection: (selectdLocation?: Location) => void
    onAddNew: () => void
    onDelete: () => void
}
enum SorterValue {
    QUANTITY = 0,
    NAME = 1
}

export enum StockAction {
    STOCK_IN,
    STOCK_OUT,
    STOCK_MOVE
}

export const WarehouseCardComponent = (props: WarehouseComponentProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [sorterOption, setSorterOption] = useState<SorterValue>()

    const [currentWarehouse, setCurrentWarehouse] = useState<Location | undefined>(undefined)
    const [filteredWarehouses, setFilteredWarehouses] = useState<Location[]>(props.warehouses)
    const [currentBatch, setCurrentBatch] = useState<Batch | undefined>(undefined)
    const [batches, setBatches] = useState<Batch[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const inputRef = useRef<InputRef>(null)
    const addressRef = useRef<InputRef>(null)
    const [warehouseName, setWarehouseName] = useState<string>()
    const [warehouseAddress, setWarehouseAddress] = useState<string>()

    const dispatch = useDispatch()
    const inventoryState = useSelector((state: RootState) => state.inventoryState)

    const api = InventoryApiFactory()


    const { CSVDownloader } = useCSVDownloader()

    const handleClick = (action: StockAction) => {
        dispatch(setSelectedWarehouse(currentWarehouse))
        dispatch(setSelectedAction(action))
        dispatch(setSelectedBatch(currentBatch))
    }

    const handleRowSelection = (currentBatch: Batch) => {
        setCurrentBatch(currentBatch)
    }

    const updateBatches = (warehousePk: number) => {
        api.getLocationBatches(warehousePk, inventoryState.variant!.pk).then(response => {
            setBatches(response.data)
        }).catch((error: AxiosError) => {
            notification.error({
                message: error.response?.status.toString(),
                description: error.message
            })
        }).finally(() => setIsLoading(false))
    }

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchedText = event.target.value.toLowerCase()
        setFilteredWarehouses(searchedText.length > 0 ?
            props.warehouses.filter(warehouse => {
                return warehouse.name.toLowerCase().includes(searchedText)
            }) : props.warehouses)
    }

    const handleSort = (value: SorterValue) => {
        const sorted = [...filteredWarehouses]
        setSorterOption(value)
        switch (value) {
        case SorterValue.QUANTITY:
            sorted.sort((a, b) => {
                if (a.count && b.count) {
                    return b.count - a.count
                } else if (a.count) {
                    return -1
                } else if (b.count) {
                    return 1
                }
                return 0
            })
            break
        case SorterValue.NAME:
            sorted.sort((a, b) => {
                if (a.name && b.name) {
                    return a.name.localeCompare(b.name)
                } else if (a.name) {
                    return -1
                } else if (b.name) {
                    return 1
                }
                return 0
            })
            break
        }
        setFilteredWarehouses(sorted)
    }

    const getExportData = (data: Batch[] = batches) => {
        return data.map(exportData => {
            return {
                ['Id']: exportData.pk,
                [t('date')]: formatDate(dayjs(exportData.date), 'DD MMM YYYY'),
                [t('batchIntervalStarting')]: exportData.batchStart,
                [t('batchIntervalEnding')]: exportData.batchEnd,
                [t('quantity')]: exportData.count
            }
        })
    }

    const handleLocationSelection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, location: Location) => {
        e.stopPropagation()
        setCurrentWarehouse(location)
        setWarehouseName(location.name)
        setWarehouseAddress(location.address)
        props.onSelection(location)
    }

    const reset = () => {
        setCurrentWarehouse(undefined)
        setCurrentBatch(undefined)
        props.onSelection(undefined)
    }

    const handleEditing = () => {
        if (currentWarehouse) {
            api.updateLocation(currentWarehouse.pk!,
                {
                    pk: currentWarehouse.pk!,
                    name: inputRef.current!.input!.value,
                    address: addressRef.current!.input!.value
                })
                .then(res => {
                    setIsEditing(false)
                })
                .catch((error: AxiosError) => {
                    inputRef.current!.input
                    notification.error({
                        message: error.response?.status.toString(),
                        description: error.message
                    })
                })
        }
    }
    const clearInputValues = () => {
        setWarehouseName(currentWarehouse?.name)
        setWarehouseAddress(currentWarehouse?.address)
    }

    useEffect(() => {
        if (isEditing) {
            inputRef.current!.focus({
                cursor: 'end',
            })
        }
    }, [isEditing])

    useEffect(() => {
        handleSort(SorterValue.QUANTITY)
    }, [])

    useEffect(() => {
        if (currentWarehouse) {
            updateBatches(currentWarehouse.pk!)
        }
    }, [inventoryState.operationComplete, currentWarehouse])

    return (
        <div className={styles.container}>
            {currentWarehouse === undefined ?
                <div>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>{t('warehouses')}</div>
                        <div className={styles.titleCount}>{`(${props.warehouses.length})`}</div>
                    </div>
                    <div className={styles.inputContainer}>
                        <Button
                            onClick={props.onAddNew}
                            className={`yellowFillPositiveButton ${styles.addNewButton}`}
                            icon={<AddIcon />}>
                            {t('addNew')}
                        </Button>
                        <div className={styles.sorterContainer}>
                            <div className={styles.sorter}>
                                {`${t('sortBy')}:`}
                                <Select
                                    value={sorterOption}
                                    popupClassName={styles.sortPopup}
                                    className={styles.sortSelector}
                                    options={[
                                        {
                                            value: SorterValue.QUANTITY,
                                            label: t('quantity')
                                        },
                                        {
                                            value: SorterValue.NAME,
                                            label: t('name')
                                        }
                                    ]}
                                    onChange={handleSort} />
                            </div>
                            <div className={styles.searchContainer}>
                                <Search
                                    onChange={(event) => handleSearch(event)}
                                    allowClear
                                    placeholder={t('searchWarehouse')}
                                    className={searchStyles.search} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.warehouseListConainer}>
                        {filteredWarehouses.map(warehouse => {
                            return (
                                <div key={warehouse.pk} className={styles.warehouseCardContainer}>
                                    <div
                                        className={styles.warehouseCard}
                                        onClick={(e) => {
                                            handleLocationSelection(e, warehouse)
                                        }}>
                                        <div className={styles.cardHeader}>
                                            <LocationIcon className={styles.cardIcon} />
                                            <div className={styles.cardLabel}>{warehouse.name}</div>
                                        </div>
                                        <div className={styles.cardFooter} onClick={(e) => {
                                            handleLocationSelection(e, warehouse)
                                        }}>
                                            <div
                                                className={`${styles.cardCount} ${(warehouse.count && warehouse.count >= 100) ? styles.cardCountNormal : styles.cardCountDanger}`}>
                                                {warehouse.count ?? '0'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.moreIcon}>
                                        <WarehousePopover getExportData={getExportData} warehouse={warehouse} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                :
                <div>
                    <div className={styles.titleContainer}>
                        <LocationIcon className={styles.detailIcon} />
                        <Input
                            value={warehouseName}
                            disabled={!isEditing}
                            defaultValue={currentWarehouse!.name}
                            ref={inputRef}
                            onChange={(e) => setWarehouseName(e.target.value)}
                            className={`${isEditing ? styles.editingWarehouse : styles.disabledWarehouse}`} />

                        <div className={styles.detailPopup}>
                            {isEditing ?
                                <div>
                                    <Button
                                        onClick={() => {
                                            setIsEditing(false)
                                            clearInputValues()
                                        }}
                                        className='yellowOutlinedCancelButton'>
                                        {t('cancel')}
                                    </Button>
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        onClick={handleEditing}
                                        className='yellowFillPositiveButton'>
                                        {t('save')}
                                    </Button>
                                </div>
                                :
                                <div>
                                    <Button
                                        onClick={() => {
                                            reset()
                                        }}
                                        className={styles.detailClose}
                                        type={'text'}
                                        icon={<CloseIcon />} />
                                    <PopupMenu
                                        content={[
                                            {
                                                label: t('editInfo'),
                                                key: '1',
                                                icon: <EditInfo />,
                                                onClick: () => setIsEditing(true)
                                            },
                                            {
                                                label: t('remove'),
                                                key: '2',
                                                icon: <RemoveIcon style={{ fill: Colors.red, justifyContent: 'center' }} />,
                                                danger: true,
                                                onClick: () => {
                                                    batches.length > 0 ? setModalOpen(true) : props.onDelete()
                                                }
                                            },
                                        ]}
                                    >
                                        <PopupIcon />
                                    </PopupMenu>
                                </div>
                            }
                        </div>
                    </div>
                    <Input
                        onChange={(e) => setWarehouseAddress(e.target.value)}
                        value={warehouseAddress}
                        ref={addressRef}
                        disabled={!isEditing}
                        placeholder={(currentWarehouse.address && currentWarehouse.address !== '') ? undefined : t('address')}
                        defaultValue={currentWarehouse!.address}
                        className={`${isEditing ? styles.editingAddress : styles.disabledAddress}`} />
                    <div className={`${isEditing && styles.disabledCard}`}>
                        {!isEditing &&
                            <>
                                <div className={styles.detailButtonsContainer}>
                                    <Button
                                        onClick={() => handleClick(StockAction.STOCK_IN)}
                                        className={styles.detailButtons}
                                        type='text'
                                        icon={<StockInIcon />}>
                                        {t('stockIn')}
                                    </Button>
                                    <Button
                                        disabled={currentBatch === undefined}
                                        onClick={() => handleClick(StockAction.STOCK_OUT)}
                                        className={styles.detailButtons}
                                        type='text'
                                        icon={<StockOutIcon />}>
                                        {t('stockOut')}
                                    </Button>
                                    <Button
                                        disabled={currentBatch === undefined}
                                        onClick={() => handleClick(StockAction.STOCK_MOVE)}
                                        className={styles.detailButtons}
                                        type='text'
                                        icon={<StockMoveIcon />}>
                                        {t('stockMove')}
                                    </Button>
                                    <div className={styles.csvDownloader}>
                                        <Button
                                            disabled={batches.length <= 0}
                                            className={styles.detailButtons}
                                            type='text' >
                                            {batches.length > 0 ?
                                                <CSVDownloader
                                                    filename={currentWarehouse.name}
                                                    bom={true}
                                                    data={getExportData}>
                                                    <div className={styles.csvLabel}><ExportIcon className={styles.csvIcon} />{t('exportAll')}</div>
                                                </CSVDownloader> :
                                                <div className={styles.csvLabel}><ExportIcon className={styles.csvIcon} />{t('exportAll')}</div>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </>

                        }
                        <WarehouseTableComponent
                            disabled={isEditing}
                            data={batches}
                            loading={isLoading}
                            onRowSelection={handleRowSelection} />
                    </div >
                </div>
            }
            <Modal
                closable={false}
                className={`modal ${styles.modal}`}
                maskClosable={false}
                open={modalOpen}
                footer={
                    <>
                        <Button
                            onClick={() => setModalOpen(false)}
                            className={'blackFillPositiveButton'}>
                            {'OK'}
                        </Button>
                    </>
                }
            >
                <AlertIcon />
                <div className={styles.deleteModal}>
                    {t('warehouseDeletionImpossible')}
                </div>
            </Modal >
        </div >
    )
}