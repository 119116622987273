import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVDownloader } from 'react-papaparse'
import actionPopupButton from '../../../../assets/actionPopupButton.svg'
import PopupMenu from '../../../../common/components/PopupMenu'
import { formatDate } from '../../../../common/utils'
import { Order } from '../../../../service/api'
import styles from './OrdersPopup.module.css'
interface PropsType {
    exportData: Order[]
    selectedData: Order[]
    onDelete: () => void
}

interface exportDataType {
    orderId?: number,
    orderDate?: dayjs.Dayjs,
    orderPurchaseCount?: number,
    orderTotalPrice?: number,
    orderPosName: string,
    lineItemOrdinal?: number,
    lineItemCustomerName?: string,
    lineItemCustomerEmail?: string,
    lineItemVoucher: string | null,
    lineItemType?: string,
    lineItemPrice?: number
}

export const OrdersPopup = ({ exportData, selectedData, onDelete }: PropsType) => {
    const [t] = useTranslation('translations')
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const { CSVDownloader } = useCSVDownloader()

    const handlePopupItemClicked = () => {
        setPopupVisible(!popupVisible)
    }
    const formatData = (orders: Order[]): exportDataType[] => {
        const orderLine: exportDataType[] = []
        orders.forEach(order => {
            order.lineItems.forEach(lineItem => {
                const line: exportDataType = {
                    orderId: 0,
                    orderDate: dayjs(),
                    orderPurchaseCount: 0,
                    orderTotalPrice: 0,
                    orderPosName: '',
                    lineItemOrdinal: 0,
                    lineItemCustomerName: '',
                    lineItemCustomerEmail: '',
                    lineItemVoucher: '',
                    lineItemType: '',
                    lineItemPrice: 0
                }
                line.orderId = order.pk
                line.orderDate = dayjs(order.date)
                line.orderPurchaseCount = order.purchaseCount
                line.orderTotalPrice = order.totalPrice
                line.orderPosName = order.posName
                line.lineItemOrdinal = lineItem.ordinal
                line.lineItemType = lineItem.type
                line.lineItemPrice = lineItem.price
                line.lineItemVoucher = lineItem.voucher
                line.lineItemCustomerEmail = lineItem.customer?.email
                line.lineItemCustomerName = lineItem.customer?.name
                orderLine.push(line)
            })
        })
        return orderLine
    }
    const createExportData = (data: Order[]) => {
        return formatData(data).map(order => {
            return {
                [t('pointOfSale')]: order.orderPosName,
                [t('orderId')]: order.orderId,
                [t('dateTime')]: formatDate(dayjs(order.orderDate), 'DD MMM YYYY HH:mm'),
                [t('purchases')]: order.orderPurchaseCount,
                [t('total')]: order.orderTotalPrice,
                [t('purchase')]: order.lineItemOrdinal,
                [t('name')]: order.lineItemCustomerName,
                [t('email')]: order.lineItemCustomerEmail,
                [t('voucher')]: order.lineItemVoucher,
                [t('type')]: order.lineItemType,
                [t('price')]: order.lineItemPrice,

            }
        })
    }


    const createExportlabel = (label: string) => {
        return <span className='ant-menu-title-content'>{label}</span>
    }
    const exportSelectedLabel = createExportlabel(t('exportSelected'))
    const exportAllLabel = createExportlabel(t('exportAll'))
    const exportMenuItem = () => (
        exportData.length > 0 && selectedData.length > 0 ?
            <CSVDownloader
                filename={t('orders')}
                bom={true}
                config={{
                    delimiter: ',',
                }}
                data={() => createExportData(selectedData)}
            >
                {exportSelectedLabel}
            </CSVDownloader> :
            <>{exportSelectedLabel}</>
    )


    const exportAllMenuItem = () => (
        exportData.length > 0 ?
            <CSVDownloader
                filename={t('orders')}
                bom={true}
                data={() => createExportData(exportData)}
            >
                {exportAllLabel}
            </CSVDownloader>
            :
            <>{exportAllLabel}</>
    )
    return (
        <PopupMenu
            content={[
                {
                    key: 1,
                    label: exportMenuItem(),
                    disabled: selectedData.length === 0,
                    onClick: () => {
                        handlePopupItemClicked()
                    }
                },
                {
                    key: 2,
                    label: exportAllMenuItem(),
                    disabled: exportData.length === 0,
                    onClick: () => {
                        handlePopupItemClicked()
                    }
                }
                /*{
                    key: 3,
                    label: t('deleteSelected'),
                    disabled: selectedData.length === 0,
                    onClick: () => onDelete()
                }*/
            ]}
            popupVisible={popupVisible}
            placement='bottomRight'>

            <div
                className={styles.popupContent}
                onClick={handlePopupItemClicked}>
                <img
                    src={actionPopupButton}
                    className={styles.popupImage} />
            </div>

        </PopupMenu>
    )
}


