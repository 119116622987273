import { ChartData } from '../../../common/components/charts/ChartData'
import { formatDate } from '../../../common/utils'
import i18n from '../../../i18n'

export function formatSoldAmountPerDayData(data: ChartData[]) {
    return data.map(item => {
        return {
            [i18n.t('date')]: formatDate(item.date, 'DD/MM/YYYY'),
            [i18n.t('totalSold')]: item.sales
        }
    })

}