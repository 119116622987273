import OldLineChart, { LineChartPropsType } from '../../../common/components/charts/LineChart'
import CustomSkeleton from '../../../common/components/CustomSkeleton'

const LineChartGraph = (props: LineChartPropsType) => {

    const chartProps = { ...props, expandable: false }

    return (
        <>
            {props.loading ?
                <CustomSkeleton height={280} />
                :
                <OldLineChart {...chartProps} />
            }

        </>
    )
}

export default LineChartGraph
