export const Colors = {
    lighterGray: '#F5F5F5',
    highlight: '#EFEFEF',
    lightGray: '#DDDDDD',
    gray: '#989898',
    white: '#FFFFFF',
    black: '#191919',
    yellow: '#F7C217',
    darkYellow: '#E7B100',
    red: '#EB5757',
    darkRed: '#C12B2B'
}