import { Button, Popover, notification } from 'antd'
import Search from 'antd/lib/input/Search'
import { AxiosError } from 'axios'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { ReactComponent as SlidersIcon } from '../../../assets/filters.svg'
import searchStyles from '../../../common/commonCss/Search.module.css'
import '../../../common/commonCss/modal.css'
import TitleWithSubitile from '../../../common/components/commonUI/TitleWithSubtitle'
import { Order, ProductsApiFactory } from '../../../service/api'
import DatePresetBar from '../../dashboard/dateselector/DatePresetBar'
import DateSelector from '../../dashboard/dateselector/DateSelector'
import { OperationStatus } from '../../users/AdminUtils'
import { DeleteModalManager, ModalOptions } from '../DeleteModalManager'
import { VariantsPopover } from '../Products/VariantsPopover'
import styles from './EcommerceOrders.module.css'
import { OrdersPopup } from './OrdersPopUp/OrdersPopup'
import OrdersTable from './OrdersTable'

export const EcommerceOrders = (): ReactElement => {
    const api = ProductsApiFactory()
    const [t] = useTranslation('translations')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [openFilterPopover, setOpenFilterPopover] = useState<boolean>(false)
    const [selectedVariant, setSelectedVariant] = useState<number>()
    const [orders, setOrders] = useState<Order[]>([])
    const [filteredOrders, setFilteredOrders] = useState<Order[]>([])
    const [selectedRows, setSelectedRows] = useState<Order[]>([])

    const [modalType, setModalType] = useState<ModalOptions>(ModalOptions.NONE)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [deletingOrder, setDeletingOrder] = useState<Order>()
    const [deletionResult, setDeletionResult] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })

    const dateState = useSelector((state: RootState) => state.dashboardState)

    const handleRowSelection = (selectedRowKeys: React.Key[]) => {
        setSelectedRows(orders.filter(order => {
            return selectedRowKeys.filter(selectedRowKey => {
                return order.pk?.toString() === selectedRowKey.toString()
            }).length > 0
        }))
    }
    const handleSortChange = (sortedData: Order[]) => {
        setFilteredOrders(sortedData)
    }
    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchedContent = event.target.value.toLowerCase()
        const filteredData = event.target.value.toLowerCase().trim().length > 0 ?
            orders.filter(order => {
                if (order.externalId?.toString().toLowerCase().includes(searchedContent) || order.lineItems.filter(lineItem =>
                    lineItem.voucher?.toString().toLowerCase().includes(searchedContent) ||
                    lineItem.customer?.name?.trim().toLowerCase().includes(searchedContent) ||
                    lineItem.customer?.email?.trim().toLowerCase().includes(searchedContent)).length > 0) {
                    return order
                }
            })
            : orders
        setFilteredOrders(filteredData)
    }

    useEffect(() => {
        setFilteredOrders(orders)
    }, [orders])

    useEffect(() => {
        updateData()
    }, [dateState.endDate, dateState.startDate, selectedVariant])

    const updateData = () => {
        setIsLoading(true)
        api.getProductOrders({
            startDate: dateState.startDate.valueOf(),
            endDate: dateState.endDate.valueOf(),
            pks: selectedVariant ? Array.of(selectedVariant) : []
        }).then(response => {
            setOrders(response.data)
            setIsLoading(false)
        })
    }
    const handleDelete = () => {
        setIsDeleting(true)
        if (deletingOrder !== undefined) {
            api.deleteOrder(deletingOrder.pk).then(() => {
                setModalType(ModalOptions.SUCCESSFUL)
            }).catch(() => {
                setModalType(ModalOptions.ERROR)
                notification.error({
                    message: t('error'),
                    description: `${t('somethingWentWrong')}: ${deletingOrder}`,
                })
            }).finally(() => {
                setIsDeleting(false)
                updateData()
            })
        }
        else {
            selectedRows.map((order) => {
                api.deleteOrder(order.pk).then(() =>
                    setDeletionResult(prevState => (
                        {
                            operationErrors: prevState.operationErrors, operationSuccessful: [...prevState.operationSuccessful, order.pk]
                        })
                    )
                ).catch((error: AxiosError) => {
                    notification.error({
                        message: t('error'),
                        description: t('somethingWentWrong')
                    })
                    deletionResult.operationErrors.push({ pk: order.pk, error: error.name })
                }).finally(() => {
                    if (deletionResult.operationErrors.length > 0) {
                        setModalType(ModalOptions.ERROR)
                    } else {
                        setModalType(ModalOptions.SUCCESSFUL)
                    }
                    updateData()
                    setIsDeleting(false)
                })
            })
        }
    }
    const handleModalChange = (value: ModalOptions) => {
        setModalType(value)
        if (value !== ModalOptions.SUCCESSFUL) {
            setDeletingOrder(undefined)
        }
    }

    const getOrderIdByPk = (pk: number): string => {
        const order = orders.find(order => order.pk === pk)
        return order && order.externalId ? order.externalId.toString() : '_'
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubitile title={t('orders')} items={filteredOrders.length} itemName={t('orders')} />
                <div className={styles.dateSelectorContainer}>
                    <DateSelector disabled={isLoading} />
                    <OrdersPopup
                        onDelete={() => setModalType(ModalOptions.DELETE)}
                        selectedData={selectedRows}
                        exportData={filteredOrders} />
                </div>
            </div>
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <Search
                        className={searchStyles.search}
                        allowClear
                        onChange={handleSearch}
                    />
                </div>
                <Popover
                    content={
                        <VariantsPopover
                            onValueChange={value => {
                                setOpenFilterPopover(false)
                                setSelectedVariant(value?.pk)
                            }}
                            onCancel={() => setOpenFilterPopover(false)}
                        />
                    }
                    trigger='click'
                    open={openFilterPopover}
                    placement='right'
                    onOpenChange={open => setOpenFilterPopover(open)}
                >
                    <Button icon={<SlidersIcon className={`${selectedVariant !== undefined && styles.filterIconActive}`} />} className={styles.filter} type='text' />
                </Popover>
                <div className={styles.datePreset}>
                    <DatePresetBar disabled={isLoading} />
                </div>
            </div>
            <div className={styles.tableContainer}>
                <OrdersTable
                    onDelete={(order) => {
                        setModalType(ModalOptions.DELETE)
                        setDeletingOrder(order)
                    }}
                    onSortChange={handleSortChange}
                    onRowSelection={handleRowSelection}
                    data={filteredOrders}
                    loaded={!isLoading}></OrdersTable>
            </div>
            <DeleteModalManager
                modalText={deletingOrder !== undefined ?
                    t('orderSingleDeletionQuestion', { orderId: getOrderIdByPk(deletingOrder.pk), pos: deletingOrder.posName })
                    :
                    t('deleteOrderQuestion', { count: selectedRows.length })}
                modalResultText={deletingOrder !== undefined ?
                    t('orderSingleDeletionResult', { orderId: getOrderIdByPk(deletingOrder.pk), pos: deletingOrder.posName })
                    :
                    t('deleteOrderResult', { count: selectedRows.length })
                }
                onChange={handleModalChange}
                modalType={modalType}
                onDelete={handleDelete}
                isDeleting={isDeleting} />
        </div>
    )
}
