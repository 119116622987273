import { CaretDownOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedItemsIds } from '../../app/Reducers/DashboardSlice'
import { RootState } from '../../app/store'
import CustomSkeleton from '../../common/components/CustomSkeleton'
import { getShows } from '../../service/DashboardService'
import { DashboardItem, DashboardItemGroup } from '../../service/model'
import styles from './OldItemSelector.module.css'
const { Option, OptGroup } = Select

interface PropsType {
    loading: boolean
}


const ItemSelector = (props: PropsType) => {
    const [t] = useTranslation('translations')
    const dispatch = useDispatch()
    const [selectionOpen, setSelectionOpen] = useState<boolean>(false)
    const [selectedItems, setSelectedItems] = useState<string[]>([t('allShows')])
    const [loaded, setLoaded] = useState<boolean>(false)
    const [showGroups, setShowGroups] = useState<DashboardItemGroup[]>([{ title: t('allShows'), items: [] }])
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    useEffect(() => {
        if (!dashboardState.config) {
            getShows((dashboardItemGroups: DashboardItemGroup[]) => {
                const groups = [...showGroups, ...dashboardItemGroups]
                setShowGroups(groups)
                onSelectAllItems(groups)
                setLoaded(true)
            })
        }
    }, [])

    const handleSelectionOpeningChanged = () => {
        setSelectionOpen(true)
    }

    const handleDropdownVisibilityChanged = (open: boolean) => {
        setSelectionOpen(open)
    }

    const onSelectAllItems = (groups: DashboardItemGroup[]) => {
        const selectedDashboardItems: DashboardItem[] = _.flatten(
            groups
                .map(group => group.items)
                .filter(items => items.length > 0)
        )
        setSelectedItems([t('allShows')])
        dispatch(setSelectedItemsIds(selectedDashboardItems.map(item => item.pk)))
    }

    const handleSelectionChanged = (values: string[]) => {
        let selectedDashboardItems: DashboardItem[] = []
        const lastSelection = values[values.length - 1]
        if (lastSelection === t('allShows')) {
            onSelectAllItems(showGroups)
        } else {
            const selectedItems = values.filter(item => item !== t('allShows'))
            setSelectedItems(selectedItems)
            selectedDashboardItems = _.flatten(
                showGroups
                    .map(group => group.items)
                    .filter(items => items.length > 0)
            ).filter(item => selectedItems.includes(item.name))
            dispatch(setSelectedItemsIds(selectedDashboardItems.map(item => item.pk)))
        }
    }

    return (
        <div className={styles.selectorContainer}>

            {!loaded ?
                <CustomSkeleton height={50} />
                :
                <>
                    <Select
                        className={styles.itemSelector}
                        mode='multiple'
                        value={selectedItems}
                        open={selectionOpen}
                        maxTagCount={'responsive'}
                        allowClear={true}
                        placeholder={t('selectPlaceholder')}
                        onChange={handleSelectionChanged}
                        onDropdownVisibleChange={handleDropdownVisibilityChanged}>

                        {showGroups.map((group: DashboardItemGroup) => (
                            group.items.length > 0 ?
                                <OptGroup
                                    key={group.title}
                                    label={group.title}>
                                    {group.items.map((item: DashboardItem) => (
                                        <Option key={item.pk} value={item.name}>{item.name}</Option>
                                    ))}
                                </OptGroup>
                                :
                                <Option key={group.title} value={group.title}>{group.title}</Option>
                        ))
                        }
                    </Select>
                    <div className={styles.arrow} onClick={handleSelectionOpeningChanged}>
                        <CaretDownOutlined />
                    </div>
                </>
            }
        </div>
    )
}

export default ItemSelector
