import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useMobileMediaQuery } from '../../../hooks/useMobileMediaQuery'
import HistogramChart from '../charts/histogramChart/HistrogramChart'
import LineChartDetail from '../charts/lineChart/LineChartDetail'
import NewLineChart from '../charts/lineChart/NewLineChart'
import ListChartDetail from '../charts/listChart/ListChartDetail'
import NewListChart from '../charts/listChart/NewListChart'
import NewTableList from '../charts/table/NewTableList'
import ExportPopup from '../exportpopup/ExportPopup'
import styles from './CustomGrid.module.css'
import { GridConfig, TableConfig } from './DashboardModel'
import classStyles from './NewDashboard.module.css'
const ResponsiveGridLayout = WidthProvider(Responsive)

interface CustomGridProps {
    loaded: boolean
    grid: GridConfig
    tables: TableConfig[]
}

enum GraphTypes {
    LINE_CHART = 'lineGraph',
    lLIST_GRAPH = 'listGraph',
    HISTOGRAM_CHART = 'histogramGraph'
}

const CustomGridComponent = (props: CustomGridProps) => {
    const isMobile = useMobileMediaQuery()
    const [grid, setGrid] = useState<JSX.Element[]>([])
    const [table, setTables] = useState<JSX.Element[]>([])
    const [selectedTitle, setSelectedTitle] = useState<string>('')
    const [selectedTooltip, setSelectedTooltip] = useState<string>('')
    const [selectedData, setSelectedData] = useState<any[]>([])
    const [currentDetail, setCurrentDetail] = useState<JSX.Element>()
    const [showExportPopup, setShowExportPopup] = useState<boolean>(false)
    const [showDetail, setShowDetail] = useState<boolean>(false)

    useEffect(() => {
        createGrid()
    }, [isMobile])

    useEffect(() => {
        if (props.loaded) {
            createGrid()
            createTables()
        }
    }, [props.loaded])

    const createGrid = () => {
        setGrid(props.grid.tiles.map((tile, index) => {
            return (
                <div
                    key={index}
                    className={classStyles.widget}
                    data-grid={isMobile ?
                        { x: 1, y: index, w: 1, h: 1, static: true } :
                        { x: tile.column - 1, y: tile.row - 1, w: tile.width, h: 1, static: true }}>
                    {tile.type === GraphTypes.HISTOGRAM_CHART &&
                        <HistogramChart
                            key={index}
                            dataSource={tile.dataSource}
                            title={tile.title}
                            tooltip={tile.tooltip}
                            onExportClick={handleExportClick}
                            onDetailClick={handleDetailOpen} />}
                    {tile.type === GraphTypes.LINE_CHART &&
                        <NewLineChart
                            key={index}
                            dataSource={tile.dataSource}
                            title={tile.title}
                            tooltip={tile.tooltip}
                            onExportClick={handleExportClick}
                            onDetailClick={handleLineDetailOpen} />}
                    {tile.type === GraphTypes.lLIST_GRAPH &&
                        <NewListChart
                            key={index}
                            dataSource={tile.dataSource}
                            title={tile.title}
                            tooltip={tile.tooltip}
                            onExportClick={handleExportClick}
                            onDetailClick={handleListDetailOpen}
                        />}
                </div>
            )
        }))
    }

    const createTables = () => {
        if (props.tables !== undefined) {
            setTables(props.tables.map((table, index) => {
                return (
                    <div key={index}>
                        <Row key={index} className={styles.tableContainer}>
                            <Col style={{ textAlign: 'center' }} span={24}>
                                <NewTableList
                                    key={index}
                                    dataSource={table.dataSource}
                                    title={table.title}
                                    tooltip={table.tooltip ? table.tooltip : undefined}
                                    onViewAll={handleListDetailOpen}
                                />
                            </Col>
                        </Row>
                    </div>
                )
            })
            )
        }
    }

    const handleListDetailOpen = (title: string, tooltip: string | undefined, data: any[], columnNames: string[], columnTypes: string[], dataSource: string) => {
        setShowDetail(true)
        setCurrentDetail(
            <>
                <ListChartDetail
                    dataSource={dataSource}
                    title={title}
                    tooltipText={tooltip}
                    items={data}
                    columnTypes={columnTypes}
                    columnLabels={columnNames}
                    onClose={handleDetailClose} />
            </>
        )
    }
    const handleLineDetailOpen = (title: string, tooltip: string, dataSource: string, data: any[], columnNames: string[]) => {
        setShowDetail(true)
        setCurrentDetail(
            <>
                <LineChartDetail
                    dataSource={dataSource}
                    title={title}
                    chartData={data}
                    columnLabels={columnNames}
                    onClose={handleDetailClose}
                    tooltip={tooltip}
                />
            </>
        )
    }
    const handleDetailOpen = (title: string, tooltip: string, data: any[]) => {
        setSelectedTitle(title)
        setSelectedTooltip(tooltip)
        setSelectedData(data)
    }
    const handleExportClick = (title: string, data: any[]) => {
        setSelectedTitle(title)
        setSelectedData(data)
        setShowExportPopup(true)
    }
    const handleDetailClose = () => {
        setShowDetail(false)
    }
    const handleExportClose = () => {
        setShowExportPopup(false)
    }
    return (
        <>
            {!showDetail &&
                <div className={styles.gridContainer}>
                    <ResponsiveGridLayout
                        className={styles.reactGrid}
                        breakpoints={{ lg: 1200, md: 992, sm: 768 }}
                        cols={{ lg: props.grid.width, md: props.grid.width, sm: 1 }}
                        rowHeight={280}
                        margin={isMobile ? [15, 15] : [30, 30]}
                    >
                        {grid}
                    </ResponsiveGridLayout>
                </div >
            }
            {!showDetail && table}
            {
                (showDetail && currentDetail) &&
                <div className={styles.detailContainer}>
                    {currentDetail}
                </div>
            }
            {
                showExportPopup &&
                <ExportPopup
                    filename={selectedTitle}
                    data={selectedData}
                    onCloseClick={handleExportClose} />
            }
        </>
    )
}

export default CustomGridComponent