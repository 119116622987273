import { Modal, Upload, UploadFile, UploadProps } from 'antd'
import { RcFile } from 'antd/lib/upload/interface'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UploadImage } from '../../../../../assets/uploadImage.svg'
import { Image } from '../../../../../service/api'
import DraggableUploadListItem from './DraggableUploadList'
import styles from './singleImageUploader.module.css'

interface uploadProps {
    file?: UploadFile
    productImage?: Image
    onLoad: (files: UploadFile<any>[]) => void
}
const MAX_FILES = 1
const getBase64 = (file: RcFile): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })
}
const SingleImageUploader = (props: uploadProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    useEffect(() => {
        if (props.productImage?.imageUrl && props.productImage?.id) {
            setFileList([{
                uid: props.productImage.id!,
                name: props.productImage.id!,
                url: props.productImage.imageUrl!
            }])
        } else if (props.file !== undefined) {
            handleChange!({ file: props.file, fileList: [props.file] })
        }
    }, [props.productImage])

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile)
        }
        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }

    const handleCancel = () => setPreviewOpen(false)
    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        props.onLoad(newFileList)
        setFileList(newFileList)
    }

    const uploadButton = (
        <div>
            <UploadImage />
            {fileList.length === 0 && <div style={{ marginTop: 8, width: '120px' }}>{t('dragOrClick')}</div>}
        </div>
    )
    return (
        <>
            <Upload
                style={{ display: 'flex' }}
                className={styles.imageUploader}
                /*Uploading Url*/
                beforeUpload={(file, fileList) => {
                    return false
                }}
                action=''
                listType='picture-card'
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                itemRender={(originNode, file) => (
                    <DraggableUploadListItem originNode={originNode} file={file} />
                )}
            >
                {fileList.length >= MAX_FILES ? null : uploadButton}
            </Upload>
            <Modal className={styles.imagePreview} open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt='example' style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    )
}

export default SingleImageUploader