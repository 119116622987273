import { Button, Modal } from 'antd'
import dayjs from 'dayjs'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckMarkIcon } from '../../assets/checkMarkIcon.svg'
import TitleWithSubtitle from '../../common/components/commonUI/TitleWithSubtitle'
import { formatDate } from '../../common/utils'
import { Rerun, Show, TicketingResponse, TicketsApiFactory } from '../../service/api'
import { ShowItem, ShowRerunItem } from '../../service/model'
import styles from './Tickets.module.css'
import TicketingForm from './ticketingForm/TicketingForm'
import TicketIssueModal, { IssueTicketErrorType } from './ticketingModal/TicketIssueModal'
const TicketIssue = (): ReactElement => {
    const api = TicketsApiFactory()
    const [t] = useTranslation('translations')
    const [formKey, setFormKey] = useState<number>(0)
    const [selectedShow, setSelectedShow] = useState<Show>()
    const [selectedRerun, setSelectedRerun] = useState<Rerun>()
    const [tickets, setTickets] = useState<string[]>([])
    const [isAlertModalOpen, setAlertModalOpen] = useState<boolean>(false)
    const [isIssueError, setIsIssueError] = useState<IssueTicketErrorType>(IssueTicketErrorType.NONE)
    const [openIssueModal, setOpenIssueModal] = useState<boolean>(false)
    const [issuingLoading, setIssuingLoading] = useState<boolean>(false)
    const [ticketSubscriptionResult, setTicketSubscriptionResult] = useState<TicketingResponse>({ successfulCodes: [], failedCodes: [] })
    const buttonEnabled = tickets.length > 0
    const handleIssueTickets = () => {
        setAlertModalOpen(true)
    }
    const handleIssueTicketsConfirm = () => {
        setIssuingLoading(true)
        api.subscribeTickets({ rerun: selectedRerun!, subscriptionCodes: tickets }).then(response => {
            const ticketSubscriptionResult = response.data
            setTicketSubscriptionResult(ticketSubscriptionResult)
            ticketSubscriptionResult.failedCodes.length > 0 ?
                setIsIssueError(IssueTicketErrorType.CODE_NOT_FOUND) :
                setIsIssueError(IssueTicketErrorType.NONE)
            setIssuingLoading(false)
            setAlertModalOpen(false)
            setOpenIssueModal(true)
        }).catch(() => {
            setIssuingLoading(false)
            setIsIssueError(IssueTicketErrorType.SERVER_NOT_RESPONDING)
        })
    }

    const handleModalConfirmClick = () => {
        if (isIssueError === IssueTicketErrorType.NONE) {
            resetForm()
        }
        setTicketSubscriptionResult({ successfulCodes: [], failedCodes: [] })
        setOpenIssueModal(false)
    }
    const resetForm = () => {
        setFormKey(key => key + 1)
    }
    const handleShowSelection = (show: ShowItem): void => {
        setSelectedShow(show)
    }
    const handleDateSelection = (rerun: ShowRerunItem): void => {
        setSelectedRerun(rerun)
    }
    const handleTicketSelection = (tickets: string[]): void => {
        setTickets(tickets)
    }
    const handleModalClose = () => {
        setAlertModalOpen(false)
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <TitleWithSubtitle title={t('issueTickets')} />
                <div className={styles.buttonContainer}>
                    <Button
                        htmlType='submit'
                        className={styles.primaryButton}
                        onClick={handleIssueTickets}
                        disabled={!buttonEnabled}>{t('issuing')}
                    </Button>
                    <Modal
                        maskClosable={true}
                        className='ticketingModal ticketingModalPrimaryButton'
                        closable={false}
                        onCancel={handleModalClose}
                        open={isAlertModalOpen}
                        okText={t('issueTickets')}
                        onOk={handleIssueTicketsConfirm}
                        confirmLoading={issuingLoading}
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <CheckMarkIcon />
                        <p className={styles.modalTitle}>{selectedShow?.title}</p>
                        <p className={styles.modalDate}>{formatDate(dayjs(selectedRerun?.date), 'D MMMM YYYY HH:mm')}</p>
                        <p>{t('issueQuestion', { count: tickets.length })}</p>
                        <ul className={styles.listContainer}>
                            {tickets.map((code, index) => (
                                <li key={index}>{code}</li>
                            ))}
                        </ul>
                    </Modal>
                    {openIssueModal &&
                        <TicketIssueModal
                            tickets={ticketSubscriptionResult}
                            showTitle={selectedShow!.title}
                            showDate={formatDate(dayjs(selectedRerun!.date), 'D MMMM YYYY HH:mm')}
                            handleConfirmClick={handleModalConfirmClick}
                            loading={issuingLoading}
                            open={openIssueModal}
                            errorType={isIssueError}
                        />}
                </div>
            </div>
            <TicketingForm
                key={formKey}
                handleShowSelection={handleShowSelection}
                handleDateSelection={handleDateSelection}
                handleTicketSelection={handleTicketSelection} />
        </div >
    )
}
export default TicketIssue