import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/it'
import { useState } from 'react'
import { ReactComponent as DateIcon } from '../../assets/datePickerImage.svg'
import './dateRangePicker.css'
const { RangePicker } = DatePicker
interface PropsType {
    startDate: dayjs.Dayjs
    endDate: dayjs.Dayjs
    dateRangeChanged: (dates?: [dayjs.Dayjs, dayjs.Dayjs]) => void
    disabled: boolean
}

const DateRangePicker = (props: PropsType) => {
    const [intervalChanged, setIntervalChanged] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<dayjs.Dayjs>()
    const [endDate, setEndDate] = useState<dayjs.Dayjs>()

    const handleIntervalChanged = (rangeDates: [dayjs.Dayjs, dayjs.Dayjs]) => {
        props.dateRangeChanged([rangeDates[0], rangeDates[1]])
    }

    return (
        <RangePicker
            suffixIcon={<DateIcon />}
            format={'DD MMM YYYY'}
            mode={['date', 'date']}
            value={[props.startDate, props.endDate]}
            allowClear={false}
            disabled={props.disabled}
            onChange={dates => {
                const rangeDates = dates as [dayjs.Dayjs, dayjs.Dayjs]
                setStartDate(rangeDates[0].hour(0).minute(0).second(0).millisecond(0))
                setEndDate(rangeDates[1].hour(23).minute(59).second(59).millisecond(59))
                setIntervalChanged(true)
            }}
            onOpenChange={open => {
                if (!open && intervalChanged) {
                    handleIntervalChanged([startDate!, endDate!])
                }
            }}
        />
    )
}

export default DateRangePicker
