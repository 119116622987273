import { Button, Table, TableProps } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import ExpandIcon from '../../../../assets/expand.svg'
import { Colors } from '../../../../common/Colors'
import '../../../../common/commonCss/tableView.css'
import CustomSkeleton from '../../../../common/components/CustomSkeleton'
import NoData from '../../../../common/components/NoData'
import ChartTooltip from '../../../../common/components/charts/ChartTooltip'
import ExportCsv from '../../../../common/components/charts/ExportCsv'
import { useMobileMediaQuery } from '../../../../hooks/useMobileMediaQuery'
import { DashboardApiFactory } from '../../../../service/api'
import NoSelection from '../../NoSelection'
import classStyles from '../dashboardGraphs.module.css'
import styles from './table.module.css'

interface TableCharProps {
    dataSource: string
    title: string
    tooltip?: string
    onViewAll: (title: string, tooltip: string | undefined, data: any[], columnNames: string[], columnTypes: string[], dataSource: string) => void
}

interface HeaderProps {
    title: string
    tableData: any[]
    handleDetailClick: () => void
}
export const formatDataByType = (type: string, value: string | number) => {
    if (type === 'date') {
        return dayjs(value).format('DD MMM HH:mm')
    } else if (type === 'day') {
        return dayjs(value).format('L')
    } else {
        return value
    }
}
const NewTableList = (props: TableCharProps): ReactElement => {
    const api = DashboardApiFactory()
    const isMobile = useMobileMediaQuery()
    const [t, i18n] = useTranslation('translations')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [filteredAndSortedItems, setFilteredAndSortedItems] = useState<any[]>([])
    const [tableData, setTableData] = useState<any[]>([])
    const [columnLabels, setColumnLabels] = useState<string[]>([])
    const [columnTypes, setColumnTypes] = useState<string[]>([])
    const title: string = t(props.title as unknown as TemplateStringsArray)
    const tooltip: string = t(props.tooltip as unknown as TemplateStringsArray)
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const error = dashboardState.selectedItemsIds.length === 0 ? true : false
    const handleDetailClick = () => {
        props.onViewAll(title, props.tooltip, tableData, columnLabels, columnTypes, props.dataSource)
    }
    useEffect(() => {
        if (!error) {
            setIsLoading(true)
            const start = dashboardState.startDate.clone().hour(0).minute(0).second(0)
            const end = dashboardState.endDate.clone().hour(23).minute(59).second(59)
            api.getChartData(props.dataSource, {
                pks: dashboardState.selectedItemsIds,
                startDate: start.valueOf(),
                endDate: end.valueOf()
            }).then(response => {
                const labels: string[] = []
                const rawData = response.data
                rawData.labels.forEach((label) => {
                    labels.push(i18n.t('translations:' + label))
                })
                setColumnLabels(labels)
                setColumnTypes(rawData.types)
                setTableData(rawData.data.map((item) => {
                    const result = {}
                    item.map((value, index) => ({
                        [labels[index]]: formatDataByType(rawData.types[index], value)
                    })).forEach(object => {
                        if (object[i18n.t('translations:status')] !== undefined) {
                            object[i18n.t('translations:status')] = i18n.t('translations:' + object[i18n.t('translations:status')])
                        }
                        Object.assign(result, object)
                    })
                    return result
                }))
                setIsLoading(false)
            })
        }
    }, [dashboardState.selectedItemsIds, dashboardState.startDate, dashboardState.endDate])

    const columns: ColumnsType<any> = columnLabels.map((column, index) => {
        return {
            title: column,
            dataIndex: column,
            key: index,
            fixed: (isMobile && index === 0) ? 'left' : undefined,
            ellipsis: true,
            sorter: (a, b) => {
                switch (columnTypes[index]) {
                case 'number': return a[column] - b[column]
                case 'string': return String(a[column]).localeCompare(String(b[column]))
                case 'date': return dayjs(a[column], 'DD MMM HH:mm').diff(dayjs(b[column], 'DD MMM HH:mm'))
                case 'day': return dayjs(a[column]).diff(dayjs(b[column]))
                default: return String(a[column]).localeCompare(String(b[column]))
                }
            }
        }
    })
    const tableProps: TableProps<any[]> = {
        pagination: false,
        rowKey: 'header',
        title: () => (!isLoading) &&
            <div>
                <div className={classStyles.titleContainer}>
                    <h1 className={`${classStyles.title}`}>
                        {title}
                        {props.tooltip &&
                            <span className={styles.tooltipContainer}>
                                <ChartTooltip tooltipText={tooltip} />
                            </span>
                        }
                    </h1>
                    {!error && tableData.length > 0 &&
                        <div className={classStyles.more}>
                            <ExportCsv filename={title} data={tableData} isImage={true} />
                            <Button
                                className={classStyles.graphIconButton}
                                icon={<img src={ExpandIcon} />}
                                onClick={handleDetailClick} />
                        </div>
                    }
                </div>
            </div>
    }
    useEffect(() => {
        setFilteredAndSortedItems(tableData)
    }, [tableData])
    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setFilteredAndSortedItems(extra.currentDataSource)
    }

    return (
        <>

            {isLoading ?
                <CustomSkeleton height={280} />
                :
                <>
                    <div className={classStyles.container}>
                        <Table
                            scroll={{ x: 400 }}
                            rowKey={record => record[columnLabels[0]]}
                            showSorterTooltip={false}
                            {...tableProps}
                            dataSource={filteredAndSortedItems.slice(0, 10)}
                            columns={columns}
                            onChange={onChange}
                            className={`tableView ${styles.table}`} />
                        {!error && filteredAndSortedItems.length === 0 &&
                            <NoData
                                className={styles.noData}
                                style={{ height: '100%', position: 'relative', margin: 'auto' }} />
                        }
                        {error &&
                            <NoSelection
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%',
                                    minHeight: '200px'
                                }}
                                imageStyle={{ fontSize: '40px', width: '40px', height: '40px', marginRight: '20px' }}
                                description={{ style: { color: Colors.gray, fontFamily: 'Open Sans', fontWeight: '500px', fontSize: '14px' }, text: t('noSelection') }} />
                        }
                    </div>
                </>
            }
        </>
    )
}

export default NewTableList