import { Button, Modal } from 'antd'
import { ReactElement, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as CloneIcon } from '../../../../../assets/clone.svg'
import { ReactComponent as AlertIcon } from '../../../../../assets/danger.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete.svg'
import { Colors } from '../../../../../common/Colors'
import styles from './collapsibleHeader.module.css'

interface headerProps {
    title: string
    handleDeleteVariant: () => void
    handleCloneVariant: () => void
}

const CollapsibleHeader = (props: headerProps): ReactElement => {
    const [isTrashModalOpen, setIsTrashModalOpen] = useState<boolean>(false)
    const [t] = useTranslation('translations')
    const showModal = () => {
        setIsTrashModalOpen(true)
    }
    const handleModalTrash = () => {
        props.handleDeleteVariant()
        setIsTrashModalOpen(false)
    }
    const handleModalCancel = () => {
        setIsTrashModalOpen(false)
    }
    return (
        <>
            <div >
                <div className={styles.buttonsContainer}>
                    <Button onClick={(event) => { event.stopPropagation(); showModal() }}
                        icon={<DeleteIcon color={Colors.white} />}
                        className={styles.deleteCard}
                        shape='circle' />
                    <Button onClick={(event) => { event.stopPropagation(); props.handleCloneVariant() }}
                        icon={<CloneIcon color={Colors.white} />}
                        className={styles.copyCard}
                        shape='circle' />
                </div>
                <Modal
                    className={styles.trashModal}
                    closable={false}
                    open={isTrashModalOpen}
                    okText={t('moveToTrash')}
                    onOk={handleModalTrash}
                    onCancel={handleModalCancel}
                    cancelText={t('cancel')}>
                    <AlertIcon />
                    <p><Trans>{t('modalQuestionMessage', { cardTitle: props.title })}</Trans></p>
                </Modal>
            </div>
        </>
    )
}

export default CollapsibleHeader