import { Popover } from 'antd'
import { ReactElement, useState } from 'react'
import moreIcon from '../../../../assets/more_vertical.svg'
import { useMobileMediaQuery } from '../../../../hooks/useMobileMediaQuery'
import styles from './CustomDropdown.module.css'

interface CustomDropdownProps {
    userMenuContent: JSX.Element
    avatar: JSX.Element
}
export const CustomDropdown = (props: CustomDropdownProps): ReactElement => {
    const isMobile = useMobileMediaQuery()
    const [userMenuVisible, setUserMenuVisible] = useState(false)

    return (
        <>
            {isMobile ?
                <Popover
                    className={styles.popover}
                    placement='bottomLeft'
                    content={props.userMenuContent}
                    trigger='click'
                    arrow={{ pointAtCenter: true }}
                    open={userMenuVisible}
                    onOpenChange={() => setUserMenuVisible(prevState => !prevState)}>
                    {props.avatar}
                </Popover>
                :
                <>
                    {props.avatar}
                    <Popover
                        className={styles.popoverMobile}
                        placement='bottomRight'
                        content={props.userMenuContent}
                        trigger='click'
                        arrow={{ pointAtCenter: true }}
                        open={userMenuVisible}
                        onOpenChange={() => setUserMenuVisible(prevState => !prevState)}>
                        <img src={moreIcon} className={styles.moreIcon} />
                    </Popover >
                </>
            }
        </>
    )
}