import { Button, DatePicker, Select } from 'antd'
import dayjs from 'dayjs'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerDetail } from '../../../../service/api'
import styles from './ContactFilterPopover.module.css'
const { RangePicker } = DatePicker

interface ContactFilterPopoverProps {
    locations: string[]
    data: CustomerDetail[]
    onCancel: () => void
    onFilter: (location?: string, startDate?: dayjs.Dayjs, endDate?: dayjs.Dayjs) => void
}

export const ContactFilterPopover = (props: ContactFilterPopoverProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [selectedLocation, setSelectedLocation] = useState<string>()
    const [startDate, setStartDate] = useState<dayjs.Dayjs>()
    const [endDate, setEndDate] = useState<dayjs.Dayjs>()
    return (
        <>
            <div className={styles.popover}>
                <div className={styles.label}>{t('city')} </div>
                <Select
                    allowClear
                    className={styles.select}
                    value={selectedLocation}
                    options={props.locations.map(location => { return { value: location === '' ? t('other') : location, label: location === '' ? t('other') : location } })}
                    onChange={value => {
                        setSelectedLocation(value)
                    }}
                />
                <div className={styles.dateContainer}>
                    <div className={styles.label}>{t('registration')}</div>
                    <RangePicker
                        onChange={dates => {
                            if (dates === null) {
                                setStartDate(undefined)
                                setEndDate(undefined)
                            } else {
                                const rangeDates = dates as [dayjs.Dayjs, dayjs.Dayjs]
                                setStartDate(rangeDates[0])
                                setEndDate(rangeDates[1])
                            }
                        }}
                        className={styles.rangePicker} />
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        className={`yellowOutlinedCancelButton ${styles.cancelButton}`}
                        onClick={() => props.onCancel()}>
                        {t('cancel')}
                    </Button>
                    <Button
                        className={`yellowFillPositiveButton ${styles.filterButton}`}
                        onClick={() => props.onFilter(selectedLocation, startDate, endDate)}>
                        {t('filter')}
                    </Button>
                </div>
            </div >
        </>
    )
}