import { Collapse } from 'antd'
import _ from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { ReactComponent as YellowArrowDownIcon } from '../../../../../assets/arrowDownYellow.svg'
import { ReactComponent as YellowArrowUpIcon } from '../../../../../assets/arrowUpYellow.svg'
import { Header } from './Header'
import styles from './VariantStocksComponent.module.css'
import { WarehouseCardComponent } from './WarehouseCardComponent'
import { InventoryVariant, Location } from '../../../../../service/api'

interface VariantstocksProps {
    open: boolean
    variant: InventoryVariant
    defaultOpen: number
    onAddNew: () => void
    onChange: (open: boolean, variant: InventoryVariant) => void
    onSelection: (location?: Location) => void
    onDelete: () => void
}

export const VariantStocksComponent = (props: VariantstocksProps): ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(props.open)
    const [locations, setlocations] = useState<Location[]>(props.variant.inventoryLocations.filter(location => !location.virtual))

    useEffect(() => {
        if (props.defaultOpen === props.variant.pk) {
            handleOpenChange()
        }
    }, [])

    const getVariantStocks = (): number => {
        return _.sumBy(locations, datum => datum.count ? datum.count : 0)
    }

    const handleOpenChange = () => {
        setIsOpen(!isOpen)
        props.onChange(!isOpen, props.variant)
    }
    return (
        <div className={styles.collapseContainer}>
            <Collapse
                defaultActiveKey={[props.defaultOpen]}
                className={`${styles.collapseItem} ${isOpen && styles.collapseItemOpen}`}
                expandIconPosition='end'
                collapsible='header'
                items={[{
                    key: props.variant.pk,
                    label:
                        <Header
                            open={isOpen}
                            totalStocks={getVariantStocks()}
                            warehouses={locations.map(location =>
                                location.name)} />,
                    children:
                        <WarehouseCardComponent
                            open={isOpen}
                            onSelection={props.onSelection}
                            onAddNew={props.onAddNew}
                            onDelete={props.onDelete}
                            warehouses={locations} />
                }]}
                expandIcon={() => {
                    return isOpen ? <YellowArrowUpIcon /> : <YellowArrowDownIcon />
                }}
                onChange={() => {
                    handleOpenChange()
                }}
            >
            </Collapse>
        </div>
    )
}