import styles from './ListItem.module.css'

interface ListItemProps {
    labels: string[]
    data: any[]
}

const ListItem = (props: ListItemProps) => {
    const data = Object.values(props.data)
    const itemCount = data.length
    return (
        <>
            {
                (itemCount === 3) ?
                    <div className={styles.container}>
                        <div className={styles.showContainer}>
                            <p className={styles.showLabel}>{data[0]}</p>
                            <p className={styles.statusLabel}>{data[2]}</p>
                        </div>
                        <p className={styles.countLabel}>{data[1]}</p>
                    </div> :
                    <div className={styles.container}>
                        <div className={styles.showContainer}>
                            <p className={styles.showLabel}>{data[0]}</p>
                        </div>
                        <p className={styles.countLabel}>{data[1]}</p>
                    </div>
            }
        </>
    )
}
export default ListItem
