import { Button, Modal, RadioChangeEvent } from 'antd'
import dayjs from 'dayjs'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AlertIcon } from '../../assets/danger.svg'
import TitleWithSubtitle from '../../common/components/commonUI/TitleWithSubtitle'
import { formatDate } from '../../common/utils'
import { Rerun, Show, TicketingResponse, TicketsApiFactory } from '../../service/api'
import styles from './Tickets.module.css'
import TicketingForm, { RadioSelections } from './ticketingForm/TicketingForm'
import TicketCancelModal, { CancelTicketErrorType } from './ticketingModal/TicketCancelModal'

const TicketCancel = (): ReactElement => {
    const api = TicketsApiFactory()
    const [t] = useTranslation('translations')
    const [formKey, setFormKey] = useState<number>(0)
    const [selectedShow, setSelectedShow] = useState<Show>()
    const [selectedRerun, setSelectedRerun] = useState<Rerun>()
    const [tickets, setTickets] = useState<string[]>([])
    const [allTicketSelected, setAllTicketSelected] = useState<boolean>(false)
    const [isAlertModalOpen, setAlertModalOpen] = useState<boolean>(false)
    const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [cancelTicketResult, setCancelTicketResult] = useState<TicketingResponse>({ successfulCodes: [], failedCodes: [] })
    const [cancelModalErrorType, setCancelModalErrorType] = useState<CancelTicketErrorType>(CancelTicketErrorType.NONE)
    const buttonEnabled = tickets.length > 0 || allTicketSelected
    const resetForm = () => {
        setFormKey(key => key + 1)
    }
    const handleShowSelection = (show: Show): void => {
        setSelectedShow(show)
        setAllTicketSelected(false)
    }
    const handleDateSelection = (rerun: Rerun): void => {
        setSelectedRerun(rerun)
    }
    const handleTicketsSelectionChange = (tickets: string[]) => {
        setTickets(tickets)
    }
    const handleRadioSelectionChange = (e: RadioChangeEvent) => {
        switch (e.target.value) {
        case RadioSelections.Single: setAllTicketSelected(false)
            break
        case RadioSelections.All: setAllTicketSelected(true)
            break

        default: setAllTicketSelected(false)
        }
    }
    const handleCancelTickets = () => {
        setAlertModalOpen(true)
    }
    const handleAlertModalConfirm = () => {
        const rerun = { pk: selectedRerun!.pk }
        setLoading(true)
        api.cancelTickets({ rerun: rerun, subscriptionCodes: tickets }).then(response => {
            const cancelTicketsResult = response.data
            setCancelTicketResult(cancelTicketsResult)
            if (cancelTicketsResult.failedCodes.length > 0) {
                setCancelModalErrorType(CancelTicketErrorType.CODE_NOT_FOUND)
            } else if (cancelTicketsResult.failedCodes.length === 0 && cancelTicketsResult.successfulCodes.length === 0) {
                setCancelModalErrorType(CancelTicketErrorType.TICKETS_NOT_FOUND)
            } else {
                setCancelModalErrorType(CancelTicketErrorType.NONE)
            }
            setLoading(false)
            setAlertModalOpen(false)
            setCancelModalOpen(true)
        }).catch(() => {
            setCancelModalErrorType(CancelTicketErrorType.SERVER_NOT_RESPONDING)
            setLoading(false)
            setAlertModalOpen(false)
            setCancelModalOpen(true)
        })
    }
    const handleCancelModalConfirm = () => {
        if (cancelModalErrorType === CancelTicketErrorType.NONE) {
            resetForm()
        }
        setCancelModalOpen(false)
    }
    const handleModalClose = () => {
        setAlertModalOpen(false)
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <TitleWithSubtitle title={t('cancelTickets')} />
                <div className={styles.buttonContainer}>
                    <Button className={styles.cancelButton} disabled={!buttonEnabled} onClick={handleCancelTickets}>{t('cancelTickets')}</Button>
                    <Modal
                        maskClosable={true}
                        className='ticketingModal ticketingModalAlertButton'
                        closable={false}
                        onCancel={handleModalClose}
                        open={isAlertModalOpen}
                        okText={t('cancelTickets')}
                        onOk={handleAlertModalConfirm}
                        confirmLoading={loading}
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <AlertIcon />
                        <p className={styles.modalTitle}>{selectedShow?.title}</p>
                        <p className={styles.modalDate}>{formatDate(dayjs(selectedRerun?.date), 'D MMMM YYYY HH:mm')}</p>
                        {allTicketSelected ?
                            <>
                                <p>{t('cancelAllQuestion')}</p>
                            </>
                            :
                            <>
                                <p>{t('cancelQuestion', { count: tickets.length })}</p>
                                <ul className={styles.listContainer}>
                                    {tickets.map((code, index) => (
                                        <li key={index}>{code}</li>
                                    ))}
                                </ul>
                            </>
                        }
                    </Modal>
                    {cancelModalOpen &&
                        <TicketCancelModal
                            tickets={cancelTicketResult}
                            handleConfirmClick={handleCancelModalConfirm}
                            errorType={cancelModalErrorType}
                            open={cancelModalOpen}
                            showTitle={selectedShow!.title}
                            showDate={formatDate(dayjs(selectedRerun!.date), 'D MMMM YYYY HH:mm')}
                        />}
                </div>
            </div>
            <TicketingForm
                key={formKey}
                handleRadioSelection={handleRadioSelectionChange}
                handleDateSelection={handleDateSelection}
                handleShowSelection={handleShowSelection}
                handleTicketSelection={handleTicketsSelectionChange} />
        </div >
    )
}
export default TicketCancel