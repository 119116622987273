import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVDownloader } from 'react-papaparse'
import actionPopupButton from '../../../../assets/actionPopupButton.svg'
import exportData from '../../../../assets/export.svg'
import importData from '../../../../assets/import.svg'
import PopupMenu from '../../../../common/components/PopupMenu'
import { formatDate, isAppCustomer } from '../../../../common/utils'
import { CustomerDetail } from '../../../../service/api'
import styles from './ContactsPopupMenu.module.css'
interface PropsType {
    exportData: CustomerDetail[],
    onImportClick: () => void
}

const ContactsPopupMenu = (props: PropsType) => {
    const [t] = useTranslation('translations')
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const { CSVDownloader } = useCSVDownloader()

    const handlePopupItemClicked = () => {
        setPopupVisible(!popupVisible)
    }

    const createExportedContacts = () => {
        return props.exportData.map(customer => {
            const birthDate = customer.birthDate !== undefined
                ? formatDate(dayjs(customer.birthDate), 'DD/MM/YYYY')
                : ''
            const registrationType = isAppCustomer(customer.type)
                ? t('app')
                : t('other')
            const registrationDate = customer.registrationDate !== undefined
                ? formatDate(dayjs(customer.registrationDate), 'DD/MM/YYYY')
                : ''
            const profilingAccepted = customer.profilePushAccepted
                ? t('yes')
                : t('no')
            return {
                [t('name')]: customer.firstName,
                [t('surname')]: customer.lastName,
                [t('email')]: customer.email,
                [t('birthDate')]: birthDate,
                [t('gender')]: customer.gender,
                [t('city')]: customer.city,
                [t('phone')]: customer.phone,
                [t('country')]: customer.country,
                [t('registrationType')]: registrationType,
                [t('registrationDate')]: registrationDate,
                [t('profilingAccepted')]: profilingAccepted,
                [t('associatedDevices')]: customer.deviceCount
            }
        })
    }

    const exportMenuItem = () => (
        <CSVDownloader
            filename={'contacts'}
            bom={true}
            data={createExportedContacts}
        >
            <>
                <img src={exportData} className='ant-menu-item-icon' />
                <span className='ant-menu-title-content'>{t('export')}</span>
            </>
        </CSVDownloader>
    )

    const importMenuItem = () => (
        <>
            <img src={importData} className='ant-menu-item-icon' />
            <span className='ant-menu-title-content'>{t('import')}</span>
        </>
    )

    return (
        <PopupMenu
            content={[
                {
                    key: '1',
                    label: exportMenuItem(),
                    onClick: () => {
                        handlePopupItemClicked()
                    }
                },
                {
                    key: '2',
                    label: importMenuItem(),
                    onClick: () => {
                        props.onImportClick()
                        handlePopupItemClicked()
                    }
                },
            ]}
            popupVisible={popupVisible}
            placement='bottomRight'>

            <div
                className={styles.popupContent}
                onClick={handlePopupItemClicked}>
                <img
                    src={actionPopupButton}
                    className={styles.popupImage} />
            </div>

        </PopupMenu>
    )
}

export default ContactsPopupMenu
