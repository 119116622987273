import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import { setConfig } from './app/Reducers/DashboardSlice'
import Dashboard from './pages/dashboard/Dashboard'
import { DashboardSetting } from './pages/dashboard/newDashboard/DashboardModel'
import NewDashboard from './pages/dashboard/newDashboard/NewDashboard'
import { Login } from './pages/login/Login'
import { Main } from './pages/main/Main'
import { NotificationBadge, NotificationWidget } from './pages/notification/NotificationWidget'
import { AuthenticationApiFactory } from './service/api'
import { IS_LOGGED } from './service/model'

export function isUserLogged(): boolean {
    return localStorage.getItem(IS_LOGGED) === 'true'
}
const App = (): ReactElement => {
    const api = AuthenticationApiFactory()
    const [adminDashboardConfiguration, setAdminDashboardConfig] = useState<boolean>()
    const dispatch = useDispatch()
    useEffect(() => {
        api.getCurrentUser().then(response => {
            const user = response.data
            const adminDashboardSetting = user.application.settings.find(setting => setting.key === 'adminDashboardConfiguration')
            if (adminDashboardSetting) {
                const adminDashboardConfiguration: DashboardSetting[] = JSON.parse(adminDashboardSetting.value)
                adminDashboardConfiguration.forEach(c => {
                    if (c.roles.includes(user.role)) {
                        dispatch(setConfig(c.config))
                        setAdminDashboardConfig(true)
                    }
                })
            } else {
                dispatch(setConfig(undefined))
                setAdminDashboardConfig(false)
            }
        })
    }, [])

    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Navigate to='/main' replace />} />
            <Route path='/notification-badge' element={
                <RequireAuth>
                    <NotificationBadge />
                </RequireAuth>
            } />
            <Route path='/notification-widget' element={
                <RequireAuth>
                    <NotificationWidget />
                </RequireAuth>
            } />
            <Route path='/main/*' element={
                <RequireAuth>
                    <Main />
                </RequireAuth>
            } />
            {adminDashboardConfiguration ?
                <Route path='/dashboard' element={
                    <RequireAuth>
                        <NewDashboard />
                    </RequireAuth>
                } /> :
                <Route path='/dashboard' element={
                    <RequireAuth>
                        <Dashboard />
                    </RequireAuth>
                } />
            }
        </Routes>
    )
}

function Home() {
    return !isUserLogged() ? <Login /> : <Navigate to='/main' replace />
}

export function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation()
    return !isUserLogged() ? <Navigate to='/' state={{ from: location }} replace /> : children
}

export default App
