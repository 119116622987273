import { Drawer, Layout, notification } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { AxiosError } from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { setCurrentUser } from '../../app/Reducers/UserInfoSlice'
import '../../common/components/Popover.css'
import { AuthenticationApiFactory, User } from '../../service/api'
import { Topbar } from './Header/Topbar'
import styles from './Main.module.css'
import { Sider } from './Sider/Sider'
import { TopicMenu } from './TopicMenu'
import { MainRoutes } from './routing'


export const Main = (): ReactElement => {
    const api = AuthenticationApiFactory()
    const [t] = useTranslation('translations')
    const [user, setUser] = useState<User>()
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // Main

    useEffect(() => {
        api.getCurrentUser()
            .then(response => {
                const user = response.data
                setUser(user)
                dispatch(setCurrentUser(user))
            }).catch((error: AxiosError) => {
                if (error.response?.status === 401) {
                    api.logout().then(() => {
                        localStorage.clear()
                        navigate('/')
                    })
                }
                notification.error({
                    message: t('error'),
                    description: error.message
                })
            })
    }, [])
    const handleMenuClick = () => {
        setDrawerVisible(false)
    }
    const getTopicMenu = () => {
        return (
            <TopicMenu
                onMenuChange={handleMenuClick}
                settings={user!.application.settings}
                userRole={user!.role} />
        )
    }
    return (
        <Layout className={styles.container}>
            {user && <Sider menu={getTopicMenu()} />}
            <Layout>
                <Header className={styles.header}>
                    {user &&
                        <Topbar
                            drawerVisible={drawerVisible}
                            user={user}
                            changeDrawerState={() => setDrawerVisible(prevState => !prevState)} />}
                </Header>
                <Content style={{ position: 'relative' }}>
                    {user &&
                        <Drawer
                            maskClassName={styles.drawerMask}
                            getContainer={false}
                            width={'55%'}
                            size='default'
                            className={styles.drawer}
                            placement="right"
                            closable={false}
                            open={drawerVisible}
                        >
                            {getTopicMenu()}
                        </Drawer>
                    }
                    <div className={styles.contentContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column' as const, flexGrow: 1 }}>
                            {user && <MainRoutes role={user.role} settings={user.application.settings} />}
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}