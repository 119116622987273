import Area from '@ant-design/plots/es/components/area'
import { Button, Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import closeDetail from '../../../../assets/close.svg'
import { Colors } from '../../../../common/Colors'
import { ChartData } from '../../../../common/components/charts/ChartData'
import ChartTooltip from '../../../../common/components/charts/ChartTooltip'
import ExportCsv from '../../../../common/components/charts/ExportCsv'
import i18n from '../../../../i18n'
import { DashboardApiFactory } from '../../../../service/api'
import styles from './LineChartDetail.module.css'
import { ItemTooltip } from './NewLineChart'
interface PropsType {
    chartData: any[]
    columnLabels: string[]
    tooltip: string
    title: string
    dataSource: string
    onClose: () => void
}

const LineChartDetail = (props: PropsType) => {
    const api = DashboardApiFactory()
    const [t] = useTranslation('translations')
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const [columnLabels, setColumnLabels] = useState<string[]>([])
    const [chartData, setChartData] = useState<any[]>([])
    const [total, setTotal] = useState<number>(0)
    useEffect(() => {
        setColumnLabels(props.columnLabels)
        setChartData(props.chartData)
        setTotal(_.sumBy(props.chartData, datum => datum[props.columnLabels[1]]))
    }, [])
    useEffect(() => {
        const start = dashboardState.startDate.clone().hour(0).minute(0).second(0)
        const end = dashboardState.endDate.clone().hour(23).minute(59).second(59)
        api.getChartData(props.dataSource, {
            pks: dashboardState.selectedItemsIds,
            startDate: start.valueOf(),
            endDate: end.valueOf()
        }).then(response => {
            const rawData = response.data
            const label1 = i18n.t('translations:' + rawData.labels[0])
            const label2 = i18n.t('translations:' + rawData.labels[1])
            setColumnLabels([label1, label2])
            setChartData(rawData.data.map((item) => ({
                [label1]: dayjs(item[0]).format('DD MMM'),
                [label2]: item[1],
            })))
        })
    }, [dashboardState.selectedItemsIds, dashboardState.startDate, dashboardState.endDate])
    const config = {
        data: chartData,
        xField: columnLabels[0],
        yField: columnLabels[1],
        // unlike width, height is not so flexible for the graph
        height: 170,
        xAxis: {
            tickCount: 7,
            tickLine: null,
            label: {
                formatter: (value: string) => {
                    return value
                },
                className: {
                    fill: '#191919',
                    fontWeight: 700
                },
            },
        },
        yAxis: {
            line: {
                style: { stroke: Colors.white },
            },
            label: {
                formatter: (value: string) => {
                    const num = parseInt(value)
                    return num >= 1000 ? `${num / 1000} ${'k'}` : value
                }
            },
            position: 'right' as const
        },
        line: { color: Colors.yellow },
        areaStyle: { fill: Colors.yellow, fillOpacity: 0.2 },
        point: {
            color: Colors.yellow,
            size: 2,
            style: {
                lineWidth: 2,
            }
        },
        tooltip: {
            marker: { fill: Colors.yellow },
            customContent: (title: string, items: ItemTooltip[]) => {
                return items.length > 0 ? items[0].value.toString() : ''
            },
            showTitle: false,
        }
    }
    const columns: ColumnsType<ChartData> = [
        {
            title: i18n.t(columnLabels[0]),
            dataIndex: columnLabels[0],
            key: columnLabels[0],
        },
        {
            title: i18n.t(columnLabels[1]),
            dataIndex: columnLabels[1],
            key: columnLabels[1],
        }
    ]

    const tableProps: TableProps<ChartData> = {
        pagination: false,
        rowKey: columnLabels[0]
    }
    return (
        <div className={styles.graphContainer}>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <div style={{ display: 'flex' }}>
                        <p className={styles.title}>{props.title}</p>
                        <ChartTooltip tooltipText={props.tooltip} />
                    </div>
                    <div className={styles.actionButtonContainer}>
                        <ExportCsv filename={props.title} data={chartData} isImage={true} />
                        <Button
                            className={styles.closeButton}
                            icon={<img src={closeDetail} />}
                            onClick={props.onClose} />
                    </div>
                </div>
                <p className={styles.totalValue}>{_.sumBy(chartData, datum => datum[props.columnLabels[1]])}</p>
                <Area
                    {...config}
                    className={styles.lineGraphContainer} />
            </div>
            <Table className={styles.lineGraphTable} {...tableProps} columns={columns} dataSource={chartData} />
        </div>
    )
}

export default LineChartDetail
