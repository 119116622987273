
import { notification } from 'antd'
import { Button } from 'antd/lib'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVDownloader } from 'react-papaparse'
import { ReactComponent as ArrowIcon } from '../../../../assets/arrowDown.svg'
import PopupMenu from '../../../../common/components/PopupMenu'
import { CustomerDetail } from '../../../../service/api'
import styles from './ContactsBulkActions.module.css'

interface ContactBulkActionsProps {
    data: CustomerDetail[]
}
const ExportMenuItem = ({ dataSource, children, disabled, filename }: { dataSource: unknown[], children: ReactElement, disabled?: boolean, filename: string }): ReactElement => {
    const { CSVDownloader } = useCSVDownloader()
    if (disabled) {
        return children
    }
    return (
        <CSVDownloader
            filename={filename}
            bom={true}
            data={dataSource}
        >
            {children}
        </CSVDownloader>
    )
}

export const ContactsBulkActions = (props: ContactBulkActionsProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const action = (action: string) => {
        notification.info({
            message: action
        })
    }
    const content = [
        {
            key: 1,
            label: <div>{t('addToList')}</div>,
            onClick: () => {
                console.log('add')
                setPopupVisible(false)
                action(t('addToList'))
            },
        },
        {
            key: 2,
            label: <div>{t('createList')}</div>,
            onClick: () => {
                setPopupVisible(false)
                console.log('create')
                action(t('createList'))
            }
        },
        {
            key: 3,
            label: <ExportMenuItem
                dataSource={props.data}
                disabled={props.data.length === 0}
                filename={t('contacts')}
            >
                <span className='ant-menu-title-content'>{t('exportSelected')}</span>
            </ExportMenuItem>,
            disabled: props.data.length === 0,
            onClick: () => {
                setPopupVisible(false)
                console.log('export')
            }
        },
        {
            key: 4,
            label: <div>{t('delete')}</div>,
            onClick: () => {
                setPopupVisible(false)
                console.log('delete')
                action(t('delete'))
            }
        }
    ]

    return (
        <PopupMenu popupVisible={popupVisible} content={content} >
            <Button
                className={styles.button}
                icon={<ArrowIcon />}
                iconPosition='end'>{t('bulkActions')}</Button>
        </PopupMenu>
    )
}