import { Button, Form, Modal } from 'antd'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CheckMarkIcon } from '../../../../assets/checkMarkIcon.svg'
import '../../../../common/commonCss/buttons.css'
import BackButton from '../../../../common/components/commonUI/BackButton'
import TitleWithSubitile from '../../../../common/components/commonUI/TitleWithSubtitle'
import { ModalManager, ModalType } from '../ModalManager'
import { OrganizationsForm } from '../OrganizationsForm.tsx/OrganizationsForm'
import styles from './OrganizationsNew.module.css'
import { OrganizationDetail, OrganizationsApiFactory } from '../../../../service/api'

export const OrganizationsNew = (): ReactElement => {
    const api = OrganizationsApiFactory()
    const [t] = useTranslation('translations')
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [modalType, setModalType] = useState<ModalType>(ModalType.NONE)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [saveComplete, setSaveComplete] = useState<boolean>(false)

    const submitForm = (values: OrganizationDetail) => {
        setIsSaving(true)
        api.addOrganization(values).then(() => {
            setSaveComplete(true)
        }).catch(() => {
            handleOpenModal(ModalType.ERROR_MODAL)
        }).finally(() =>
            setIsSaving(false)
        )
    }
    const handleCancelConfirm = () => {
        navigate(-1)
    }
    const handleOpenModal = (type: ModalType) => {
        setModalOpen(true)
        setModalType(type)
    }
    const handleModalClose = () => {
        setModalOpen(false)
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubitile title={t('newOrganization')} />
                <div className={styles.buttonsContainer}>
                    <Button
                        disabled={isSaving}
                        onClick={() => handleOpenModal(ModalType.CANCEL_MODAL)}
                        className={`blackFillPositiveButton ${styles.cancelButton}`}>
                        {t('cancel')}
                    </Button>
                    <Button
                        loading={isSaving}
                        onClick={() => form.submit()}
                        className={'yellowFillPositiveButton'}>
                        {t('save')}
                    </Button>
                </div>
            </div>
            <div className={styles.backContainer}>
                <BackButton />
            </div>
            <div className={styles.formContainer}>
                <OrganizationsForm
                    disabled={false}
                    form={form}
                    onFinish={submitForm} />
            </div>
            {modalOpen && <ModalManager
                type={modalType}
                open={modalOpen}
                onCancelConfirm={handleCancelConfirm}
                onModalClose={handleModalClose}
            />
            }
            <Modal
                closable={false}
                className={'modal'}
                open={saveComplete}
                footer={
                    <>
                        <Button
                            key={'createNewButton'}
                            className='yellowOutlinedCancelButton'
                            onClick={() => {
                                form.resetFields()
                                setSaveComplete(false)
                            }
                            }>
                            {t('createNew')}
                        </Button>
                        <Button
                            key={'okButton'}
                            className={'blackFillPositiveButton'}
                            onClick={() => navigate(-1)}>
                            {'OK'}
                        </Button>
                    </>
                }>
                <CheckMarkIcon />
                <p>{t('organizationSaved')}</p>
            </Modal>
        </div>
    )
}
