import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Header.module.css'

const WAREHOUSES = 3

interface HeaderProps {
    open: boolean,
    totalStocks: number,
    warehouses: string[]
}

export const Header = ({ open, totalStocks, warehouses }: HeaderProps): ReactElement => {
    const [t] = useTranslation('translations')
    return (
        <div className={styles.container}>
            <div className={styles.title}>{`${t('totalStocks')}:`}
                <div className={styles.total}>
                    {totalStocks}
                </div>
            </div>
            {!open &&
                <div className={styles.locationList}>{`${t('warehouses')}:`}
                    {warehouses.slice(0, WAREHOUSES).map(warehouse => {
                        return (
                            <div key={warehouse} className={styles.warehouse}>
                                {warehouse.concat(' ')}
                            </div>
                        )
                    })}
                    {warehouses.length > WAREHOUSES &&
                        <div className={styles.summaryContainer}>
                            {'...+'}
                            <div>
                                {(warehouses.length - WAREHOUSES).toString()}
                            </div>
                        </div>}
                </div>
            }
        </div >
    )
}