import { Button, notification } from 'antd'
import Title from 'antd/es/typography/Title'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import _ from 'lodash'
import { ParseResult } from 'papaparse'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePapaParse } from 'react-papaparse'
import { useLocation, useNavigate } from 'react-router-dom'
import { importCustomers } from '../../../../service/CustomerService'
import alertIcon from '../../../../assets/alert.svg'
import backIcon from '../../../../assets/back_active.svg'
import saveIcon from '../../../../assets/save.svg'
import { CustomerType } from '../Contacts/Contacts'
import ImportConfirmDialog from './ImportConfirmDialog'
import ImportTableView from './ImportTableView'
import styles from './Importer.module.css'

export interface ImportedCustomer {
    email: string
    firstName: string
    lastName: string
    birthDate: string
    gender: string
    address: string
    zip: string
    city: string
    province: string
    country: string
    phone: string
}

export enum ImportedCustomerStatus {
    Imported,
    WarningAlreadyExisting,
    ErrorInvalidEmail,
    ErrorDuplicatedImport
}

export interface ImportedCustomerItem {
    customer: ImportedCustomer
    status: ImportedCustomerStatus
}

const Importer = () => {
    const [t] = useTranslation('translations')
    const navigate = useNavigate()
    const location = useLocation()
    const { readString } = usePapaParse()

    const [csvFile, setCsvFile] = useState<File | null>(null)
    const [importFailed, setImportFailed] = useState<boolean>(false)
    const [importError, setImportError] = useState<string | null>(null)
    const [importedCustomers, setImportedCustomers] = useState<ImportedCustomerItem[]>([])
    const [overwrittenCustomersCount, setOverwrittenCustomersCount] = useState<number>(0)
    const [invalidCustomersCount, setInvalidCustomersCount] = useState<number>(0)
    const [revisitableCustomersCount, setRevisitableCustomersCount] = useState<number>(0)

    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)

    const customerEmails = location.state ? (location.state as string[]) : []
    const headerSet = new Set([
        'email',
        'firstName',
        'lastName',
        'birthDate',
        'gender',
        'address',
        'zip',
        'city',
        'province',
        'country',
        'phone'
    ])

    const isEmailValid = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

    const onFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files ?? []
        if (fileList.length === 0) {
            return
        }
        setCsvFile(fileList[0])
    }

    const onFileLoad = () => {
        const reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                const content = reader.result?.toString() || ''
                readString(content.trim(), {
                    worker: true,
                    header: true,
                    skipEmptyLines: 'greedy',
                    complete: onImportCompleted
                })
            },
            false
        )
        if (csvFile !== null) {
            reader.readAsText(csvFile as File)
        }
    }

    const onImportCompleted = (results: ParseResult<ImportedCustomer>) => {
        // check if there are header errors
        if (!_.isEqual(headerSet, new Set(results.meta.fields))) {
            setImportError(t('errorCsvWrongHeaders'))
            setImportFailed(true)
            return
        }

        // check if there are import errors
        if (results.errors.length > 0) {
            const errorMessages = {
                'MissingQuotes': t('errorCsvMissingQuotes'),
                'UndetectableDelimiter': t('errorCsvDelimiter'),
                'TooFewFields': t('errorCsvTooFewFields', { value: results.meta.fields?.length }),
                'TooManyFields': t('errorCsvTooManyFields', { value: results.meta.fields?.length }),
                'InvalidQuotes': t('errorCsvInvalidQuotes')
            }
            // we show only the first error
            const firstError = results.errors[0]
            let errorMessage = errorMessages[firstError.code]
            if (firstError.row !== undefined) {
                errorMessage += ' ' + t('atLine', { line: firstError.row + 1 })
            }
            setImportError(errorMessage)
            setImportFailed(true)
            return
        }

        // if there are no import errors we can proceed to process data
        setImportError(null)
        setImportFailed(false)
        const importedEmails = new Set(results.data.map(customer => customer.email))
        const importedCustomerItems = results.data.map(customer => {
            let status = ImportedCustomerStatus.Imported
            if (!isEmailValid(customer.email)) {
                status = ImportedCustomerStatus.ErrorInvalidEmail
            } else {
                if (customerEmails.includes(customer.email)) {
                    status = ImportedCustomerStatus.WarningAlreadyExisting
                }
                if (importedEmails.has(customer.email)) {
                    importedEmails.delete(customer.email)
                } else {
                    status = ImportedCustomerStatus.ErrorDuplicatedImport
                }
            }
            return { customer, status }
        })

        const customerByStatusCount = _.countBy(importedCustomerItems, customer => customer.status)
        const invalidEmailCount = customerByStatusCount[ImportedCustomerStatus.ErrorInvalidEmail] ?? 0
        const duplicatedCount = customerByStatusCount[ImportedCustomerStatus.ErrorDuplicatedImport] ?? 0
        const existingCount = customerByStatusCount[ImportedCustomerStatus.WarningAlreadyExisting] ?? 0
        setOverwrittenCustomersCount(existingCount)
        setInvalidCustomersCount(invalidCustomersCount + duplicatedCount)
        setRevisitableCustomersCount(invalidEmailCount + duplicatedCount + existingCount)
        setImportedCustomers(importedCustomerItems)
    }

    const onCancel = () => {
        setCsvFile(null)
        setImportFailed(false)
        setImportError(null)
        setImportedCustomers([])
    }

    const onSave = () => {
        if (revisitableCustomersCount > 0) {
            setShowConfirmDialog(true)
        } else {
            setShowConfirmDialog(false)
            onSaveConfirm()
        }
    }

    const onSaveConfirm = () => {
        const customersToImport = importedCustomers.filter(importedCustomer => {
            return [
                ImportedCustomerStatus.Imported,
                ImportedCustomerStatus.WarningAlreadyExisting
            ].includes(importedCustomer.status)
        }).map(importedCustomer => {
            let timestamp: number | undefined = undefined
            const birthDate = importedCustomer.customer.birthDate ?? ''
            if (birthDate.length > 0) {
                timestamp = dayjs(birthDate, 'DD/MM/YYYY').valueOf()
            }
            return {
                pk: 0,
                email: importedCustomer.customer.email,
                firstName: importedCustomer.customer.firstName,
                lastName: importedCustomer.customer.lastName,
                birthDate: timestamp,
                gender: importedCustomer.customer.gender,
                address: importedCustomer.customer.address,
                zip: importedCustomer.customer.zip,
                city: importedCustomer.customer.city,
                province: importedCustomer.customer.province,
                country: importedCustomer.customer.country,
                phone: importedCustomer.customer.phone,
                type: CustomerType.Crm
            }
        })

        importCustomers(customersToImport)
            .then(() => {
                navigate(-1)
            }).catch((error: AxiosError) => {
                notification.error({
                    message: error.response?.status.toString(),
                    description: error.message
                })
            })

        setShowConfirmDialog(false)
    }

    const onSaveCancel = () => {
        setShowConfirmDialog(false)
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <Title level={3} className={styles.title}>{t('importContacts')}</Title>
                    {importedCustomers.length > 0 &&
                        <div className={styles.buttonsContainer}>
                            <Button
                                onClick={onCancel}
                                className={`yellowOutlinedCancelButton ${styles.cancelButton}`}>
                                {t('cancel')}
                            </Button>
                            <Button
                                icon={<img src={saveIcon} className={styles.saveIcon} />}
                                onClick={onSave}
                                className={'yellowFillPositiveButton'}>
                                {t('saveContacts')}
                            </Button>
                        </div>
                    }
                </div>
                <Button className={styles.backButtonContainer} onClick={() => navigate(-1)}>
                    <img src={backIcon} className={styles.backIcon} />
                    <span className={styles.backLabel}>{t('back')}</span>
                </Button>

                {importedCustomers.length === 0 ?
                    <div className={styles.importerSelector}>
                        <p className={styles.instructionLabel}>
                            {t('selectCsvFile')}
                        </p>
                        <div>
                            <div className={styles.fileSelector}>
                                <input
                                    type="file"
                                    accept="text/csv"
                                    className={styles.inputFileSelector}
                                    onChange={onFileChanged} />
                            </div>

                            <Button
                                onClick={onFileLoad}
                                disabled={csvFile === null}
                                className={`yellowFillPositiveButton ${styles.fileLoaderButton}`}>
                                {t('loadFile')}
                            </Button>
                        </div>

                        {importFailed &&
                            <div className={styles.alertContainer}>
                                <img src={alertIcon} className={styles.alertIcon} />
                                <span className={styles.alertLabel}>{t('impossibleToLoad')}</span>
                                <span className={styles.alertReasonLabel}>{importError}</span>
                            </div>
                        }
                    </div>
                    :
                    <>
                        <div style={{ marginTop: 35, marginBottom: 45 }}>
                            <span className={styles.loadedContactsCount}>
                                {importedCustomers.length} {t('loadedContacts')}
                            </span>
                            {revisitableCustomersCount > 0 &&
                                <span className={styles.invalidContactsCount}>
                                    &nbsp;&nbsp;&bull;&nbsp;&nbsp;{revisitableCustomersCount} {t('invalidContacts')}
                                </span>
                            }

                        </div>
                        <ImportTableView data={importedCustomers} />
                    </>
                }

                {showConfirmDialog &&
                    <ImportConfirmDialog
                        overwrittenCount={overwrittenCustomersCount}
                        invalidCount={invalidCustomersCount}
                        onConfirm={onSaveConfirm}
                        onCancel={onSaveCancel} />
                }
            </div>
        </>
    )
}

export default Importer