import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../service/api'

interface UserState {
    user: User
}

const initialState: UserState = {
    user: {
        pk: -1,
        username: '',
        email: '',
        password: '',
        role: '',
        firstName: '',
        lastName: '',
        company: '',
        organizations: [],
        timestamp: null,
        enabled: false,
        notification: false,
        tenantId: -1,
        application: { pk: -1, settings: [] },
    }
}

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        logoutUser: state => {
            state.user
        }
    }
})

export const { setCurrentUser, logoutUser } = userInfoSlice.actions
export default userInfoSlice.reducer